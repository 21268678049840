/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { openDialog } from "redux-reactstrap-modal";
import { bindActionCreators } from "redux";
import { loadLotteryPackages } from "../actions/index";
import { withRouter } from "react-router-dom";

import { MODALS, EVENTS } from "../config";

import { translate, getLanguage } from "react-switch-lang";
import { LOTTERY_LIST } from "../config/";
import _ from "lodash";

class MyPackage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false
    };
  }

  componentWillMount() {
    if (this.props.token === "null") {
      this.props.history.push("/");
      // this.props.openDialog(MODALS.LOGIN);
      this.props.setNextEvent({ event: EVENTS.ACCOUNT });
    } else {
      // this.props.loadLotteryPackages();
    }
  }

  deactivate() {
    const { package_id, package_name, registered_date } = this.props.result;
    const { t } = this.props;
    const data = {
      title: `${t("myaccount.deactivate")} ${t(this.loadLotteryName(package_name))}`,
      message: {
        head: t("myaccount.confirm_msg_head"),
        msg: t("myaccount.confirm_msg"),
      },
      action: EVENTS.DEACTIVATE,
      props: { package_id, source: "WEB_APP_USER" }
    };

    this.props.openDialog(MODALS.WARNING, data);
  }

  loadLotteryName(lottery_name) {
    const { t } = this.props;
    var pack = _.find(LOTTERY_LIST, { value: lottery_name });

    // return lottery_name;
    return pack === undefined ? lottery_name : t("lottery_tickets." + pack.key);
  }

  render() {
    const { package_id, package_name, registered_date } = this.props.result;
    const { t } = this.props;
    return (
      <div key={package_id} className="col-12 col-md-4 my-1 px-2">
        <div className="bg-light rounded p-3 d-flex flex-row  justify-content-between align-items-center">
          <div>
            <h5 className="my-0 bolded">
              {t(this.loadLotteryName(package_name))}
            </h5>
            <small className="my-0">
              {t("myaccount.registered_date")}: {registered_date.substr(0, 10)}
            </small>
          </div>
          <div className="mx-1 text-right">
            <button
              className="btn btn-warning"
              type="button"
              onClick={() => this.deactivate()}
            >
              {t("myaccount.deactivate")}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ openDialog, loadLotteryPackages }, dispatch);
}

//export default connect(null, mapDispatchToProps)(MyPackage);

//export default translate(compose(reduxDialog({ name: 'MODAL_SIGNUP', centered: true }), connect(null, mapDispatchToProps))(SignUpModal));
export default translate(
  connect(
    null,
    mapDispatchToProps
  )(MyPackage)
);
