import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { translate } from 'react-switch-lang';
import { COMMON_WALLET_LOGIN_URL } from '../../config/index';
class WalletButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentWillMount() {
    }

    navigate() {
        window.open(COMMON_WALLET_LOGIN_URL);
    }

    render() {
        const { t } = this.props;
        return (
            <div className="">

                <div className="container">
                    <div className="col-12 text-center my-5">
                        <a className="btn btn-primary" onClick={() => { this.navigate("add") }} >{t("wallet.payment_configuration")}</a>
                    </div>
                    
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({  }, dispatch);
}

function mapStateToProps({}) {
    return { };
}

export default withRouter(translate((connect(
    mapStateToProps,
    mapDispatchToProps
)(WalletButton))));