/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import { LOAD_MY_PACKAGES, PACKAGE_DEACTIVATED, CLEAR_MY_PACKAGES } from "../actions/index";
import _ from "lodash";

export default function (state = [], action) {
    switch (action.type) {
        case LOAD_MY_PACKAGES:
            return action.payload.data;
        case PACKAGE_DEACTIVATED:
            var index = 0;
            for (var i = 0; i < state.length; i++) {
                if (state[i].package_id === action.payload.package_id) {
                    index = i;
                }
            }
            return [...state.slice(0, index), ...state.slice(index + 1)];
        case CLEAR_MY_PACKAGES:
          return [];
        default:
            return state;
    }
}
