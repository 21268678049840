/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import reduxDialog, { closeDialog, openDialog } from 'redux-reactstrap-modal';
import { connect } from "react-redux";
import { compose } from 'recompose';
import { bindActionCreators } from "redux";
import { MODALS } from '../config';
import { proceedBannerCallToAction } from '../actions';

class BannerModal extends Component {

    componentDidMount() {
        sessionStorage.setItem('lk_banner_viewed', true);
    }

    closeBannerModal() {
        this.props.closeDialog(MODALS.BANNER);
    }

    proceedAction() {
        const { lottery, action } = this.props.data;
        this.props.proceedBannerCallToAction({ lotteryId: lottery.lottery_id, action: action });
        this.closeBannerModal();
    }

    render() {
        const { image, actionText, btnColor } = this.props.data;
        const buttonStyle = {
            backgroundColor: btnColor,
            color: "#ffffff",
        };

        return (
            <div id="banner-modal" className="p-0">
                <div className="modal-body p-0">
                    <div className="absolute-close-btn">
                        <button type="button" className="close" onClick={() => this.closeBannerModal()}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <img src={image} className="img-fluid" />
                    <div className="row p-3">
                        <div className="col-12">
                            <button
                                className="btn btn-block"
                                style={buttonStyle}
                                type="button"
                                onClick={() => this.proceedAction()}
                            >
                                {actionText}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ proceedBannerCallToAction, closeDialog, openDialog }, dispatch);
}

export default compose(
    reduxDialog({
        name: 'MODAL_BANNER',
        className: 'banner-modal',
        centered: true,
        backdrop: "static"
    }),
    connect(null, mapDispatchToProps))
    (BannerModal);
