/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from "react";
import { connect } from "react-redux";

import { bindActionCreators } from "redux";
import { loadResults, loadAvaliableLotteries } from "../actions/index";

import ResultTicket from "../components/ResultTicket";
import { LIMIT, LOTTERY_LIST } from "../config/";
import {
  X as CloseIcon,
  Filter as FilterIcon,
  RefreshCcw as Refresh
} from "react-feather";
// import InfiniteScroll from 'redux-infinite-scroll';
// import InfiniteScroll from 'react-infinite-scroller';
import InfiniteScroll from "react-infinite-scroll-component";

//import { LOTTERY_LIST } from '../config/';

import _ from "lodash";

import { translate, getLanguage } from "react-switch-lang";
import 'react-day-picker/lib/style.css';
import DayPickerInput from "react-date-picker";
import moment from "moment";
class Results extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: props.width,
      show_filter: false,
      offset: 0,
      hasMore: true,
      result_loading: false,
      draw_number: "",
      drawIdValue: "Select",
      offsetSearch: 0,
      searchDate: "",
      dateSupport: true
    };
  }

  componentWillMount() {
    let test = document.createElement('input');
    test.type = 'date';
    if (test.type === 'text') {
      this.setState({
        dateSupport: false
      });
    }

    const { available_packages, loadAvaliableLotteries } = this.props;
    this.handleRefresh();
    this.setState({
      width: window.innerWidth
    });

    if (available_packages !== undefined) {
      loadAvaliableLotteries();
    }
  }

  componentDidUpdate() {
    // window.scrollTo(0, 0);
    // this.setState({ result_loading:this.props.result_loading});
  }

  updateInputDrawId(event) {
    this.setState({ draw_number: event.target.value });
  }

  resetButtonClick() {
    this.setState({
      offsetSearch: 0,
      drawIdValue: "",
      draw_number: "",
      searchDate: ""
    });
    this.handleRefresh();
  }

  loadMore() {
    const { results } = this.props;
    if (!this.state.show_filter) {
      var hasMore;
      if (results.page.total > LIMIT) {
        if (this.state.offset < results.page.total) {
          hasMore = true;
        } else {
          hasMore = false;
        }
      } else {
        hasMore = false;
      }
      this.setState({ hasMore: hasMore, result_loading: true });
      if (hasMore) {
        this.setState({ offset: this.state.offset + LIMIT }, () => {
          this.props.loadResults(this.state.offset);
        });
      }
    } else {
      var hasMore;
      if (results.page.total > LIMIT) {
        if (this.state.offsetSearch < results.page.total) {
          hasMore = true;
        } else {
          hasMore = false;
        }
      } else {
        hasMore = false;
      }

      this.setState({ hasMore: hasMore, result_loading: true });
      var item = {
        lottery_id: this.state.drawIdValue,
        draw_number: this.state.draw_number
      };
      if (hasMore) {
        this.setState({ offsetSearch: this.state.offsetSearch + LIMIT }, () => {
          this.props.loadResults(this.state.offsetSearch, item, false);
        });
      }
    }
  }

  handleRefresh() {
    this.setState({ offset: 0, hasMore: true }, () => {
      this.props.loadResults(this.state.offset, null);
    });
  }

  renderLotteries() {
    const { results } = this.props;

    return results.data.map(result => {
      return <ResultTicket key={result.draw_id} result={result} />;
    });
  }

  toggleFilter() {
    window.scrollTo(0, 0);
    this.setState({ show_filter: !this.state.show_filter });
    this.handleRefresh();
    this.setState({
      searchDate: "",
      draw_number: "",
      drawIdValue: "",
      hasMore: true
    });
  }

  refresh() { }

  //   loadLotteryName(lottery_name){
  //     const { t } = this.props;
  //     var pack = _.find(LOTTERY_LIST, { 'value': lottery_name });
  //  
  //     return (pack);

  // }

  loadLotteryName(lottery_name) {
    const { t } = this.props;
    var pack = _.find(LOTTERY_LIST, { value: lottery_name });

    // return lottery_name;
    return pack === undefined ? lottery_name : t("lottery_tickets." + pack.key);
  }

  renderSelect() {
    const { available_packages } = this.props;
    const { t } = this.props;
    if (available_packages.length != 0) {
      return available_packages.data.map(result => {
        return (
          <option value={result.id}>
            {t(this.loadLotteryName(result.name))}
          </option>
        );
      });
    }
  }
  changeSelectDrawId(event) {
    this.setState({ drawIdValue: event.target.value });
    // this.setState({drawIdValue: event.target.value});
  }

  handleDateChange(event) {
    this.setState({ searchDate: event.target.value });
    // this.setState({drawIdValue: event.target.value});
  }

  filterButtonClick() {
    this.setState({ show_filter: true, hasMore: true });
    var item = {};
    var offsetUpdated = 0;
    this.setState({ offsetSearch: 0 });
    if (
      !this.state.drawIdValue &&
      !this.state.draw_number &&
      !this.state.searchDate
    ) {
      this.props.loadResults(offsetUpdated);
    } else {
      item = {
        lottery_id: this.state.drawIdValue,
        draw_number: this.state.draw_number,
        search_date: this.state.searchDate
      };
      this.props.loadResults(offsetUpdated, item, false);
    }
  }

  render() {
    const { results, t } = this.props;

    return (
      <div>
        {this.state.width < 768 && !this.state.show_filter ? (
          <button
            onClick={() => this.toggleFilter()}
            className="btn btn-filter-show filter_btn"
          >
            <FilterIcon size={16} />
          </button>
        ) : (
            <div className="search_filter my-4">
              {this.state.width < 768 && this.state.show_filter ? (
                <div className="closeIcon float-right">
                  <CloseIcon size={16} onClick={() => this.toggleFilter()} />
                </div>
              ) : (
                  ""
                )}
              <h4>{t("myaccount.search")}</h4>
              <form>
                <div className="row ">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>{t("menu.lottery_name")}</label>
                      <select
                        onChange={this.changeSelectDrawId.bind(this)}
                        value={this.state.drawIdValue}
                        className="form-control form-control-lg"
                      >
                        <option value="Select">{t("menu.select")}</option>
                        {this.renderSelect()}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>{t("menu.draw_no")}</label>
                      <input
                        type="text"
                        className="form-control"
                        id="drawNumber"
                        placeholder={t("menu.draw_no")}
                        value={this.state.draw_number}
                        onChange={this.updateInputDrawId.bind(this)}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>{t("home.date")}</label>
                      {
                        this.state.dateSupport ?
                          <input
                            onChange={this.handleDateChange.bind(this)}
                            className="form-control zmDatePicker"
                            value={this.state.searchDate}
                            type="date"
                            min="2019-07-08"
                            id="example-date-input"
                          /> :
                          <DayPickerInput className={"form-control zmDatePicker"}
                            clearIcon={null}
                            inputProps={{
                              readOnly: true
                            }}
                            format={this.state.searchDate}
                            onChange={date => {
                              this.setState({
                                searchDate: moment(date).format("YYYY-MM-DD")
                              });

                            }
                            } />
                      }
                    </div>
                  </div>
                  <div className="col-md-3 d-md-flex">
                    <div className="form-group ml-md-1">
                      <label className="dummyLabel" />
                      <button
                        type="submit"
                        className="btn btn-primary btn-search-filter mx-auto"
                        onClick={() => this.filterButtonClick()}
                      >
                        {t("myaccount.search")}
                      </button>
                    </div>
                    {this.state.width > 768 ? (
                      <div className="form-group">
                        <label className="dummyLabel" />
                        <button
                          type="submit"
                          className="btn btn-primary btn-search-filter reset mx-auto"
                          onClick={() => this.resetButtonClick()}
                        >
                          <Refresh
                            size={20}
                            onClick={() => this.toggleFilter()}
                          />
                        </button>
                      </div>
                    ) : (
                        ""
                      )}
                  </div>
                </div>
              </form>
            </div>
          )}

        {/* <div className="date_alert">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-8">
              {t("alerts.date_alert")}
            </div>
          </div>
        </div> */}

        <InfiniteScroll
          pullDownToRefresh
          dataLength={this.props.results.data.length} //This is important field to render the next data
          pullDownToRefreshContent={
            <h3 style={{ textAlign: "center" }}>
              &#8595; Pull down to refresh
            </h3>
          }
          releaseToRefreshContent={
            <h3 style={{ textAlign: "center" }}>&#8593; Release to refresh</h3>
          }
          next={this.loadMore.bind(this)}
          refreshFunction={this.refresh.bind(this)}
          hasMore={this.state.hasMore}
          loader={
            <h4 style={{ textAlign: "center", color: "white" }}>Loading...</h4>
          }
          endMessage={
            <p style={{ textAlign: "center", color: "white" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          <div className="row px-3 px-lg-1 home-screen-height">
            {this.renderLotteries()}
          </div>
        </InfiniteScroll>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ loadResults, loadAvaliableLotteries }, dispatch);
}

function mapStateToProps({
  results,
  result_loading,
  ongoingLotteries,
  available_packages
}) {
  return { results, result_loading, ongoingLotteries, available_packages };
}

export default translate(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Results)
);
