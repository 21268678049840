/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 *
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from "react";
import reduxDialog, { closeDialog } from "redux-reactstrap-modal";
import { connect } from "react-redux";
import { compose } from "recompose";

import {
  FEE,
  INSTRUMENTS,
  LOTTERY_LIST,
  PAYMENT_METHOD,
  STRING,
} from "../config/";
import _, { startCase, toUpper } from "lodash";

import { bindActionCreators } from "redux";

import {
  randomBuy,
  randomBuyWithWallet,
  getCommonWalletInfo,
  setNextEventLocalStorage,
  clearNextEventLocalStorage,
  clearNextEvent,
  getPaymentSource,
  setPaymentMethodToLocalStorage,
  getPaymentMethodFromLocalStorage,
} from "../actions/index";
import { registerCommonWallet } from "../actions/wallet";
import { MODALS, EVENTS } from "../config";
import { translate } from "react-switch-lang";
import Wizad from "../components/Wizad";
import LoadingBar from "react-redux-loading-bar";
import { MINIMUM_TOPUP_FEE } from "../config";
import { dataPush } from "../utils/piwikDataLayerPush";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

class BuyModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 1,
      loadEditText: false,
      currentStep: 1,
      mobile: false,
      wallet: false,
      both: false,
      disable: false,
      disableSubmit: false,
      isCompartmentCharge: 0,
      mobileRate: false,
      //isConfirmed: false
    };
    this.updateInput = this.updateInput.bind(this);
    this.closeBuyModal = this.closeBuyModal.bind(this);
    this.showMobileRates = this.showMobileRates.bind(this);
    // this.closeMobileRates = this.closeMobileRates.bind(this);
  }

  componentWillMount() {
    const { getPaymentMethodFromLocalStorage, payment_method } = this.props;
    getPaymentMethodFromLocalStorage();
    this.setState({
      mobile: payment_method
        ? payment_method === PAYMENT_METHOD.MOBILE
          ? true
          : false
        : false,
      wallet: payment_method
        ? payment_method === PAYMENT_METHOD.WALLET
          ? true
          : false
        : false,
      both: payment_method
        ? payment_method === PAYMENT_METHOD.BOTH
          ? true
          : false
        : true,
    });
  }

  componentDidMount() {
    this.props.getPaymentSource();
  }

  _next = () => {
    let currentStep = this.state.currentStep;
    currentStep = currentStep >= 2 ? 3 : currentStep + 1;
    this.setState({
      currentStep: currentStep,
    });
  };

  _prev = () => {
    let currentStep = this.state.currentStep;
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    this.setState({
      currentStep: currentStep,
      //isConfirmed: false
    });
  };

  /*
   * the functions for our button
   */
  previousButton() {
    let currentStep = this.state.currentStep;
    const {
      data: { dark_color },
      t,
    } = this.props;

    var buttonStyle = {
      border: `1px solid ${dark_color}`,
      backgroundColor: "#ffffff",
      color: dark_color,
    };

    if (currentStep !== 1) {
      return (
        <button
          className="btn btn-block mr-1"
          style={buttonStyle}
          disabled={this.state.disableSubmit || this.state.disable}
          type="button"
          onClick={this._prev}
        >
          {t("buttons.previous")}
        </button>
      );
    }
    return null;
  }

  nextButton() {
    let { currentStep, count } = this.state;
    const {
      data: { dark_color },
      t,
    } = this.props;

    var buttonStyle = {
      border: `1px solid ${dark_color}`,
      backgroundColor: dark_color,
      color: "#ffffff",
    };
    if (currentStep === 1) {
      return (
        <button
          className="btn btn-block mr-1"
          style={buttonStyle}
          disabled={count > 0 ? false : true}
          type="button"
          onClick={this._next}
        >
          {t("buttons.next")}
        </button>
      );
    } else if (currentStep === 2) {
      return (
        <button
          className="btn btn-block mr-1"
          style={buttonStyle}
          disabled={!this.state.wallet && !this.state.both}
          type="button"
          onClick={this._next}
        >
          {t("buttons.next")}
        </button>
      );
    }
    return null;
  }

  submitButton() {
    const { mobile, wallet, disableSubmit } = this.state;
    const {
      data: { dark_color, draw_id, lottery_name },
      t,
      packages,
      payment_source,
    } = this.props;
    var pack = _.find(packages, { package_name: lottery_name });

    let paymentMethod = mobile ? "MOBILE" : wallet ? "WALLET" : "BOTH";
    if (payment_source && payment_source === toUpper(INSTRUMENTS.FRIMI)) {
      paymentMethod = "WALLET";
    }

    var buttonStyle = {
      border: `1px solid ${dark_color}`,
      backgroundColor: dark_color,
      color: "#ffffff",
    };
    //if (currentStep === 3) {
    return (
      <button
        className="btn btn-block mr-1"
        id="buy-modal-submit-button"
        style={buttonStyle}
        type="button"
        // disabled={(this.state.isConfirmed === false) || disableSubmit}
        disabled={disableSubmit}
        onClick={() =>
          this.handleSubmitClick(draw_id, paymentMethod, pack.package_id)
        }
      >
        {t("wallet.submit")}
      </button>
    );
    //}
    //return null;
  }

  handleSubmitClick(draw_id, paymentMethod, packageId) {
    const {
      randomBuyWithWallet,
      commonWalletInfo,
      registerCommonWallet,
      setNextEventLocalStorage,
      payment_source,
      setPaymentMethodToLocalStorage,
    } = this.props;
    const { count, isCompartmentCharge } = this.state;
    this.setState({ disableSubmit: true });

    const buyWallet = {
      packageId: packageId,
      drawId: draw_id,
      count: count,
      paymentMethod: paymentMethod,
      source:
        payment_source === toUpper(INSTRUMENTS.FRIMI)
          ? toUpper(INSTRUMENTS.FRIMI)
          : STRING.WALLET_REQUEST_TYPE_WEB,
      salesType: STRING.WALLET_ON_DEMAND_SALES_TYPE,
      isCompartmentCharge: isCompartmentCharge,
      customerReferral: sessionStorage.getItem("referral_code"),
      //isConfirmed: this.state.isConfirmed
    };
    //register wallet
    const data = {
      action: STRING.SUBSCRIBE_WALLET,
      ...(payment_source &&
        payment_source === toUpper(INSTRUMENTS.FRIMI) && {
        source: INSTRUMENTS.FRIMI,
      }),
    };
    if (
      commonWalletInfo &&
      commonWalletInfo.status === STRING.WALLET_ACTIVE &&
      commonWalletInfo.walletPaymentInstruments &&
      commonWalletInfo.walletPaymentInstruments.length > 0
    ) {
      let defaultPaymentInstrument = commonWalletInfo.walletPaymentInstruments.find(
        (obj) => {
          return obj.isDefault === true;
        }
      );
      if (
        payment_source &&
        payment_source === toUpper(INSTRUMENTS.FRIMI) &&
        defaultPaymentInstrument.type !== "Account" &&
        !defaultPaymentInstrument.name.includes(startCase(INSTRUMENTS.FRIMI))
      ) {
        registerCommonWallet({ ...data, purchaseRequest: buyWallet });
      } else {
        //call buy
        randomBuyWithWallet(buyWallet);
        // website tracking code segment - manual work
        let dataLayerObject = {
          tickets_count: this.state.count,
        };
        dataPush(dataLayerObject);
      }
    } else {
      setNextEventLocalStorage({
        event: EVENTS.WALLET_RANDOM_BUY,
        props: buyWallet,
      });
      registerCommonWallet({ ...data, purchaseRequest: buyWallet });
    }
    setPaymentMethodToLocalStorage(paymentMethod);
  }

  buyButton() {
    let { disable } = this.state;
    const {
      data: { dark_color, draw_id, lottery_name },
      t,
      packages,
    } = this.props;
    var pack = _.find(packages, { package_name: lottery_name });

    var buttonStyle = {
      border: `1px solid ${dark_color}`,
      backgroundColor: dark_color,
      color: "#ffffff",
    };

    //if (currentStep === 2) {
    return (
      <button
        className="btn btn-block mr-1"
        id="buy-modal-buy-button"
        style={buttonStyle}
        type="button"
        disabled={disable}
        onClick={() => this.handleBuyClick(pack.package_id, draw_id)}
      >
        {t("buymodal.buy")}
      </button>
    );
    //}
    //return null;
  }

  handleBuyClick(packageId, draw_id) {
    const { randomBuy, setPaymentMethodToLocalStorage } = this.props;
    const { count, isCompartmentCharge } = this.state;
    this.setState({ disable: true });
    randomBuy({
      packageId: packageId,
      drawId: draw_id,
      count: count,
      paymentMethod: "MOBILE",
      source: "WEB",
      salesType: "ON_DEMAND",
      isCompartmentCharge: isCompartmentCharge,
      customerReferral: sessionStorage.getItem("referral_code"),
    });

    // website tracking code segment - manual work
    let dataLayerObject = {
      tickets_count: this.state.count,
    };
    dataPush(dataLayerObject);
    setPaymentMethodToLocalStorage(PAYMENT_METHOD.MOBILE);
  }

  // ==================================================================================
  renderCount(count) {
    const {
      t,
      data: { lottery_price, dark_color },
    } = this.props;
    var style = {
      border: `1px solid ${dark_color}`,
      color: dark_color,
      backgroundColor: "#ffffff",
      selected: "",
    };

    if (this.state.count === count) {
      style.color = "#ffffff";
      style.backgroundColor = dark_color;
      style.selected = " selected";
    }

    return (
      <div key={count} className="col-4 p-1">
        {count === 50 ? (
          <button
            className="btn btn-block mr-1"
            id="buy_others_button_id"
            type="button"
            style={style}
            onClick={() => this.setState({ loadEditText: true })}
          >
            <h1>+</h1>
            <small>{t("const_file.other")}</small>
          </button>
        ) : (
          <button
            className={"btn btn-block mr-1" + style.selected}
            type="button"
            style={style}
            id="buy_button_id"
            onClick={() => {
              // piwik pro related code segment
              let dataLayerObject = {
                tickets_count: count,
              };
              dataPush(dataLayerObject);

              this.setState({ count });
            }}
          >
            <h1>{count}</h1>
            <small>
              {t("const_file.rs")}
              {count * lottery_price}
            </small>
          </button>
        )}
      </div>
    );
  }

  // Render the pay method in the second step
  renderPayMethod() {
    const {
      data: { dark_color },
      t,
    } = this.props;
    const { mobile, wallet, both } = this.state;

    var notSelected = {
      border: `1px solid ${dark_color}`,
      color: dark_color,
      backgroundColor: "#ffffff",
      selected: "",
    };

    var selected = {
      border: `1px solid ${dark_color}`,
      color: "#ffffff",
      backgroundColor: dark_color,
      selected: " selected",
    };

    return (
      <React.Fragment>
        <div key={1} className="col-4 p-1">
          <button
            className={
              "btn btn-block mr-1 p-2 py-3" + (mobile ? " selected" : "")
            }
            type="button"
            style={mobile ? selected : notSelected}
            onClick={() =>
              this.setState({ mobile: !mobile, wallet: false, both: false })
            }
          >
            <h5>{t("subscribemodal.mobile")}</h5>
          </button>
        </div>
        <div key={2} className="col-4 p-1">
          <button
            className={
              "btn btn-block mr-1 p-2 py-3" + (wallet ? " selected" : "")
            }
            type="button"
            style={wallet ? selected : notSelected}
            onClick={() =>
              this.setState({ wallet: !wallet, mobile: false, both: false })
            }
          >
            {/* <h5>{'Wallet'}</h5> */}
            <h5>{t("subscribemodal.credit_debit_card")}</h5>
          </button>
        </div>
        <div key={3} className="col-4 p-1">
          <button
            className={
              "btn btn-block mr-1 p-2 py-3" + (both ? " selected" : "")
            }
            type="button"
            style={both ? selected : notSelected}
            onClick={() =>
              this.setState({ both: !both, mobile: false, wallet: false })
            }
          >
            <h5>{t("subscribemodal.both")}</h5>
          </button>
        </div>
      </React.Fragment>
    );
  }

  updateInput(event) {
    event.preventDefault();
    // this.setState({ mobile_pin: event.target.value });

    this.setState({ count: event.target.value });

    // piwik pro tracking
    let dataLayerObject = {
      tickets_count: event.target.value,
    };
    dataPush(dataLayerObject);

    // if (!regex.test(event.target.value)) {
    //     this.setState({ count: 1 });
    //     // ReactDOM.findDOMNode(this.refs.ticket_count_sub).value = 1;
    // }

    //     if (event.target.value <= 0) {
    //         if (event.target.value !== "") {
    //             this.setState({ count: 1 });
    //             // ReactDOM.findDOMNode(this.refs.ticket_count_sub).value = 1;
    //         }
    //     } else {
    //         this.setState({ count: event.target.value });
    //     }
  }

  renderEditText() {
    const { t } = this.props;

    return (
      <div className="col-12 p-1">
        {
          <input
            type="number"
            ref="ticket_count_buy"
            min="1"
            className="form-control"
            placeholder={t("buymodal.enter_no_tickets_buy")}
            value={this.state.count}
            onChange={this.updateInput}
          />
        }
      </div>
    );
  }

  loadLotteryName(lottery_name) {
    const { t } = this.props;
    var pack = _.find(LOTTERY_LIST, { value: lottery_name });
    // return lottery_name;
    return pack === undefined ? lottery_name : t("lottery_tickets." + pack.key);
  }

  // handleConfirmationCheck = () => {
  //     const { isConfirmed } = this.state;
  //     this.setState({
  //       isConfirmed: !isConfirmed
  //     });
  // }

  setDropdownChangeValue(paymentMethod) {
    switch (paymentMethod) {
      case "MOBILE":
        this.setState({
          mobile: true,
          wallet: false,
          both: false,
          isCompartmentCharge: 1,
        });
        break;
      case "WALLET":
        this.setState({
          wallet: true,
          mobile: false,
          both: false,
          isCompartmentCharge: 0,
        });
        break;
      case "BOTH":
        this.setState({
          both: true,
          mobile: false,
          wallet: false,
          isCompartmentCharge: 0,
        });
        break;
      // case "WIN_BALANCE":
      //     this.setState({ wallet: true, mobile: false, both: false, isCompartmentCharge: 1 });
      //     break;
    }
  }

  // =======================================================================

  step1() {
    const { currentStep, wallet, mobile, both, count } = this.state;
    const { t, data, payment_source } = this.props;

    // if(commonWalletInfo && (wallet === false)){
    //     this.setState({ wallet: true, mobile: false, both: false });
    // }

    if (currentStep !== 1) {
      return null;
    } else {
      return (
        <React.Fragment>
          {/* <Wizad page={1} color={data.dark_color} total={2} /> */}
          <h5>{t("buymodal.no_tickets_buy")}</h5>
          <div className="my-3 px-3 row">
            {this.state.loadEditText
              ? this.renderEditText()
              : [1, 2, 5].map((count) => {
                return this.renderCount(count);
              })}
            {this.state.loadEditText
              ? ""
              : [10, 20, 50].map((count) => {
                return this.renderCount(count);
              })}
          </div>
          {/* <p className="my-3  text-center">{t("const_file.convenient_fee")}</p> */}
          <br />
          <h5>{t("subscribemodal.select_pay_method")}</h5>
          <div className="my-3 px-3 row">
            {/* {this.renderPayMethod()} */}
            <select
              disabled={
                payment_source && payment_source === toUpper(INSTRUMENTS.FRIMI)
                  ? true
                  : false
              }
              className="form-control search-dropdown text-center px-0 col-12"
              id="select-payment-method-in-buy-modal"
              onChange={(e) => {
                // piwik pro related code segment
                let dataLayerObject = {
                  event: "selectsPaymentMethod",
                  paymentMethod: e.target.value,
                  tickets_count: count,
                };
                dataPush(dataLayerObject);

                this.setDropdownChangeValue(e.target.value);
              }}
            >
              <option value="MOBILE" selected={mobile}>
                {t("subscribemodal.mobile")}
              </option>
              <option value="WALLET" selected={wallet}>
                {t("subscribemodal.credit_debit_card")}
              </option>
              <option value="BOTH" selected={both}>
                {payment_source === toUpper(INSTRUMENTS.FRIMI)
                  ? "FriMi"
                  : t("subscribemodal.both")}
              </option>
              {/* <option value="WIN_BALANCE">{t("subscribemodal.win_balance")}</option> */}
              {/* {this.renderOptions(values)} */}
            </select>
          </div>

          {mobile ? (
            <>
              {/* onvenient_fee change in mobile view and added info icon */}
              <p style={{ fontSize: 10 }} className="my-3  text-center">
                {/* {t("const_file.convenient_fee")} */}
                {FEE.TEXT}{" "}
                {/* <button
                  type="button"
                  className="btn btn-link p-0 m-0 text-danger"
                  onClick={() => this.showMobileRates()}
                >
                  <FontAwesomeIcon icon={faExclamationCircle} />
                </button> */}
              </p>

              {/* {this.state.mobileRate ? ( */}
              <ul style={{ fontSize: 10 }}>
                {Object.values(FEE)
                  .filter((val) => val != FEE.TEXT)
                  .map((val) => (
                    <li>{val}</li>
                  ))}
              </ul>
              {/* ) : (
              "" */}
              {/* )} */}
            </>
          ) : (
            ""
          )}

          {wallet ? (
            <p style={{ fontSize: 12 }} className="my-3  text-center">{`${t(
              "const_file.minimum_topup_front"
            )}${MINIMUM_TOPUP_FEE}${t("const_file.minimum_topup_end")}`}</p>
          ) : (
            ""
          )}

          {/* {both ? (
            <p style={{ fontSize: 12 }} className="my-3  text-center">{`${t(
              "const_file.both_fee_front"
            )}${MINIMUM_TOPUP_FEE}${t("const_file.both_fee_end")}`}</p>
          ) : (
            ""
          )} */}

          <br />
          <div className="my-3 px-3 row">
            <div className="col-6">{t("subscribemodal.total_tickets")}</div>
            <div className="col-6 text-right">{count ? count : "0"}</div>
          </div>
          <div className="my-3 px-3 row">
            <div className="col-6">
              {t("subscribemodal.total_tickets_price")}
            </div>
            <div className="col-6 text-right">
              {count * data.lottery_price}.00 LKR
            </div>
          </div>
          <br />
          {/* <h5>{t("subscribemodal.confirmation")}</h5>
                    <div className="my-3 px-3 row">
                        <label><input type="checkbox" onChange={this.handleConfirmationCheck} />
                        &nbsp;{t("subscribemodal.charge_500_confirmation")} </label>
                    </div> */}
          {mobile && !wallet ? this.buyButton() : this.submitButton()}
          {/* {this.submitButton()} */}
        </React.Fragment>
      );
    }
  }

  step2() {
    const { currentStep, wallet, mobile, both } = this.state;
    const { t, data } = this.props;

    if (currentStep !== 2) {
      return null;
    }
    return (
      <React.Fragment>
        <Wizad page={2} color={data.dark_color} />
        <br />
        <h5>{t("subscribemodal.select_pay_method")}</h5>
        <div className="my-3 px-3 row pt-2">{this.renderPayMethod()}</div>
        <br />

        {mobile ? (
          <p style={{ fontSize: 12 }} className="my-3  text-center">
            {t("const_file.convenient_fee")}
          </p>
        ) : (
          ""
        )}

        {wallet ? (
          <p style={{ fontSize: 12 }} className="my-3  text-center">
            {" "}
            {`${t("const_file.minimum_topup_front")}${MINIMUM_TOPUP_FEE}${t(
              "const_file.minimum_topup_end"
            )}`}{" "}
          </p>
        ) : (
          ""
        )}

        {both ? (
          <p style={{ fontSize: 12 }} className="my-3  text-center">{`${t(
            "const_file.both_fee_front"
          )}${MINIMUM_TOPUP_FEE}${t("const_file.both_fee_end")}`}</p>
        ) : (
          ""
        )}

        <div className="row">
          <div className="col-6 pr-1">{this.previousButton()}</div>
          <div className="col-6 pr-1">
            {mobile && !wallet ? this.buyButton() : this.nextButton()}
          </div>
        </div>
      </React.Fragment>
    );
  }

  step3() {
    const { currentStep, count } = this.state;
    const { data, t } = this.props;

    if (currentStep !== 3) {
      return null;
    }

    return (
      <React.Fragment>
        <Wizad page={3} color={data.dark_color} />
        <br />
        <div className="my-3 px-3 row">
          <div className="col-6">{t("subscribemodal.total_tickets")}</div>
          <div className="col-6 text-right">{count ? count : "0"}</div>
        </div>
        <div className="my-3 px-3 row">
          <div className="col-6">{t("subscribemodal.total_tickets_price")}</div>
          <div className="col-6 text-right">
            {count * data.lottery_price}.00 LKR
          </div>
        </div>
        <br />
        <h5>{t("subscribemodal.confirmation")}</h5>
        <div className="my-3 px-3 row">
          <label>
            <input type="checkbox" onChange={this.handleConfirmationCheck} />
            &nbsp;{t("subscribemodal.charge_500_confirmation")}{" "}
          </label>
        </div>
        <br />

        <div className="row">
          <div className="col-6 pr-1">{this.previousButton()}</div>
          <div className="col-6 pr-1">{this.submitButton()}</div>
        </div>
      </React.Fragment>

      // <React.Fragment>
      //     <Wizad page={3} color={data.dark_color} />
      //     <br />
      //     <div className="my-3 px-3 row">
      //         <div className='col-6'>{t("subscribemodal.total_tickets")}</div>
      //         <div className='col-6 text-right'>{count ? count : '0'}</div>
      //     </div>
      //     <div className="my-3 px-3 row">
      //         <div className='col-6'>{t("subscribemodal.total_tickets_price")}</div>
      //         <div className='col-6 text-right'>{count * data.lottery_price}.00 LKR</div>
      //     </div>
      //     <br />
      //     <br />

      //     <div className="row">
      //         <div className="col-6 pr-1">
      //             {this.previousButton()}
      //         </div>
      //         <div className="col-6 pr-1">
      //             {this.submitButton()}
      //         </div>
      //     </div>
      // </React.Fragment>
    );
  }

  // closeMobileRates() {
  //   this.setState({
  //     mobileRate: false,
  //   });
  // }

  closeBuyModal() {
    const {
      closeDialog,
      clearNextEventLocalStorage,
      clearNextEvent,
    } = this.props;

    closeDialog(MODALS.BUY);
    clearNextEventLocalStorage();
    clearNextEvent();
    this.setState({
      mobileRate: false,
    });
  }

  showMobileRates() {
    this.setState({
      mobileRate: true,
    });
  }

  showMobileRates() {
    this.setState((prevState) => ({
      mobileRate: !prevState.mobileRate,
    }));
  }

  render() {
    const { t, data } = this.props;

    return (
      <div className="p-3">
        <div className="modal-header">
          <h5 className="modal-title">
            {t("buymodal.buy")} {t(this.loadLotteryName(data.lottery_name))}
          </h5>
          <button
            type="button"
            className="close"
            onClick={() => this.closeBuyModal()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <LoadingBar
          scope="loading_search"
          maxProgress={100}
          style={{ backgroundColor: data.dark_color }}
        />
        <div className="modal-body buy-modal">
          <React.Fragment>
            <form>
              {this.step1()}
              {this.step2()}
              {this.step3()}
            </form>
          </React.Fragment>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      closeDialog,
      randomBuy,
      randomBuyWithWallet,
      getCommonWalletInfo,
      registerCommonWallet,
      setNextEventLocalStorage,
      clearNextEventLocalStorage,
      clearNextEvent,
      getPaymentSource,
      setPaymentMethodToLocalStorage,
      getPaymentMethodFromLocalStorage,
    },
    dispatch
  );
}

function mapStateToProps({
  packages,
  commonWalletInfo,
  payment_source,
  payment_method,
}) {
  return { packages, commonWalletInfo, payment_source, payment_method };
}

export default compose(
  translate,
  reduxDialog({ name: MODALS.BUY, centered: true, backdrop: "static" }),
  connect(mapStateToProps, mapDispatchToProps)
)(BuyModal);
