import React, { Component } from 'react';
import glamorous from 'glamorous';
import { connect } from "react-redux";
import { translate } from 'react-switch-lang';

class Wizad extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: 1
        };
    }



    render() {
        const { page, color, t } = this.props;

        //Inline pseudo class style added here fot <li>
        const ListItem = glamorous.li({
            '&:not(:first-child)::before': {
                backgroundColor: color
            }
        })

        //Inline pseudo class style added here fot <a>
        const Link = glamorous.a({
            '&::before': {
                backgroundColor: color,
            },
        })


        let active = 'step-item active';
        let inActive = 'step-item'

        return (
            <ul className="step d-flex flex-nowrap">
                <ListItem className={page === 1 ? active : inActive}>
                    <Link href="#!" > {t("buttons.step") + ' 1'}</Link>
                </ListItem>
                <ListItem className={page === 2 ? active : inActive} style={{
                    ":before": {
                        background: color,
                    }
                }}>
                    <Link href="#!" > {t("buttons.step") + ' 2'}</Link>
                </ListItem>
                <ListItem className={page === 3 ? active : inActive}>
                    <Link href="#!"> {t("buttons.step") + ' 3'}</Link>
                </ListItem>
            </ul>
        )
    }
}

export default translate(connect()(Wizad));
