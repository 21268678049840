/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';


class TermTml extends Component{

    render (){
        return (<div>
            <h1><strong>விதிமுறைகள்</strong></h1>
            <p><span >தகுதி</span></p>
            <p><span >இலங்கை குடியுரிமை உடைய, 18 அல்லது அதற்கு மேற்பட்ட வயதுடைய &nbsp;எவருக்கும் லொத்தர் சீட்டிழுப்பில் பங்கேற்க முடியும். SMS மூலம் டிக்கெட் கொள்வனவு செய்பவர்கள், செல்லுபடியாகும் மொபைல் இணைப்பு மூலம் கொள்வனவு செய்ய வேண்டும். இருப்பினும், இந்த சேவையை பயன்படுத்த பின்வரும் தரப்பினர் தகுதியற்றவர்கள்: சந்தைப்படுத்துபவர்கள் மற்றும் அவர்களது குடும்பத்தவர்கள், சந்தைப்படுத்தல் சேவை வழங்குநர்கள், முகவர்கள் மற்றும் தொழில் ரீதியாக இந்த சீட்டிழுப்பில் ஈடுபடுபவர்கள்.</span></p>
           
            <p>&nbsp;</p>
            <p><span >பெறக்கூடிய லாட்டரிகள் மற்றும் சீட்டிழுப்பு தினங்கள்</span></p>
            <div className="table-responsive">
            <table>
                <tbody>
                    <tr>
                        <td>
                            <p><span >லொத்தர் உருப்படி</span></p>
                        </td>
                        <td>
                            <p><span >திங்கள்</span></p>
                        </td>
                        <td>
                            <p><span >செவ்வாய்</span></p>
                        </td>
                        <td>
                            <p><span >புதன்</span></p>
                        </td>
                        <td>
                            <p><span >வியாழன்</span></p>
                        </td>
                        <td>
                            <p><span >வெள்ளி</span></p>
                        </td>
                        <td>
                            <p><span >சனி</span></p>
                        </td>
                        <td>
                            <p><span >ஞாயிறு</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p><strong>மஹஜன</strong> <strong>சம்பத</strong></p>
                        </td>
                        <td>
                            <p><strong>&radic;</strong></p>
                        </td>
                        <td>
                            <p><strong>&radic;</strong></p>
                        </td>
                        <td>
                            <p><strong>&radic;</strong></p>
                        </td>
                        <td>
                            <p><strong>&radic;</strong></p>
                        </td>
                        <td>
                            <p><strong>&radic;</strong></p>
                        </td>
                        <td>
                            <p><strong>&radic;</strong></p>
                        </td>
                        <td>
                            <p><strong>&radic;</strong></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p><strong>நீரோகா</strong></p>
                        </td>
                        <td>
                            <p><strong>&radic;</strong></p>
                        </td>
                        <td>
                            <p><strong>&radic;</strong></p>
                        </td>
                        <td>&nbsp;</td>
                        <td>
                            <p><strong>&radic;</strong></p>
                        </td>
                        <td>
                            <p><strong>&radic;</strong></p>
                        </td>
                        <td>&nbsp;</td>
                        <td>
                            <p><strong>&radic;</strong></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p><strong>மெகா</strong> <strong>பவர்</strong></p>
                        </td>
                        <td>
                            <p><strong>&radic;</strong></p>
                        </td>
                        <td>&nbsp;</td>
                        <td>
                            <p><strong>&radic;</strong></p>
                        </td>
                        <td>&nbsp;</td>
                        <td>
                            <p><strong>&radic;</strong></p>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            <p><strong>கொவி</strong> <strong>செத</strong></p>
                        </td>
                        <td>&nbsp;</td>
                        <td>
                            <p><strong>&radic;</strong></p>
                        </td>
                        <td>&nbsp;</td>
                        <td>
                            <p><strong>&radic;</strong></p>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>
                            <p><strong>&radic;</strong></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p><strong>சுப்பிரி</strong> <strong>வாசனா</strong></p>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>
                            <p><strong>&radic;</strong></p>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>
                            <p><strong>&radic;</strong></p>
                        </td>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            <p><strong>ஜாதிக</strong> <strong>சம்பத</strong></p>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>
                            <p><strong>&radic;</strong></p>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>
                            <p><strong>&radic;</strong></p>
                        </td>
                        <td>&nbsp;</td>
                    </tr>
                </tbody>
            </table>
            </div>
            <p>&nbsp;</p>
            <p><span >டிக்கெட்டின் விலை</span></p>
                    <p><span >NLB ஆல் வெளியிடப்படும் விலை அமைப்பின் படி, ஒவ்வொரு லொத்தருக்கும் + 20% சௌகரிய கட்டணம் &nbsp;உரிய சேவை வழங்குனரால் வசூலிக்கப்படும்.</span></p>
            <p>&nbsp;</p>
            <p><span >டீலர் பதிவு எண்</span></p>
            <p><span >இல 59/6, க்ரிகோரி வீதி, கொழும்பு 7இல் அமைந்திருக்கும் டிஜிட்டல் எக்ஸ் லேப்ஸ் (பிரைவேட் லிமிட்டட்), டீலராக E-005 இன் கீழ் பதிவு செய்யப்பட்டுள்ளது.</span></p>
            <p>&nbsp;</p>
            <p><span >லொத்தர்களை கொள்வனவு செய்வது எப்படி</span></p>
            <p><span >லொத்தர்களை &nbsp;"பெறக்கூடிய லொத்தர்கள் மற்றும் சீட்டிழுப்பு தினங்கள்" மூலமோ, SMS &nbsp;அல்லது வெப் சைட் (www.lucky989.lk/www.lucky1.lk) வழியாகவோ பெற்றுக்கொள்ள முடியும். </span></p>
            <p><span >உரிய குறிச்சொல்லுடன் 989க்கு எஸ்எம்எஸ் அனுப்புவதன் மூலம் டிக்கெட்களை வாடிக்கையாளர்கள் பெற்றுக்கொள்ள முடியும். சந்தா மற்றும் உடனடி கோரிக்கை (ஒரு முறைக்கு மட்டுப்படுத்தப்பட கொள்வனவு) முறைகள் மூலம் கொள்வனவு செய்யக்கூடிய தெரிவு வாடிக்கையாளருக்கு உண்டு.</span></p>
            <ul>
                <li ><span >டிக்கெட்களை கொள்வனவு செய்வதற்கு, பயனர்களின் கையடக்கத் தொலைபேசி இணைப்பில் போதுமான கணக்கு மிகுதி இருக்க வேண்டும்.</span></li>
                <li ><span >செல்லுபடியாகும் தேசிய அடையாள அட்டை ஒன்றை கொண்டிருக்க வேண்டும்.</span></li>
                <li ><span >பயனர்கள் சந்தா செலுத்தியதும், வாராந்த கட்டணமுறை ஒதுக்கப்படும். கணக்கு மிகுதியின் அடிப்படையில், உடனடி சீட்டிழுப்பு டிக்கெட் வழங்கப்படும்.</span></li>
                <li ><span >பயனர்கள் பல தொகுப்புகளுக்கு சந்தா செலுத்தி, உடன் கோரிக்கை சேவையில் 50 டிக்கெட்டுக்கள் வரை கொள்வனவு செய்யலாம்.</span></li>
            </ul>
            <p>&nbsp;</p>
            <p><span >வெற்றி</span> <span >பெரும்</span> <span >சீட்டிழுப்பு</span></p>
            <p><span >லொத்தர்</span><span > &nbsp;</span><span >சீட்டிழுப்பு</span><span >, NLB</span><span >இன்</span> <span >பிரகடனப்படுத்தப்பட்ட</span> <span >திகதிகளின்படி</span><span >"</span><span >பெறக்கூடிய</span> <span >லொத்தர்கள்</span> <span >மற்றும்</span> <span >சீட்டிழுப்பு</span> <span >தினங்கள்</span><span >" </span><span >இல்</span> <span >குறிப்பிடப்பட்டுள்ளது</span><span >.</span></p>
            <p>&nbsp;</p>
            <p><span >வெற்றியாளர்</span> <span >விபரங்கள்</span></p>
            <p><span >லொத்தரின்</span> <span >பிரகாரம்</span><span >, </span><span >அனைத்து</span> <span >சந்தாதாரர்களுக்கும்</span><span > SMS &nbsp;</span><span >மூலமாக</span> <span >சீட்டிழுப்பின்</span> <span >அதிகாரப்பூர்வ</span> <span >முடிவு</span> <span >அறிவிக்கப்படும்</span><span >.</span></p>
            <p>&nbsp;</p>
            <p><span >வெற்றியாளர்</span> <span >பரிசுகள்</span></p>
            <div className="table-responsive">
            <table>
                <tbody>
                    <tr>
                        <td>
                            <p><span >ரூ</span> <strong>.20.00 -</strong><span >ரூ</span><strong>.1000.00</strong></p>
                        </td>
                        <td>
                            <p><span >Lucky1</span><span >இன்</span> <span >கணக்கு</span> <span >மிகுதி</span></p>
                        </td>
                        <td>
                            <p><span >ரூ</span><span > 20 </span><span >மற்றும்</span> <span >ரூ</span><span >. 1,000</span><span >க்கு</span> <span >இடைப்பட்ட</span> <span >பணப்பரிசுகள்</span> <span >வாடிக்கையாளரின்</span><span > Lucky1 </span><span >கணக்கு</span> <span >மிகுதிக்கு</span> <span >சேர்க்கப்படும்</span><span >. </span><span >வாடிக்கையாளர்கள்</span> <span >பணப்பரிசை</span> <span >பயன்படுத்தி</span> <span >மேலும்</span> <span >டிக்கெட்களை</span> <span >கொள்வனவு</span> <span >செய்யலாம்</span><span >.</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p><span >ரூ</span><strong>. 1,000 &ndash;</strong><span >ரூ</span><strong>.20,000</strong></p>
                        </td>
                        <td>
                            <p><span >மொபைல்</span> <span >பணம்</span> <span >அல்லது</span> <span >வங்கிப்</span> <span >பரிமாற்றம்</span></p>
                        </td>
                        <td>
                            <p><span >ரூ</span><span >. 1,000 </span><span >அல்லது</span><span > &nbsp;</span><span >ரூ</span><span >20,000</span><span >க்கு</span> <span >குறைவான</span> <span >பணப்பரிசிற்கு</span><span > :</span></p>
                            <p><span >◦ Lucky1 </span><span >கணக்குமிகுதிக்கு</span> <span >சேர்க்கப்படல்</span><span > &nbsp;(</span><span >இயல்புநிலை</span><span >)</span></p>
                            <p><span >◦ Lucky1 </span><span >அலுவலகத்திலிருந்து</span> <span >பெற்றுக்கொள்ளல்</span></p>
                            <p><span >◦ </span><span >கோரிக்கையின்</span> <span >நிமித்தம்</span> <span >வெற்றியாளரின்</span> <span >வங்கிக்</span> <span >கணக்கிற்கு</span> <span >வைப்புச்செய்தல்</span></p>
                            <p><span >◦ </span><span >கோரிக்கையில்</span> <span >நிமித்தம்</span> <span >வெற்றியாளரின்</span><span > mCash / eZCash </span><span >கணக்கிற்கு</span> <span >வைப்புச்செய்தல்</span></p>
                            <p><span >Lucky1 </span><span >அழைப்பாளர்</span> <span >நிலைய</span><span > &nbsp;</span><span >முகவர்</span><span > 0117989989 </span><span >மூலம்</span> <span >வெற்றியாளரை</span> <span >விரைவில்</span> <span >தொடர்புகொள்வார்</span><span >.</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p><strong>Rs. 20,000 </strong><strong>உம்</strong> <strong>அதற்கு</strong> <strong>மேலும்</strong></p>
                        </td>
                        <td>
                            <p><span >NLB </span><span >மூலம்</span></p>
                        </td>
                        <td>
                            <p><span >ரூ</span><span >. 20,000</span><span >க்கு</span> <span >மேற்பட்ட</span> <span >பணப்பரிசுகள்</span><span > &nbsp;</span><span >தேசிய</span> <span >லொத்தர்</span> <span >சபையிலிருந்து</span> <span >பெற்றுக்கொள்ளப்பட</span><span > &nbsp;</span><span >வேண்டும்</span><span >. Lucky1 </span><span >அழைப்பாளர்</span> <span >நிலைய</span><span > &nbsp;</span><span >முகவர்</span><span > 0117989989 </span><span >மூலம்</span> <span >வெற்றியாளரை</span> <span >விரைவில்</span> <span >தொடர்புகொண்டு</span> <span >பணப்பரிசை</span> <span >பெற்றுக்கொள்வது</span> <span >பற்றி</span> <span >விளக்குவார்</span><span >.</span></p>
                        </td>
                    </tr>
                </tbody>
            </table>
            </div>
            <p>&nbsp;</p>
            <p><span >சேவையிலிருந்து</span> <span >வெளியேற</span></p>
            <p><span >குறிச்சொல்லை</span> <span >டைப்</span> <span >செய்து</span><span >, </span><span >இடைவெளி</span> <span >விட்டு</span><span >, OFF </span><span >என</span> <span >டைப்</span> <span >செய்து</span><span > 989</span><span >இற்கு</span><span > SMS </span><span >செய்யவும்</span></p>
        </div>);
    }
};

export default TermTml;