/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import thunk from "redux-thunk";
import { loadingBarMiddleware } from 'react-redux-loading-bar';
import { createBrowserHistory } from 'history';
import LoadingBar from 'react-redux-loading-bar';

import './styles/css/main.css';

import reducers from './reducers';

import Home from './containers/Home';
import Results from './containers/Results';
import Account from './containers/Account';
import Terms from './containers/terms';
import About from './containers/About';
import HowItWorks from './containers/HowItWorks';
import PrivacyPolicy from './containers/PrivacyPolicy';


import Menu from './containers/Menu';
import NotificationBar from './containers/NotificationBar';
import Footer from './components/Footer';

import LoginModal from './containers/LoginModal';
import BuyModal from './containers/BuyModal';
import SearchModal from './containers/SearchModal';
import SubscribeModal from './containers/SubscribeModal';
import SubModal from './containers/SubModal';
import WarningModal from './containers/WarningModal';
import SignUpModal from './containers/SignUpModal';
import PinVerifyModal from './containers/pinVerifyModal';
import PasswordForgetModal from './containers/PasswordForgetModal';
import PasswordResetModal from './containers/PasswordResetModal';
import MobileNoResetModal from './containers/MobileNoResetModal';
import NICModal from './containers/NicModal';
import AdvertModal from './containers/AdvertModal';
import MobileEnterModal from './containers/MobileEnterModal';
import OtpVerificationModal from './containers/OtpVerificationModal';
import BannerModal from './containers/BannerModal';
// import withTracker from './utils/withTracker';


import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import { GOOGLE_TAG_MANAGER } from './config';

const tagManagerArgs = { gtmId: GOOGLE_TAG_MANAGER.GTM_ID };
TagManager.initialize(tagManagerArgs);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

ReactGA.pageview(window.location.pathname + window.location.search);

// function fireTracking() {
//     ReactGA.pageview(window.location.hash);
// }
// ReactGA.pageview(window.location.pathname + window.location.hash);


// const history = createBrowserHistory();

// history.listen((location, action) => {
//   window.ga.set({ page: location.pathname });
//   window.ga.pageview(location.pathname);
// });

const store = createStore(
    reducers,
    composeEnhancers(
        applyMiddleware(
            thunk,
            loadingBarMiddleware()
        )
    )
);

store.subscribe(() => {
    localStorage.setItem('TOKEN', store.getState().token);
    localStorage.setItem('language-loaded', store.getState().language);
});

ReactDOM.render(

    <Provider store={store}>
        <Router>
            <div className="container-fluid pos-rel">
                <div className="row">
                    <LoadingBar maxProgress={100} className="loading-bar bg-warning" />
                </div>
                <div className="row menu-bg">
                    <div className="container">
                        <Menu />
                    </div>
                </div>

                <div className="row">
                    <div className="container">

                        <NotificationBar />
                        <Switch>
                            <Route path="/about" component={About} />
                            <Route path="/howItWorks" component={HowItWorks} />
                            <Route path="/terms" component={Terms} />
                            <Route path="/privacyPolicy" component={PrivacyPolicy} />
                            <Route path="/results" component={Results} />
                            <Route path="/account" component={Account} />
                            <Route path="/:id" component={Home} />
                            <Route path="/" component={Home} />
                        </Switch>

                        <LoginModal />
                        <BuyModal />
                        <SearchModal />
                        <SubscribeModal />
                        <WarningModal />
                        <SubscribeModal />
                        <SignUpModal />
                        <PinVerifyModal />
                        <PasswordForgetModal />
                        <PasswordResetModal />
                        <MobileNoResetModal />
                        <NICModal />
                        <MobileEnterModal />
                        <AdvertModal />
                        <OtpVerificationModal />
                        <SubModal />
                        <BannerModal />
                    </div>
                </div>

                <div className="row footer-bg">
                    <div className="container-fluid px-0">
                        <Footer />
                    </div>
                </div>
            </div>
        </Router>
    </Provider>, document.getElementById('root'));

export default store;