import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import WalletButton from './WalletButton';
import './wallet.css'
import './styles/scss/main.css';
class Wallet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            wallet: props.commonWalletInfo,
            regObj: {
                "mobileNo": localStorage.getItem("mobile")
            }
        }
    }
    componentWillMount() {
        if (localStorage.getItem("wallet_account")) {
            // this.props.walletInfo();
        }
    }

    componentWillReceiveProps({ commonWalletInfo }) {
        if (commonWalletInfo) {
            this.setState({ wallet: commonWalletInfo })
        }
    }

    render() {
        const { wallet } = this.state;

        return (
            <div className="">

                <div className="container">
                    {<WalletButton/>}
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({  }, dispatch);
}

function mapStateToProps({
    wallet,
    payment_instrument,
    registerWallet,
    commonWalletInfo
}) {
    return { wallet, payment_instrument, registerWallet, commonWalletInfo };
}

export default withRouter((connect(
    mapStateToProps,
    mapDispatchToProps
)(Wallet)));