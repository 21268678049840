import React, { useState } from "react";

import { connect } from "react-redux";
import { translate, getLanguage } from "react-switch-lang";
import { withRouter } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  FacebookShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

class Urlbutton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      genarate: true,
    };
  }

  copyToClipboard = (e) => {
    this.textArea.select();
    document.execCommand("copy");

    e.target.focus();
  };

  copied = () => {
    const timeout = setTimeout(() => {
      if (this.state.copied) {
        this.setState({ copied: false });
      }
    }, 6000);
  };

  render() {
    const { t } = this.props;

    const { user } = this.props;

    const mobile = parseInt(`${user.mobile}`);
    const encodedMobile = mobile.toString(32);

    const shareUrl = `${process.env.REACT_APP_URL}?referral_code=${encodedMobile}`;

    return (
      <div className="bg-light rounded px-lg-5">
        <h5 className="pb-1"> {t("url_button.Reffer_word")}</h5>
        <p className="pb-1">{shareUrl}</p>
        <CopyToClipboard
          text={shareUrl}
          onCopy={() => this.setState({ copied: true })}
        >
          <button className=" btn btn-secondary" onClick={this.copied()}>
            {t("url_button.copy")}
          </button>
        </CopyToClipboard>
        <div className="Demo__some-network_facebook pl-1">
          <FacebookShareButton
            url={shareUrl}
            quote=""
            className="Demo__some-network__share-button"
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>
        </div>
        <div className="Demo__some-network">
          <WhatsappShareButton
            url={shareUrl}
            quote=""
            className="Demo__some-network__share-button"
          >
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
        </div>
        <div className=" pt-2 pl-1">
          {
            <span className={this.state.copied ? "" : "hide"}>
              {t("url_button.copied")}
            </span>
          }
        </div>
      </div>
    );
  }
}

function mapStateToProps({
  token,
  user,
  myTickets,
  myPackages,
  wallet,
  available_packages,
  commonWalletInfo,
}) {
  return {
    token,
    user,
    myTickets,
    myPackages,
    wallet,
    available_packages,
    commonWalletInfo,
  };
}
export default withRouter(translate(connect(mapStateToProps)(Urlbutton)));
