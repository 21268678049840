/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import reduxDialog, { closeDialog, openDialog } from 'redux-reactstrap-modal';
import { connect } from "react-redux";
import { compose } from 'recompose';
import { bindActionCreators } from "redux";
import { API_ROOT_URL, MODALS, EVENTS, GOOGLE_SIGN_IN, FACEBOOK_SIGN_IN } from '../config/';
import NotificationBarModal from './NotificationBarModal';

import { FacebookLogin } from 'react-facebook-login-component';
import { GoogleLogin } from 'react-google-login-component';

import { login, fbLogin, googleLogin, clearNotification } from "../actions/index";
import LoginForm from './LoginForm';
import {
    translate,
    getLanguage
} from 'react-switch-lang';
class LoginModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            mobile_no: "",
            password: ""
        }
    }



    login() {
        this.props.login(this.state.mobile_no, this.state.password);
    }

    goToSignUp() {
        let user = {
            name: "",
            typeSocial: false
        };
        this.props.clearNotification();
        this.props.closeDialog(MODALS.LOGIN);
        this.props.openDialog(MODALS.SIGNUP, user);
    }

    render() {
        const { t } = this.props;
        return (
            <div className="p-2">
                <div className="modal-header">
                    <h5 className="modal-title">{t("loginmodal.login")}</h5>
                    <button type="button" className="close" onClick={() => {
                        this.props.closeDialog('MODAL_LOGIN');
                        this.props.clearNotification();
                    }}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <NotificationBarModal />
                    <LoginForm />
                    {/* <div className="row py-3 border-top mt-3">
                        <div className="col-6 pr-1">
                            <FacebookLogin socialId={FACEBOOK_SIGN_IN.FACEBOOK_ID}
                                language="en_US"
                                scope="public_profile,email"
                                responseHandler={this.props.fbLogin}
                                xfbml={true}
                                fields="id,email,name"
                                version="v2.5"
                                className="facebook-login btn btn-facebook btn-block px-0"
                                buttonText={t("loginmodal.facebook_login")}/>
                        </div>
                        <div className="col-6 pl-1">
                            <GoogleLogin socialId={GOOGLE_SIGN_IN.GOOGLE_LIVE}
                                className="google-login btn btn-google btn-block px-0"
                                scope="email"
                                fetchBasicProfile={false}
                                responseHandler={this.props.googleLogin}
                                buttonText={t("loginmodal.google_login")} />
                        </div>
                    </div> */}
                    {/* <div className="bg-light rounded p-3 text-center">
                        <div> {t("loginmodal.no_account")}</div>
                        <div className="pt-3"><button type="submit" className="btn btn-primary btn-block" onClick={() => this.goToSignUp()}>{t("loginmodal.create_account")}</button></div>
                    </div> */}
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ login, fbLogin, googleLogin, closeDialog, openDialog, clearNotification }, dispatch);
}

export default translate(compose(reduxDialog({ name: 'MODAL_LOGIN', centered: true }), connect(null, mapDispatchToProps))(LoginModal));
