/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import reduxDialog, { closeDialog, openDialog } from 'redux-reactstrap-modal';
import { Button } from 'reactstrap';
import { connect } from "react-redux";
import { compose } from 'recompose';
import { bindActionCreators } from "redux";
import { API_ROOT_URL, MODALS, EVENTS } from '../config';
import NotificationBarModal from './NotificationBarModal';
import { translate } from 'react-switch-lang';
import { showNotificationModal, otpVerification, login } from "../actions/index";
import { dataPush } from '../utils/piwikDataLayerPush';

const TIMEOUT = 60;

class OtpVerificationModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            otp: "",
            currentCount: TIMEOUT,
        }

        this.updateInput = this.updateInput.bind(this);
    }

    timer() {
        this.setState({
            currentCount: this.state.currentCount - 1
        });
        //clear interval
        if (this.state.currentCount < 1) {
            clearInterval(this.intervalId);
        }
    }

    timeCount() {
        this.intervalId = setInterval(this.timer.bind(this), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    updateInput(event) {
        this.setState({ otp: event.target.value });
    }

    userMobileNumber() {
        localStorage.setItem('mobile', this.props.data.mobile);
        if (this.state.otp) {
            this.props.otpVerification({ "mobile": this.props.data.mobile, "otp": this.state.otp });

            // piwik pro related code segment
            let dataLayerObject = {
                event: "enterOtp"
            };
            dataPush(dataLayerObject);

        } else {
            var data = { type: 'danger', message: 'Please enter the OTP' }
            this.props.showNotificationModal(data);
        }
    }

    mobileEnterModel() {
        this.props.closeDialog(MODALS.OTP)
        this.props.openDialog(MODALS.LOGIN);
    }
    handleResend() {
        this.props.login({ "mobile": this.props.data.mobile });
        this.setState({ currentCount: TIMEOUT });
        this.timeCount();
    }
    componentDidMount() {
        this.props.showNotificationModal({ type: '', message: '' });
        clearInterval(this.intervalId)
        this.timeCount();
    }

    render() {
        const { currentCount } = this.state;
        const { t } = this.props;

        let timeSec = (currentCount >= 10) ? currentCount : "0" + currentCount;
        return (
            <div className="p-2">
                <div className="modal-header">
                    <h5 className="modal-title">{t("loginmodal.enter_otp_head")}</h5>
                    <button type="button" className="close" onClick={() => this.props.closeDialog(MODALS.OTP)}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <NotificationBarModal />
                    {currentCount > 0 ?
                        <button type="submit" className="float-right btn btn-link" disabled> {t("loginmodal.resend_in") + ' ' + timeSec}</button> :
                        <button type="submit" className="float-right btn btn-link" onClick={() => this.handleResend()}>  {t("loginmodal.resend")} </button>}

                    <input type="text" className="form-control" placeholder={t("loginmodal.enter_otp")}
                        onChange={this.updateInput}></input>
                    <div className="pt-3">
                        <button type="submit" className="btn btn-primary btn-block"
                            onClick={() => this.userMobileNumber()}>{t("loginmodal.submit")}
                        </button>
                        <button type="submit" className="btn btn-danger btn-block"
                            onClick={() => this.mobileEnterModel()}>{t("loginmodal.back")}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ login, otpVerification, closeDialog, openDialog, showNotificationModal }, dispatch);
}

export default compose(reduxDialog({
    name: 'OTP_VERIFICATION',
    centered: true
}), translate, connect(null, mapDispatchToProps))(OtpVerificationModal);
