/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import { combineReducers } from "redux";

import ongoingLotteries from "./ongoing_lotteries";
import packages from "./packages";
import results from "./results";
import user from "./user";
import token from "./token";
import myPackages from "./my_packages";
import myTickets from "./my_tickets";
import nextEvent from "./next_event";
import notifications from "./notifications";
import notificationsmodal from "./notificationsmodal";
import result_loading from "./result_loading";
import available_packages from "./available_packages";
import nic_update from "./nic_update";
import sharedStatus from "./shared_status";
// import subcribe_list from "./subcribe_list";
import dialog_app_kp from "./dialog_app_kp";
import language from "./language";
import registerWallet from "./reg_wallet";
import packagePrice from "./package_price";
import mobile_number from "./mobile_number";
import payment_source from "./payment_source";
import bannerAction from "./banner_action";

import { reducer as form } from "redux-form";
import { loadingBarReducer } from 'react-redux-loading-bar';
import { dialogReducer } from 'redux-reactstrap-modal';

import commonWalletInfo from "./wallet_info";
import wallet_balance from "./wallet_balance";
import payment_method from "./payment_method";

import myAgents from "./my_agents";
import myAgentsSales from "./my_agents_sales";


const rootReducer = combineReducers({
    ongoingLotteries,
    token,
    user,
    dialogReducer,
    results,
    form,
    loadingBar: loadingBarReducer,
    myPackages,
    myTickets,
    nextEvent,
    notifications,
    packages,
    notificationsmodal,
    result_loading,
    available_packages,
    nic_update,
    sharedStatus,
    dialog_app_kp,
    language,
    registerWallet,
    packagePrice,
    commonWalletInfo,
    mobile_number,
    payment_source,
    myAgents,
    wallet_balance,
    payment_method,
    bannerAction,
    myAgentsSales
});



export default rootReducer;
