/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";

import { userPasswordForgot } from "../actions/index";
import { translate, getLanguage } from "react-switch-lang";
class PasswordForgetForm extends Component {
  renderField(field) {
    const {
      meta: { touched, error }
    } = field;
    const className = `form-group ${touched && error ? "has-danger" : ""}`;
    var no_input_button = "";

    if (field.input.name === "mobile_no") {
      no_input_button = "form-control no_input_button";
    } else {
      no_input_button = "form-control";
    }

    return (
      <div className={className}>
        <label>{field.label}</label>
        <input
          className={no_input_button}
          type={field.type || "text"}
          {...field.input}
        />
        <div className="text-help">{touched ? error : ""}</div>
      </div>
    );
  }

  onSubmit(values) {
    this.props.userPasswordForgot(values);
  }

  render() {
    const { handleSubmit, t } = this.props;

    // console.log(this.props);
    return (
      <form onSubmit={handleSubmit(this.props.userPasswordForgot)}>
        <Field
          label={t("loginmodal.mobile_no")}
          name="mobile_no"
          component={this.renderField}
        />
        <Field
          label={t("loginmodal.new_password")}
          name="new_password"
          component={this.renderField}
          type="password"
        />
        <button type="submit" className="btn btn-primary btn-block">
          {t("forgot_password.send")}
        </button>
      </form>
    );
  }
}

function validate(values, props) {
  const errors = {};
  const { t } = props;

  // Validate the inputs from 'values'
  if (!values.mobile_no) {
    errors.mobile_no = t("validate.mobile_no");
  }
  if (!values.new_password) {
    errors.new_password = t("validate.new_password");
  }
  return errors;
}

export default translate(
  reduxForm({
    validate,
    form: "PasswordForgetForm"
  })(
    connect(
      null,
      { userPasswordForgot }
    )(PasswordForgetForm)
  )
);
