/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 *
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from "react";
import axios from "axios";
import { INSTRUMENTS, LOTTERY_LIST, PAYMENT_METHOD } from "../config/";
import _, { startCase, toUpper } from "lodash";
import reduxDialog, { closeDialog, openDialog } from "redux-reactstrap-modal";
import { connect } from "react-redux";
import { compose } from "recompose";
import LoadingBar, { showLoading, hideLoading } from "react-redux-loading-bar";
import { API_ROOT_URL, SEARCH_LIMIT, MODALS, EVENTS, STRING } from "../config/";
import { bindActionCreators } from "redux";
import { translate } from "react-switch-lang";

import {
  buy,
  buyWithWallet,
  getCommonWalletInfo,
  setNextEventLocalStorage,
  clearNextEventLocalStorage,
  clearNextEvent,
  getPaymentSource,
  setPaymentMethodToLocalStorage,
  getPaymentMethodFromLocalStorage
} from "../actions/index";
import { registerCommonWallet } from "../actions/wallet";
// import InfiniteScroll from 'react-infinite-scroller';
import NewTicket from "../components/NewTicket";
import ReactGA from "react-ga";
// import InfiniteLoader from 'react-infinite-loader'
// import InfiniteScroll from 'react-simple-infinite-scroll';
import Infinite from "react-infinite";
import { MINIMUM_TOPUP_FEE } from "../config";

class SearchModal extends Component {
  constructor(props) {
    super(props);
    this.fields = [];
    this.state = {
      all_tickets: [],
      selected_tickets: [],
      suggestions: false,
      offset: 0,
      hasMore: false,
      buy_all_enable: false,
      initialLoad: true,
      loadResponse: {},
      offsetValue: 4,
      isInfiniteLoading: false,
      currentStep: 1,
      mobile: false,
      wallet: false,
      both: false,
      buyAll: false,
      buySelected: false,
      fields: [],
      disabled: false,
      isCompartmentCharge: 0,
      //isConfirmed: false
    };

    this.closeSearchModal = this.closeSearchModal.bind(this);
  }

  componentWillMount() {
    const { getPaymentMethodFromLocalStorage, payment_method } = this.props;
    getPaymentMethodFromLocalStorage();
    this.setState({
      mobile: payment_method ? ((payment_method === PAYMENT_METHOD.MOBILE) ? true : false) : false,
      wallet: payment_method ? ((payment_method === PAYMENT_METHOD.WALLET) ? true : false) : false,
      both: payment_method ? ((payment_method === PAYMENT_METHOD.BOTH) ? true : false) : true,
    });
  }

  componentDidMount = () => {
    this.props.getPaymentSource();
  };

  searchTickets(no_exact_matches = false, offset = 0, selected_tickets = []) {
    const { draw_id, fields } = this.props.data;
    this.fields = this.state.fields;
    var self = this;
    self.props.showLoading("loading_search");
    var all_empty = _.every(self.fields, (a) => {
      return !a;
    });
    let arrayField = [];
    fields.forEach((element, index) => {
      if (element.format !== "3") {
        arrayField.push(self.fields[index] ? self.fields[index] : "*");
        // self.fields[index] = '*'
      }
    });

    // let f = self.fields
    var buy_enable = false;
    const url = `${API_ROOT_URL}/lottery/search?limit=${
      buy_enable ? 500 : SEARCH_LIMIT
    }&offset=${offset}`;
    axios
      .post(url, {
        drawId: draw_id,
        fields: arrayField,
      })
      .then(function(response) {
        if (response.data.data.length > 0) {
          self.state.offsetValue++;
          if (offset > 0) {
            let dataset = [];
            const { all_tickets } = self.state;
            dataset = all_tickets.concat(response.data.data);

            self.setState({
              offsetValue: self.state.offsetValue,
              loadResponse: response.data,
              all_tickets: dataset,
              selected_tickets: selected_tickets,
              suggestions: no_exact_matches,
              buy_all_enable: !(all_empty || self.fields.length === 0),
              initialLoad: false,
            });
          } else {
            self.setState({
              offsetValue: self.state.offsetValue,
              loadResponse: response.data,
              all_tickets: response.data.data,
              selected_tickets: selected_tickets,
              suggestions: no_exact_matches,
              buy_all_enable: !(all_empty || self.fields.length === 0),
              initialLoad: false,
            });
          }

          self.props.hideLoading("loading_search");

          var search_lottery = self.props.ongoingLotteries.data.find(
            (item) => item.draw_id == draw_id
          );
          ReactGA.event({
            category: "Ticket",
            action: "Search",
            label: search_lottery.lottery_name,
            value: 1,
          });

          // self.setState({ all_tickets: response.data.data, selected_tickets: [], suggestions: false, buy_all_enable: !(all_empty || self.fields.length === 0) });
        } else {
          self.getSuggestions(true);
        }
      })
      .catch(function() {
        self.setState({
          suggestions: true,
        });
        self.props.hideLoading("loading_search");
      });
  }

  getSuggestions(no_exact_matches = true, offset = 0, selected_tickets = []) {
    const { draw_id, fields } = this.props.data;
    var self = this;
    var all_empty = _.every(self.fields, (a) => {
      return !a;
    });

    let arrayField = [];
    fields.forEach((element, index) => {
      if (element.format !== "3") {
        arrayField.push(self.fields[index] ? self.fields[index] : "*");
        // self.fields[index] = '*'
      }
    });

    var buy_enable = !(all_empty || self.fields.length === 0);
    const url = `${API_ROOT_URL}/lottery/search?limit=${
      buy_enable ? 500 : SEARCH_LIMIT
    }&offset=${offset}`;
    axios
      .post(url, {
        drawId: draw_id,
        fields: arrayField,
      })
      .then(function(response) {
        if (response.data.suggestions.length > 0) {
          self.state.offsetValue++;
          if (offset > 0) {
            let dataset = [];
            const { all_tickets } = self.state;
            dataset = all_tickets.concat(response.data.suggestions);

            self.setState({
              offsetValue: self.state.offsetValue,
              isInfiniteLoading: false,
              loadResponse: response.data,
              all_tickets: dataset,
              selected_tickets: selected_tickets,
              suggestions: no_exact_matches,
              buy_all_enable: false,
            });
          } else {
            self.setState({
              offsetValue: self.state.offsetValue,
              isInfiniteLoading: false,
              loadResponse: response.data,
              all_tickets: response.data.suggestions,
              selected_tickets: selected_tickets,
              suggestions: no_exact_matches,
              buy_all_enable: false,
            });
          }

          var search_lottery = self.props.ongoingLotteries.data.find(
            (item) => item.draw_id == draw_id
          );

          ReactGA.event({
            category: "Ticket",
            action: "Suggestion",
            label: search_lottery.lottery_name,
            value: 1,
          });
        } else {
          self.setState({
            suggestions: true,
          });
        }
        self.props.hideLoading("loading_search");
      })
      .catch(function() {
        self.props.hideLoading("loading_search");
      });
  }
  loadMores() {}

  loadMore() {
    // if(0>this.state.as){
    var hasMore;
    if (this.state.suggestions) {
      var limit = this.state.buy_all_enable ? 500 : SEARCH_LIMIT;
      if (this.state.loadResponse.page.total > limit) {
        if (this.state.offset < this.state.loadResponse.page.total) {
          hasMore = true;
        } else {
          hasMore = false;
        }
      } else {
        hasMore = false;
      }
      this.setState({ hasMore: hasMore, isInfiniteLoading: false });
      if (hasMore) {
        this.setState({ offset: this.state.offset + SEARCH_LIMIT });
        setTimeout(function() {
          this.getSuggestions(
            true,
            this.state.offset + SEARCH_LIMIT,
            this.state.selected_tickets
          );
        }, 1000);
      }
    } else {
      var hasMore;
      var limit = this.state.buy_all_enable ? 500 : SEARCH_LIMIT;
      if (this.state.loadResponse.page.total > limit) {
        if (this.state.offset < this.state.loadResponse.page.total) {
          hasMore = true;
        } else {
          hasMore = false;
        }
      } else {
        hasMore = false;
      }
      this.setState({ hasMore: hasMore, isInfiniteLoading: false });
      if (hasMore) {
        this.setState({ offset: this.state.offset + SEARCH_LIMIT });
        setTimeout(
          function() {
            this.searchTickets(
              false,
              this.state.offset + SEARCH_LIMIT,
              this.state.selected_tickets
            );
          }.bind(this),
          1000
        );

        // setTimeout(function() { this.setState({position: 1}); }.bind(this), 3000);
      }
    }
    // }
  }

  refresh() {}

  selectTicket(ticket_id) {
    this.setState({
      selected_tickets: [...this.state.selected_tickets, ticket_id],
    });
  }

  deselectTicket(ticket_id) {
    var arrayvar = this.state.selected_tickets.slice();
    var index = arrayvar.indexOf(ticket_id);
    arrayvar.splice(index, 1);
    this.setState({ selected_tickets: arrayvar });
  }

  buyAll(type, packageId) {
    const { draw_id, lottery_name, lottery_id } = this.props.data;
    var lottery_ticket_ids = _.map(this.state.all_tickets, "lottery_ticket_id");
    const {
      buyWithWallet,
      registerCommonWallet,
      commonWalletInfo,
      setNextEventLocalStorage,
      payment_source,
    } = this.props;
    const { mobile, wallet, isConfirmed, isCompartmentCharge } = this.state;
    //register wallet
    const data = {
      action: STRING.SUBSCRIBE_WALLET,
      ...(payment_source &&
        payment_source === toUpper(INSTRUMENTS.FRIMI) && {
          source: INSTRUMENTS.FRIMI,
        }),
    };
    if (this.props.token === "" || this.props.token === null) {
      // this.props.openDialog(MODALS.LOGIN);
      // setNextEventLocalStorage({ event: EVENTS.ACCOUNT});
    } else {
      if (lottery_ticket_ids.length > 5000) {
        const data = {
          title: `Buy all ${lottery_name} tickets`,
          message: `Your will get charged Rs.${20 *
            lottery_ticket_ids.length}.00 + telecom fee + tax`,
          action: EVENTS.BUYALL,
          props: {
            draw_id,
            lottery_id,
            lottery_ticket_ids,
            mobile_no: "",
            purchased_source: "WEB_APP_USER",
            customerReferral: sessionStorage.getItem("referral_code"),
          },
        };

        this.props.openDialog(MODALS.WARNING, data);
      } else {
        if (type === "MOBILE") {
          this.props.buy({
            packageId: packageId,
            drawId: draw_id,
            ticketIds: lottery_ticket_ids,
            paymentMethod: "MOBILE",
            source: "WEB",
            salesType: "SEARCH_AND_BUY",
            isCompartmentCharge: isCompartmentCharge,
            customerReferral: sessionStorage.getItem("referral_code"),
          });
        } else {
          const buyWallet = {
            packageId: packageId,
            drawId: draw_id,
            ticketIds: lottery_ticket_ids,
            paymentMethod: type,
            source:
              payment_source === toUpper(INSTRUMENTS.FRIMI)
                ? toUpper(INSTRUMENTS.FRIMI)
                : STRING.WALLET_REQUEST_TYPE_WEB,
            salesType: "SEARCH_AND_BUY",
            isCompartmentCharge: isCompartmentCharge,
            customerReferral: sessionStorage.getItem("referral_code"),
          };

          if (
            commonWalletInfo &&
            commonWalletInfo.status === "Active" &&
            commonWalletInfo.walletPaymentInstruments &&
            commonWalletInfo.walletPaymentInstruments.length > 0
          ) {
            if (
              payment_source &&
              payment_source === toUpper(INSTRUMENTS.FRIMI) &&
              !commonWalletInfo.walletPaymentInstruments.isDefault &&
              commonWalletInfo.walletPaymentInstruments.type === "Account" &&
              commonWalletInfo.walletPaymentInstruments.name.includes(
                startCase(INSTRUMENTS.FRIMI)
              )
            ) {
              registerCommonWallet({ ...data, purchaseRequest: buyWallet });
            } else {
              //call buy
              buyWithWallet(buyWallet);
            }
          } else {
            setNextEventLocalStorage({
              event: EVENTS.WALLET_BUY,
              props: buyWallet,
            });
            registerCommonWallet({ ...data, purchaseRequest: buyWallet });
          }
        }

        this.setState({ all_tickets: [], disabled: true });
      }
    }
  }

  buySelected(type, packageId) {
    const { draw_id } = this.props.data;
    const {
      buyWithWallet,
      registerCommonWallet,
      commonWalletInfo,
      setNextEventLocalStorage,
      payment_source,
    } = this.props;
    const { selected_tickets, all_tickets, isCompartmentCharge } = this.state;
    //register wallet
    const data = {
      action: STRING.SUBSCRIBE_WALLET,
      ...(payment_source &&
        payment_source === toUpper(INSTRUMENTS.FRIMI) && {
          source: INSTRUMENTS.FRIMI,
        }),
    };
    if (type === STRING.PAYMENT_TYPE_MOBILE) {
      this.props.buy({
        packageId: packageId,
        drawId: draw_id,
        ticketIds: selected_tickets,
        paymentMethod: STRING.PAYMENT_TYPE_MOBILE,
        source: STRING.WALLET_REQUEST_TYPE_WEB,
        salesType: STRING.WALLET_SEARCH_SALES_TYPE,
        isCompartmentCharge: isCompartmentCharge,
        customerReferral: sessionStorage.getItem("referral_code"),
      });
    } else {
      const buyWalletSelected = {
        packageId: packageId,
        drawId: draw_id,
        ticketIds: selected_tickets,
        paymentMethod: type,
        source:
          payment_source === toUpper(INSTRUMENTS.FRIMI)
            ? toUpper(INSTRUMENTS.FRIMI)
            : STRING.WALLET_REQUEST_TYPE_WEB,
        salesType: STRING.WALLET_SEARCH_SALES_TYPE,
        isCompartmentCharge: isCompartmentCharge,
        customerReferral: sessionStorage.getItem("referral_code"),
      };
      if (
        commonWalletInfo &&
        commonWalletInfo.status === STRING.WALLET_ACTIVE &&
        commonWalletInfo.walletPaymentInstruments &&
        commonWalletInfo.walletPaymentInstruments.length > 0
      ) {
        if (
          payment_source &&
          payment_source === toUpper(INSTRUMENTS.FRIMI) &&
          !commonWalletInfo.walletPaymentInstruments.isDefault &&
          commonWalletInfo.walletPaymentInstruments.type === "Account" &&
          commonWalletInfo.walletPaymentInstruments.name.includes(
            startCase(INSTRUMENTS.FRIMI)
          )
        ) {
          registerCommonWallet({ ...data, purchaseRequest: buyWalletSelected });
        } else {
          //call buy
          buyWithWallet(buyWalletSelected);
        }
      } else {
        setNextEventLocalStorage({
          event: EVENTS.WALLET_BUY,
          props: buyWalletSelected,
        });
        registerCommonWallet({ ...data, purchaseRequest: buyWalletSelected });
      }
    }

    for (var i = 0; i < selected_tickets.length; i++) {
      var index = this.findIndexInData(all_tickets, selected_tickets[i]);
      all_tickets.splice(index, 1);
    }
    this.setState({
      all_tickets: all_tickets,
      selected_tickets: [],
      disabled: true,
    });
  }

  renderSearchResults() {
    var { dark_color } = this.props.data;
    var selected_tickets = this.state.selected_tickets;

    return this.state.all_tickets.map((ticket) => {
      var selectedTicket = false;
      if (selected_tickets.length !== 0) {
        var pack = _.find(selected_tickets, function(selected_ticket) {
          return selected_ticket === ticket.lottery_ticket_id;
        });
        if (pack) {
          selectedTicket = true;
        }
      }
      return (
        <NewTicket
          selectedTicket={selectedTicket}
          key={ticket.lottery_ticket_id}
          ticket={ticket}
          color={dark_color}
          onSelect={(ticket_id) => this.selectTicket(ticket_id)}
          onDeselect={(ticket_id) => this.deselectTicket(ticket_id)}
        />
      );
    });
  }

  renderOptions(values) {
    return Object.keys(values).map((element) => {
      return <option key={element}>{values[element]}</option>;
    });
  }

  scrolled() {
    // console.log("Scrolled");
  }

  findIndexInData(data, value) {
    for (var i = 0, l = data.length; i < l; i++) {
      if (data[i].lottery_ticket_id === value) {
        return i;
      }
    }
    return -1;
  }

  elementInfiniteLoad() {
    return <div className="infinite-list-item">Loading...</div>;
  }

  setSearchValue(index, value, range, format) {
    const fields = { ...this.state.fields };

    switch (format) {
      case "1":
        //@ts-check the alphabet, TODO: the range is hardcoded, need to modify
        if (/^[a-zA-Z]+$/.test(value) && value.length === 1) {
          fields[index] = value;
        } else {
          fields[index] = "";
        }
        break;
      case "2":
        let arr = range.split("-");
        if (parseInt(arr[0]) <= value && parseInt(arr[1]) >= value) {
          fields[index] = value;
        } else {
          fields[index] = "";
        }
        break;
      default:
        fields[index] = value;
        break;
    }
    this.setState({ fields });
  }

  renderFields() {
    const {
      t,
      data: { fields },
    } = this.props;
    return fields.map((field, index) => {
      var element = "";
      //Serial code no need to display in ui
      if (field.format !== "3") {
        if (field.format === "4") {
          var values = JSON.parse(field.values);
          Object.keys(values).map((ele) => {
            return (element = (
              <select
                className="form-control search-dropdown text-center px-0"
                onChange={(e) =>
                  this.setSearchValue(
                    index,
                    e.target.value,
                    field.values,
                    field.format
                  )
                }
              >
                <option>{t("searchmodal.select")}</option>
                {this.renderOptions(values[ele])}
              </select>
            ));
          });
        } else if (field.format === "2") {
          element = (
            <input
              type="number"
              className="form-control text-center px-0 search-number"
              onChange={(e) =>
                this.setSearchValue(
                  index,
                  e.target.value,
                  field.values,
                  field.format
                )
              }
              value={this.state.fields[index]}
            />
          );
        } else if (field.format === "1") {
          element = (
            <input
              type="text"
              className="form-control text-center px-0"
              onChange={(e) =>
                this.setSearchValue(
                  index,
                  e.target.value,
                  field.values,
                  field.format
                )
              }
              value={this.state.fields[index]}
            />
          );
        } else {
          element = (
            <input
              type="text"
              className="form-control text-center px-0"
              onChange={(e) =>
                this.setSearchValue(
                  index,
                  e.target.value,
                  field.values,
                  field.format
                )
              }
              value={this.state.fields[index]}
            />
          );
        }

        return (
          <div
            className="form-group text-center searchfeild"
            key={`field_${index}`}
          >
            <label className={"mb-0 small"}>
              {field.special_field
                ? field.field === "SPECIAL"
                  ? "Special No"
                  : t("searchmodal.special")
                : field.format === "4" ? field.field : field.values}
            </label>
            {element}
          </div>
        );
      }
    });
  }

  loadLotteryName(lottery_name) {
    const { t } = this.props;
    var pack = _.find(LOTTERY_LIST, { value: lottery_name });

    // return lottery_name;
    return pack === undefined ? lottery_name : t("lottery_tickets." + pack.key);
  }

  //================================= HANDLE NAVIGATION =================================================
  _next = () => {
    let currentStep = this.state.currentStep;
    currentStep = currentStep >= 2 ? 3 : currentStep + 1;
    this.setState({
      currentStep: currentStep,
    });
  };

  _prev = () => {
    let currentStep = this.state.currentStep;
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    this.setState({
      currentStep: currentStep,
      //isConfirmed: false
    });
  };

  /*
   * the functions for our button
   */
  previousButton() {
    let currentStep = this.state.currentStep;
    const {
      data: { dark_color },
      t,
    } = this.props;

    var buttonStyle = {
      border: `1px solid ${dark_color}`,
      backgroundColor: "#ffffff",
      color: dark_color,
    };

    if (currentStep !== 1) {
      return (
        <button
          className="btn btn-block mr-1"
          style={buttonStyle}
          type="button"
          onClick={this._prev}
        >
          {t("buttons.previous")}
        </button>
      );
    }
    return null;
  }

  submitButton() {
    const { currentStep, buyAll, mobile, wallet } = this.state;
    const {
      data: { dark_color, lottery_name },
      t,
      packages,
    } = this.props;
    var pack = _.find(packages, { package_name: lottery_name });

    let paymentMethod = mobile ? "MOBILE" : wallet ? "WALLET" : "BOTH";
    var buttonStyle = {
      border: `1px solid ${dark_color}`,
      backgroundColor: dark_color,
      color: "#ffffff",
    };
    if (currentStep === 2) {
      return (
        <button
          className="btn btn-block mr-1"
          style={buttonStyle}
          disabled={!this.state.wallet && !this.state.both}
          type="button"
          onClick={() =>
            buyAll
              ? this.buyAll(paymentMethod, pack.package_id)
              : this.buySelected(paymentMethod, pack.package_id)
          }
        >
          {t("wallet.submit")}
        </button>
      );
    }
    return null;
  }

  buyButton() {
    const { buyAll, mobile, wallet } = this.state;
    const {
      data: { lottery_name },
      packages,
      payment_source,
      setPaymentMethodToLocalStorage
    } = this.props;

    var pack = _.find(packages, { package_name: lottery_name });
    let paymentMethod = mobile ? "MOBILE" : wallet ? "WALLET" : "BOTH";
    if (payment_source && payment_source === toUpper(INSTRUMENTS.FRIMI)) {
      paymentMethod = "WALLET";
    }

    if (buyAll) {
      this.buyAll(paymentMethod, pack.package_id);
    } else {
      this.buySelected(paymentMethod, pack.package_id);
    }

    setPaymentMethodToLocalStorage(paymentMethod);

    // var buttonStyle = {
    //   border: `1px solid ${dark_color}`,
    //   backgroundColor: dark_color,
    //   color: "#ffffff"
    // };

    // if (currentStep === 2) {
    //   return (
    //     <button
    //       className="btn btn-block mr-1"
    //       style={buttonStyle}
    //       type="button"
    //       // disabled={!(wallet || mobile || both) || (!mobile && this.state.isConfirmed === false) || disabled}
    //       disabled={!(wallet || mobile || both) || disabled}
    //       onClick={() =>
    //         buyAll ? this.buyAll(paymentMethod, pack.package_id) : this.buySelected(paymentMethod, pack.package_id)
    //       }
    //     >
    //       {t("buymodal.buy")}
    //     </button>
    //   )
    // }
    // return null;
  }

  handleBuySelected() {
    this.setState(
      {
        buySelected: true,
        buyAll: false,
      },
      () => {
        this.buyButton();
      }
    );

    // this.setState({ buySelected: true, buyAll: false })
    // this.buyButton()
    // this._next()
  }

  handleBuyAll() {
    this.setState(
      {
        buyAll: true,
        buySelected: false,
      },
      () => {
        this.buyButton();
      }
    );

    // this.setState({ buyAll: true, buySelected: false })
    // this.buyButton()
    // this._next()
  }

  setDropdownChangeValue(paymentMethod) {
    switch (paymentMethod) {
      case "MOBILE":
        this.setState({
          mobile: true,
          wallet: false,
          both: false,
          isCompartmentCharge: 1,
        });
        break;
      case "WALLET":
        this.setState({
          wallet: true,
          mobile: false,
          both: false,
          isCompartmentCharge: 0,
        });
        break;
      case "BOTH":
        this.setState({
          both: true,
          mobile: false,
          wallet: false,
          isCompartmentCharge: 0,
        });
        break;
      // case "WIN_BALANCE":
      //     this.setState({ wallet: true, mobile: false, both: false, isCompartmentCharge: 1 });
      //     break;
    }
  }

  // handleConfirmationCheck = () => {
  //   const { isConfirmed } = this.state;
  //   this.setState({
  //     isConfirmed: !isConfirmed
  //   });
  // }

  // Render the pay method in the second step
  renderPayMethod() {
    const {
      data: { dark_color },
      t,
    } = this.props;
    const { mobile, wallet, both } = this.state;

    var notSelected = {
      border: `1px solid ${dark_color}`,
      color: dark_color,
      backgroundColor: "#ffffff",
      selected: "",
    };

    var selected = {
      border: `1px solid ${dark_color}`,
      color: "#ffffff",
      backgroundColor: dark_color,
      selected: " selected",
    };

    return (
      <React.Fragment>
        <div key={1} className="col-4 p-1">
          <button
            type="button"
            className={
              "btn btn-block mr-1 p-2 py-3" + (mobile ? " selected" : "")
            }
            style={mobile ? selected : notSelected}
            // onClick={() => this.setState({ mobile: !mobile, wallet: false, both: false, isConfirmed: false })}
            onClick={() =>
              this.setState({ mobile: !mobile, wallet: false, both: false })
            }
          >
            <h5>{t("subscribemodal.mobile")}</h5>
          </button>
        </div>
        <div key={2} className="col-4 p-1">
          <button
            type="button"
            className={"btn btn-block mr-1 p-3" + (wallet ? " selected" : "")}
            style={wallet ? selected : notSelected}
            // onClick={() => this.setState({ wallet: !wallet, mobile: false, both: false, isConfirmed: false })}
            onClick={() =>
              this.setState({ wallet: !wallet, mobile: false, both: false })
            }
          >
            <h5>{t("subscribemodal.credit_debit_card")}</h5>
          </button>
        </div>
        <div key={3} className="col-4 p-1">
          <button
            type="button"
            className={"btn btn-block mr-1 p-3" + (both ? " selected" : "")}
            style={both ? selected : notSelected}
            // onClick={() => this.setState({ both: !both, mobile: false, wallet: false, isConfirmed: false })}
            onClick={() =>
              this.setState({ both: !both, mobile: false, wallet: false })
            }
          >
            <h5>{t("subscribemodal.both")}</h5>
          </button>
        </div>
      </React.Fragment>
    );
  }

  step1() {
    const {
      t,
      data: { dark_color, lottery_price },
      payment_source,
    } = this.props;

    var edgeoffset =
      (this.state.all_tickets.length - this.state.offset) *
      this.state.offsetValue;
    edgeoffset = edgeoffset + this.state.offsetValue;

    const { currentStep, wallet, mobile, both } = this.state;

    if (currentStep !== 1) {
      return "";
    } else {
      return (
        <React.Fragment>
          <div className="d-flex flex-row">{this.renderFields()}</div>

          <button
            type="button"
            onClick={() => this.searchTickets()}
            className="btn btn-block"
            id="search_button_id"
            style={{
              background: dark_color,
              borderColor: dark_color,
              color: "#ffffff",
            }}
          >
            {t("searchmodal.search")}
          </button>
          {this.state.suggestions ? (
            <p className="mt-3">
              {this.state.all_tickets.length
                ? t("searchmodal.not_match")
                : "Tickets are not available"}
            </p>
          ) : (
            ""
          )}
          {this.state.all_tickets.length ? (
            <div className="new_scroll">
              {/* <InfiniteScroll
                          pullDownToRefresh
                          dataLength={this.state.all_tickets.length} //This is important field to render the next data
                          pullDownToRefreshContent={
                              <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
                          }
                          onScroll={()=>{
                              
                          }}
                          releaseToRefreshContent={
                              <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
                          }
                          loadMore={this.loadMore()}
                          refreshFunction={this.refresh.bind(this)}
                          hasMore={this.state.hasMore}
                          loader={<h4 style={{ textAlign: 'center', color: 'white' }}>Loading...</h4>}
                          endMessage={
                              <p style={{ textAlign: 'center', color: 'white' }}>
                              <b>Yay! You have seen it all</b>
                              </p>
                          }>
                          <div className="my-3 search-results" >
                              {this.renderSearchResults()}
                          </div>
                      </InfiniteScroll> */}
              <Infinite
                elementHeight={50}
                containerHeight={250}
                infiniteLoadBeginEdgeOffset={edgeoffset}
                onInfiniteLoad={this.loadMore.bind(this)}
                loadingSpinnerDelegate={this.elementInfiniteLoad()}
                isInfiniteLoading={this.state.isInfiniteLoading}
              >
                {this.renderSearchResults()}
              </Infinite>
              <br />
              <h5>{t("subscribemodal.select_pay_method")}</h5>
              <div className="my-3 px-3 row">
                {/* {this.renderPayMethod()} */}
                <select
                  disabled={
                    payment_source &&
                    payment_source === toUpper(INSTRUMENTS.FRIMI)
                      ? true
                      : false
                  }
                  className="form-control search-dropdown text-center px-0 col-12"
                  onChange={(e) => this.setDropdownChangeValue(e.target.value)}
                >
                  <option value="MOBILE" selected={mobile}>{t("subscribemodal.mobile")}</option>
                  <option value="WALLET" selected={wallet}>
                    {t("subscribemodal.credit_debit_card")}
                  </option>
                  <option value="BOTH" selected={both}>
                    {payment_source === toUpper(INSTRUMENTS.FRIMI)
                      ? "FriMi"
                      : t("subscribemodal.both")}
                  </option>
                  {/* <option value="WIN_BALANCE">{t("subscribemodal.win_balance")}</option> */}
                  {/* {this.renderOptions(values)} */}
                </select>
              </div>

              {mobile ? (
                <p style={{ fontSize: 12 }} className="my-3  text-center">
                  {t("const_file.convenient_fee")}
                </p>
              ) : (
                ""
              )}

              {wallet ? (
                <p style={{ fontSize: 12 }} className="my-3  text-center">
                  {" "}
                  {`${t(
                    "const_file.minimum_topup_front"
                  )}${MINIMUM_TOPUP_FEE}${t(
                    "const_file.minimum_topup_end"
                  )}`}{" "}
                </p>
              ) : (
                ""
              )}

              {both ? (
                <p style={{ fontSize: 12 }} className="my-3  text-center">
                  {" "}
                  {`${t("const_file.both_fee_front")}${MINIMUM_TOPUP_FEE}${t(
                    "const_file.both_fee_end"
                  )}`}{" "}
                </p>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
          {this.state.all_tickets.length ? (
            this.state.buy_all_enable ? (
              <div>
                <p className="my-3  text-center">
                  {/* {t("const_file.convenient_fee")} */}
                </p>
                <div className="row">
                  <div className="col-6 pr-1">
                    <button
                      className="btn btn-block"
                      type="button"
                      disabled={
                        this.state.selected_tickets.length ? false : true
                      }
                      style={{
                        background: dark_color,
                        borderColor: dark_color,
                        color: "#ffffff",
                      }}
                      onClick={() => this.handleBuySelected()}
                    >
                      {t("searchmodal.buy_selected")} (
                      {this.state.selected_tickets.length}) <br />
                      <small>
                        {" "}
                        {t("const_file.rs")}{" "}
                        {this.state.selected_tickets.length * lottery_price}{" "}
                      </small>
                    </button>
                  </div>
                  <div className="col-6 pl-1">
                    <button
                      className="btn btn-block"
                      type="button"
                      style={{
                        background: dark_color,
                        borderColor: dark_color,
                        color: "#ffffff",
                      }}
                      onClick={() => this.handleBuyAll()}
                    >
                      {t("searchmodal.buy_all")} (
                      {this.state.all_tickets.length}) <br />
                      <small>
                        {" "}
                        {t("const_file.rs")}{" "}
                        {this.state.all_tickets.length * lottery_price}{" "}
                      </small>
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <p className="my-3  text-center">
                  {/* {t("const_file.convenient_fee")} */}
                </p>
                <div className="row">
                  <div className="col-12">
                    <button
                      className="btn btn-block"
                      type="button"
                      disabled={
                        this.state.selected_tickets.length ? false : true
                      }
                      style={{
                        background: dark_color,
                        borderColor: dark_color,
                        color: "#ffffff",
                      }}
                      onClick={() => this.handleBuySelected()}
                    >
                      {t("searchmodal.buy_selected")} (
                      {this.state.selected_tickets.length}) <br />
                      <small>
                        {" "}
                        {t("const_file.rs")}{" "}
                        {this.state.selected_tickets.length * lottery_price}{" "}
                      </small>
                    </button>
                  </div>
                </div>
              </div>
            )
          ) : (
            ""
          )}
        </React.Fragment>
      );
    }
  }

  step2() {
    const { currentStep, wallet, mobile, both } = this.state;
    const { t } = this.props;

    if (currentStep !== 2) {
      return null;
    }

    // if(commonWalletInfo && (wallet === false)){
    //     this.setState({ wallet: true, mobile: false, both: false });
    // }

    return (
      <React.Fragment>
        {/* <br /> */}
        <h5>{t("subscribemodal.select_pay_method")}</h5>
        <div className="my-3 px-3 row pt-2">{this.renderPayMethod()}</div>

        {/* { (wallet || both) ? 
          <h5>{t("subscribemodal.confirmation")}</h5> 
           : ''
        }
        { (wallet || both) ? 
        <div className="my-3 px-3 row">
            <label><input type="checkbox" onChange={this.handleConfirmationCheck} />
            &nbsp;{t("subscribemodal.charge_500_confirmation")} </label>
        </div> : ''
        } */}
        <br />

        {mobile || both ? (
          <p className="my-3  text-center">{t("const_file.convenient_fee")}</p>
        ) : (
          ""
        )}

        <div className="row">
          <div className="col-6 pr-1">{this.previousButton()}</div>
          <div className="col-6 pr-1">
            {mobile && !wallet ? this.buyButton() : this.buyButton()}
          </div>
        </div>
      </React.Fragment>
    );
  }

  closeSearchModal() {
    const {
      closeDialog,
      clearNextEventLocalStorage,
      clearNextEvent,
    } = this.props;

    closeDialog("MODAL_SEARCH");
    clearNextEventLocalStorage();
    clearNextEvent();
  }

  render() {
    const {
      t,
      data: { lottery_name, dark_color },
    } = this.props;

    var edgeoffset =
      (this.state.all_tickets.length - this.state.offset) *
      this.state.offsetValue;
    edgeoffset = edgeoffset + this.state.offsetValue;

    return (
      <div className="p-2 modal_window">
        <div className="modal-header">
          <h5 className="modal-title">
            {t("searchmodal.search")} {t(this.loadLotteryName(lottery_name))}
          </h5>
          <button
            type="button"
            className="close"
            onClick={() => this.closeSearchModal()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <LoadingBar
          scope="loading_search"
          maxProgress={100}
          style={{ backgroundColor: dark_color }}
        />

        <div className="modal-body search-modal">
          <React.Fragment>
            <form>
              {this.step1()}
              {this.step2()}
            </form>
          </React.Fragment>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      buy,
      closeDialog,
      showLoading,
      hideLoading,
      openDialog,
      buyWithWallet,
      getCommonWalletInfo,
      registerCommonWallet,
      setNextEventLocalStorage,
      clearNextEventLocalStorage,
      clearNextEvent,
      getPaymentSource,
      setPaymentMethodToLocalStorage,
      getPaymentMethodFromLocalStorage
    },
    dispatch
  );
}
function mapStateToProps({
  state,
  commonWalletInfo,
  packages,
  payment_source,
  payment_method
}) {
  return { state, commonWalletInfo, packages, payment_source, payment_method };
}

export default compose(
  translate,
  reduxDialog({
    name: "MODAL_SEARCH",
    centered: true,
    size: "lg",
    backdrop: "static",
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SearchModal);
