/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import reduxDialog, { closeDialog } from 'redux-reactstrap-modal';
import { connect } from "react-redux";
import { compose } from 'recompose';

import { bindActionCreators } from "redux";
import { translate } from 'react-switch-lang';
import { deactivate, loadLotteryPackages, buy } from "../actions/index";
import { MODALS, EVENTS } from '../config';

class WarningModal extends Component {
    constructor(props) {
        super(props);
    }

    yes() {
        const { closeDialog, state, deactivate, loadLotteryPackages, buy, data: { action, props } } = this.props;
        switch (action) {
            case EVENTS.DEACTIVATE:
                deactivate(props);
                return state;
            case EVENTS.BUYALL:
                buy(props);
                return state;
            default:
                closeDialog(MODALS.WARNING);
                return "";
        }
    }

    render() {
        const { data: { title, message, action }, t } = this.props;
        let submit_button = t("buttons.yes");
        let cancel_button = t("buttons.no");
        if (action === EVENTS.BUYALL) {
            submit_button = t("buttons.ok");
            cancel_button = t("buttons.cancel");
        }
        return (
            <div className="p-3">
                <div className="modal-header">
                    <h5 className="modal-title">{title}</h5>
                    <button type="button" className="close" onClick={() => this.props.closeDialog(MODALS.WARNING)}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body text-center">
                    <h4>{message.head}</h4>
                    <small >{message.msg}</small>
                    <div className="row mt-3">
                        <div className="col-6 pr-1">
                            <button className="btn btn-block btn-success" onClick={() => this.yes()}>{submit_button}</button>
                        </div>
                        <div className="col-6 pl-1">
                            <button className="btn btn-block btn-danger" onClick={() => this.props.closeDialog(MODALS.WARNING)}>{cancel_button}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ closeDialog, deactivate, loadLotteryPackages, buy }, dispatch);
}

export default compose(reduxDialog({ name: MODALS.WARNING, centered: true }), translate, connect(null, mapDispatchToProps))(WarningModal);
