/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';


class TermSin extends Component{

    render (){
        return (<div><h1><strong>කොන්දේසි</strong></h1>
            <p><strong>සුදුසුකම්</strong></p>
            <p><span >වයස අවුරුදු 18ට හෝ ඊට වැඩි ශ්&zwj;රී ලාංකික පුරවැසියන්ට පමණක් මේ හරහා </span><span >ලොතරැයිපත්</span><span > මිලදී ගැනීමේ අවස්ථාව හිමිවේ. SMS කෙටි පණිවිඩ හරහා ලොතරැයිපත් මිලදී ගන්නා පිරිස් වලංගු ජංගම දුරකතන සම්බන්ධතාවක් හරහා එම කටයුතු සිදුකල යුතුවේ. කෙසේ නමුත්,පහත දැක්වෙන පිරිස් සඳහා මෙම සේවාව වලංගු නොවේ. අනුග්&zwj;රාහක ආයතනයේ සේවකයින් හෝ ඔවුන්ගේ පවුල් වල සාමාජිකයින්, අනුග්&zwj;රාහක ආයතනයට සේවා සපයන්නන්,නියෝජිතයින් සහ දිනුම් ඇදීම් සඳහා වෘත්තීයමය ලෙස දායකවන ඕනෑම අයෙක්.</span></p>
            
            <p>&nbsp;</p>
            <p><strong>ලොතරැයිපත් සහ දිනුම් අදින දිනයන් </strong></p>
                     <div className="table-responsive">
            <table>
            <tbody>
            <tr>
            <td>
            <p><span >ලොතරැයිය</span></p>
            </td>
            <td>
            <p><span >සඳුදා</span></p>
            </td>
            <td>
            <p><span >අඟහරුවාදා</span></p>
            </td>
            <td>
            <p><span >බදාදා</span></p>
            </td>
            <td>
            <p><span >බ්&zwj;රහස්පතින්දා</span></p>
            </td>
            <td>
            <p><span >සිකුරාදා</span></p>
            </td>
            <td>
            <p><span >සෙනසුරාදා</span></p>
            </td>
            <td>
            <p><span >ඉරිදා</span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><span >ගොවි සෙත</span></p>
            </td>
            <td>
            <p><span >&radic;</span></p>
            </td>
            <td>
            <p><span >&radic;</span></p>
            </td>
            <td>
            <p><span >&radic;</span></p>
            </td>
            <td>
            <p><span >&radic;</span></p>
            </td>
            <td>
            <p><span >&radic;</span></p>
            </td>
            <td>
            <p><span >&radic;</span></p>
            </td>
            <td>
            <p><span >&radic;</span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><span >මහජන සම්පත</span></p>
            </td>
            <td>
            <p><span >&radic;</span></p>
            </td>
            <td>
            <p><span >&radic;</span></p>
            </td>
            <td>&nbsp;</td>
            <td>
            <p><span >&radic;</span></p>
            </td>
            <td>
            <p><span >&radic;</span></p>
            </td>
            <td>&nbsp;</td>
            <td>
            <p><span >&radic;</span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><span >මෙගා පවර්</span></p>
            </td>
            <td>
            <p><span >&radic;</span></p>
            </td>
            <td>&nbsp;</td>
            <td>
            <p><span >&radic;</span></p>
            </td>
            <td>&nbsp;</td>
            <td>
            <p><span >&radic;</span></p>
            </td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            </tr>
            <tr>
            <td>
            <p><span >නීරෝගා</span></p>
            </td>
            <td>&nbsp;</td>
            <td>
            <p><span >&radic;</span></p>
            </td>
            <td>&nbsp;</td>
            <td>
            <p><span >&radic;</span></p>
            </td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>
            <p><span >&radic;</span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><span >ජාතික සම්පත</span></p>
            </td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>
            <p><span >&radic;</span></p>
            </td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>
            <p><span >&radic;</span></p>
            </td>
            <td>&nbsp;</td>
            </tr>
            <tr>
            <td>
            <p><span >සුපිරි වාසනා</span></p>
            </td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>
            <p><span >&radic;</span></p>
            </td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>
            <p><span >&radic;</span></p>
            </td>
            <td>&nbsp;</td>
            </tr>
            </tbody>
            </table>
            </div>
            <p>&nbsp;</p>
            <p><span >ලොතරැයි මිල</span><span >&nbsp;</span></p>
            <p><span >මෙය සෑම ලොතරැයියක්ම ජාතික ලොතරැයි මණ්ඩලය (</span><span >NLB) </span><span >විසින් ප්&zwj;රකාශයට පත් කර ඇති මිල ආකෘතියට අනුකූල වන අතර ඊට අමතරව සේවා සැපයුම් සන්නිවේදන ආයතනය විසින් අය කෙරෙන </span><span >20%</span><span > සේවා පහසුකම් සැලසීමේ ගාස්තුවද ඇතුලත් වේ.</span><span >&nbsp;</span></p>
            <p>&nbsp;</p>
            <p><span >අලෙවි නියෝජිත ලියාපදිංචි අංකය</span><span >&nbsp;</span></p>
            <p><span >අලෙවි නියෝජිතභාවය </span><span >E-005</span><span > යටතේ </span><span >DigitalX Labs </span><span >පෞද්ගලික සමාගම</span><span >, </span><span >අංක </span><span >59/6, </span><span >ග්&zwj;රෙගරි මාවත</span><span >, </span><span >කොළඹ </span><span >07</span><span > යන ලිපිනයට ලියාපදිංචි කොට ඇත.</span><span >&nbsp;</span></p>
            <p>&nbsp;</p>
            <p><span >ලොතරැයි මිලදී ගන්නේ කෙසේද?</span></p>
            <p><span >ඉතිරිව ඇති ලොතරැයි සහ දිනුම් ඇදීමේ දින යටතේ එදිනට තවදුරටත් ලබාගත හැකිව තිබෙන ලොතරැයිපත්</span><span >, </span><span >ජංගම දුරකථන කෙටි පණිවිඩයක් (</span><span >SMS) </span><span >එවීමෙන් හෝ වෙබ් අඩවියට (</span><span >www.lucky989.lk/www.lucky1.lk) </span><span >පිවිසීමෙන් මිලදී ගත හැකියි. ඒ අනුව පාරිභෝගිකයින්ට අදාල ලොතරැයිපතට අයත් </span><span >කේතය</span><span >(</span><span >Keyword</span><span >)</span><span > 989</span><span > ට </span><span >SMS </span><span >කිරීම ඔස්සේ</span><span >&nbsp;</span><span >ලොතරැයි මිලදී ගත හැකියි. සේවාවට ලියාපදිංචි වූ සහ ලියාපදිංචි නොවූ යන පාරිභෝගික දෙවර්ගයටම එදිනෙදා අවශ්&zwj;ය මොහොතකදී ලොතරැයිපත් මිලදී ගැනීමේ පහසුකම හිමිවේ.</span><span >&nbsp;</span></p>
    
            <ul>
            <li ><span > ලොතරැයිපත් මිලදී ගැනීම සඳහා, සේවා ලබාගන්නාගේ ජංගම දුරකතනයේ ප්&zwj;රමාණවත් ශේෂයක් තිබීම අනිවාර්ය වේ.</span></li>
            <li ><span >වලංගු ජාතික හැඳුනුම්පත් අංකයක් තිබිය යුතුය.</span></li>
            <li ><span >එක් ලොතරැයි පැකේජයක් සමඟ ලියාපංදිචි වීමෙන් පසුව සතිපතා ඒ වෙනුවෙන් ගාස්තු වෙන්කෙරේ. </span><span >ඔබගේ</span> <span >ගිණුම්</span> <span >ශේෂයට</span> <span >අනුව</span> <span >ක්ෂණික</span> <span >දිනුම්</span> <span >ඇදීම්</span> <span >වාරයේ</span> <span >ලොතරයිපත්</span> <span >ඔබගේ</span> <span >ජංගම</span> <span >දුරකතනය</span> <span >වෙත</span> <span >නිකුත්</span> <span >කෙරෙනු</span> <span >ඇත</span><span >. </span><span >සේවා</span> <span >ලබාගන්නන්ට</span> <span >එකවර</span> <span >ලොතරැයි</span> <span >රැසක්</span> <span >අයදුම්කළ</span> <span >හැකි</span> <span >අතර</span> <span >දිනකට</span> <span >එක්</span> <span >කෙටි</span> <span >පණිවිඩයක්</span><span > (SMS) </span><span >ඔස්සේ</span> <span >ලොතරැයි</span><span > 50</span><span >ක්</span> <span >දක්වා</span> <span >අයදුම්</span> <span >කල</span> <span >හැක</span><span >.</span></li>
            </ul>
            <p>&nbsp;</p>
            <p><strong>ජයග්&zwj;රාහි දිනුම් ඇදීම් </strong></p>
            <p><span >ලොතරැයි</span> <span >දිනුම්</span> <span >ඇදීම්</span><span >, &ldquo;</span><span >ලොතරැයිපත්</span> <span >සහ</span> <span >දිනුම්</span> <span >අදින</span> <span >දිනයන්</span><span >&rdquo; </span><span >යන</span> <span >විස්තරයෙහි</span> <span >දැක්වෙන</span> <span >පරිදි</span><span >, </span><span >ජාතික</span> <span >ලොතරැයි</span> <span >මණ්ඩලය</span> <span >විසින්</span> <span >ප්</span><span >&zwj;</span><span >රකාශයට</span> <span >පත්</span> <span >කර</span> <span >ඇති</span> <span >ආකාරයට</span> <span >සිදුවේ</span><span >.</span></p>
            <p>&nbsp;</p>
            <p><strong>ජයග්&zwj;රාහි ප්&zwj;රතිඵල </strong></p>
            <p><span >දිනුම්</span> <span >ඇදීමෙන්</span> <span >පසුව</span> <span >අදාල</span> <span >ලොතරැයියේ</span> <span >නිල</span> <span >ප්</span><span >&zwj;</span><span >රතිඵලය</span><span >, </span><span >ලොතරැයිපත්</span> <span >ලබාගත්</span> <span >සියළුම</span> <span >පාරිභෝගිකයින්</span> <span >වෙත</span><span > &nbsp;SMS - </span><span >කෙටි</span> <span >පණිවිඩයක්</span> <span >මඟින්</span> <span >දැනුම්දෙනු</span> <span >ලැබේ</span><span >.</span></p>
            <p>&nbsp;</p>
            <p><strong>ජයග්&zwj;රාහි ත්&zwj;යාග මුදල් </strong></p>
            <div className="table-responsive">
            <table>
            <tbody>
            <tr>
            <td>
            <p><span >රු.20.00 සිට &nbsp;රු.1000.00</span></p>
            </td>
            <td>
            <p><span >Lucky1 ශේෂයට එකතුවේ.</span></p>
            </td>
            <td>
            <p><span >රු.20 සිට රු. 1,000/- දක්වා වූ මුදල් ත්&zwj;යාග පාරිභෝගික ඔබගේ Lucky1 ශේෂයට එකතුවේ. දිනාගත් ත්&zwj;යාග මුදලට අනුව පාරිභෝගික ඔබට අමතර ලොතරැයිපත් මිලදී ගැනීමේ අවස්ථාව ද හිමිවේ.</span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><span >රු. 1,000 &ndash; &nbsp;රු. 20,000</span></p>
            </td>
            <td>
            <p><span >මොබයිල් කෑෂ් හෝ බැංකු ගණුදෙණු හරහා ලැබේ.</span></p>
            </td>
            <td>
            <p><span >රු.1000ට වැඩි ත්&zwj;යාග මුදල් සහ රු. 20,000 හෝ ඊට වැඩි ත්&zwj;යාග මුදල්;</span></p>
            <ul>
            <li ><span >Lucky1 ශේෂය වෙත මාරු කිරීමට</span></li>
            <li ><span >Lucky1 කාර්යාලය වෙතින් ලබාගැනීමට</span></li>
            <li ><span >ජයග්&zwj;රාහකයාගේ ඉල්ලීම මත ඔහුගේ/ඇයගේ බැංකු ගිණුමේ තැන්පත් කිරීමට</span></li>
            <li ><span >ජයග්&zwj;රාහකයාගේ ඉල්ලීම මත ඔහුගේ/ඇයගේ mCash/eZCash ගිණුම වෙත මාරු කිරීමට හැකියාව ලැබේ.</span></li>
            </ul>
            <p><span >Lucky1 ඇමතුම් නිලධාරියෙකු විසින් 0117989989 දුරකතනය ඔස්සේ ත්&zwj;යාග මුදල් දිනාගත් ජයග්&zwj;රාහකයින් සම්බන්ධ කරගනු ඇති.</span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><span >රු. 20,000 සහ ඉන් ඉහළට</span></p>
            </td>
            <td>
            <p><span >ජාතික ලොතරැයි මණ්ඩලය හරහා ලැබේ.</span></p>
            </td>
            <td>
            <p><span >රු. 20,000 ට වැඩි සියලුම ත්&zwj;යාග මුදල් ජාතික ලොතරැයි මණ්ඩලය වෙතින් ලබාගැනීම අනිවාර්ය වේ. Lucky1 ඇමතුම් නිලධාරියෙකු විසින් 0117989989 දුරකතනය ඔස්සේ ත්&zwj;යාග මුදල් දිනාගත් ජයග්&zwj;රාහකයින් සම්බන්ධ කරගනු ඇති.</span></p>
            </td>
            </tr>
            </tbody>
            </table>
            </div>
            <p>&nbsp;</p>
            <p><strong>සේවාවෙන් ඉවත්වීම සඳහා </strong></p>
            <p><span >කේතය</span><span >(keyword)&lt;හිස්තැනක්&gt;OFF යන්න සඳහන් කර 989ට SMS-කෙටි පණිවිඩයක් යොමුකරන්න.</span></p></div>);
    }
};

export default TermSin;
