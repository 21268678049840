/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import { LANGUAGE} from "../actions/index";
import Localstore from 'store';
export default function (state = null, action) {
    switch (action.type) {
        case LANGUAGE:
            localStorage.setItem('language-loaded', action.payload);        
            return localStorage.getItem('language-loaded');

        default:
            // localStorage.clear();
            return localStorage.getItem('language-loaded');
    }
}
