/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import { GET_MOBILE_NUMBER } from "../actions/index";
import { dataPush } from "../utils/piwikDataLayerPush";

export default function (state, action) {

    switch (action.type) {
        case GET_MOBILE_NUMBER:
            if (localStorage.getItem('mobile')){
                // piwik pro related code segment
                let dataLayerObject = {
                    event: "enterMobileNumber",
                    mobile: localStorage.getItem('mobile')
                };
                dataPush(dataLayerObject);
            }
            return localStorage.getItem('mobile') === "null" || localStorage.getItem('mobile') === null ? "" : localStorage.getItem('mobile');
        default:
            return localStorage.getItem('mobile') === "null" || localStorage.getItem('mobile') === null ? "" : localStorage.getItem('mobile');
    }
}
