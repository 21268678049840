/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 *
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component} from "react";
import { connect } from "react-redux";
import { TabContent, TabPane, Nav, NavItem, NavLink, Table } from "reactstrap";
import { withRouter } from "react-router-dom";
import { openDialog } from "redux-reactstrap-modal";
import axios from "axios";

import { bindActionCreators } from "redux";
import {
  loadAccount,
  logout,
  setNextEvent,
  loadMyLottery,
  loadAvaliableLotteries,
  loadHeaderMobile,
  showNotificationModal,
  subscribeWithWallet,
  buyWithWallet,
  randomBuyWithWallet,
  getCommonWalletInfo,
  loadMyAgents,
  getCommonWalletInfoNoConsentCheck,
  loadMyAgentsSales

} from "../actions/index";

import { MODALS, EVENTS, LIMIT, API_ROOT_URL } from "../config/";
import {
  X as CloseIcon,
  Filter as FilterIcon,
  RefreshCcw as Refresh,
  Download
} from "react-feather";
import { translate, getLanguage } from "react-switch-lang";
import MyTicket from "../components/MyTicket";
import MyPackage from "../containers/MyPackage";
import InfiniteScroll from "react-infinite-scroll-component";
import { LOTTERY_LIST } from "../config/";
import _, { forEach, isDate } from "lodash";
import Wallet from "./wallet/Wallet";
import queryString from "query-string";
import "react-day-picker/lib/style.css";
import DayPickerInput from "react-date-picker";
import moment from "moment";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { Field, reduxForm } from "redux-form";




import Urlbutton from "./Urlbutton";

import "react-dates/initialize";
import {
  DateRangePicker,
  SingleDatePicker,
  DayPickerRangeController,
} from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import {HORIZONTAL_ORIENTATION,VERTICAL_ORIENTATION} from "react-dates/constants";

import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
export const pureComponentAvailable = true;
const MAX_RANGE_VALUE = 24;
const MAX_VALUE = 100;
const MIN_VALUE = (-1<0<1);

const number = value => value && isNaN(Number(value)) ? 'Must be a number' : undefined

class Account extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      total:0,
      activeTab: "1",
      offset: 0,
      hasMore: true,
      result_loading: false,
      draw_number: "",
      drawIdValue: "Select",
      offsetSearch: 0,
      searchDate: "",
      width: props.width,
      show_filter: false,
      dateSupport: true,
      subFromDate: null,
      subToDate: null,
      saleFromDate: null,
      saleToDate: null,
      settlementStatus:"",
      packageId:"",
      salePackageId:"",
      mobileNo:"",
      saleMobileNo:"",
      settlementFromDate: "",
      settlementToDate: "",
      focusedInputSub: "",
      focusedInputSale: "",
      focusedInputSettlement: "",
      value: { min: 0, max: MAX_RANGE_VALUE },
      smallDevice:window.matchMedia('(max-width: 400px)').matches,
      orientation:window.matchMedia('(max-width: 400px)').matches ? VERTICAL_ORIENTATION : HORIZONTAL_ORIENTATION,
      loadingRef:false
    };
  }

  

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }


  
  componentWillReceiveProps({ history }) {
    const {
      subscribeWithWallet,
      randomBuyWithWallet,
      buyWithWallet,
    } = this.props;

    // This is to get the status of the combank response and handle
    const values = queryString.parse(history.location.search);

    // This is to check the url passing the params 'status'
    // If status is there, need to give success msg or continue the started process
    if (values.status) {
      if (values.status === "success") {
        history.push("/account");
        let pendingTask = localStorage.getItem("pendingProcess"); //..Getting the pending details if exists
        localStorage.setItem("pendingProcess", null); //..Remove the details once we get it

        // Here we check is there any pending tasks availabe
        // If its there, then continue the blocked process here
        if (pendingTask && pendingTask !== "null") {
          pendingTask = JSON.parse(pendingTask);

          // Here checking which task was in the process and continue that
          switch (pendingTask.processName) {
            case "SUBSCRIPTION":
              subscribeWithWallet(pendingTask.body); // This will continue subscription process
              history.push("/"); //..To redirect to the home page
              break;
            case "BUY":
              randomBuyWithWallet(pendingTask.body); // This will continue on-demand random buy process
              history.push("/"); //..To redirect to the home page
              break;
            case "SEARCH_AND_BUY":
              buyWithWallet(pendingTask.body); // This will continue on-demand search and buy process
              history.push("/"); //..To redirect to the home page
              break;
          }
        } else {
          // handleResponseFromComBase(values.status)
          //this.toggle("3");                           //..This will navigate to page 'My payments'
        }
      } else {
        //history.push("/account");
        //handleResponseFromComBase(values.status);     // This will show the error msg from pay-Add
      }
    }
  }

  componentDidMount() {
    let test = document.createElement("input");
    test.type = "date";
    if (test.type === "text") {
      this.setState({
        dateSupport: false,
      });
    }

    const {
      available_packages,
      token,
      history,
      openDialog,
      setNextEvent,
      loadAccount,
      loadMyLottery,
      loadAvaliableLotteries,
      handleResponseFromComBase,
      getCommonWalletInfo,
      loadMyAgents,
      getCommonWalletInfoNoConsentCheck,
      loadMyAgentsSales
    } = this.props;
    // this.props.loadHeaderMobile();
    this.setState({
      width: window.innerWidth,
    });

    // console.log(token)
    if (
      localStorage.getItem("TOKEN") === "" ||
      localStorage.getItem("TOKEN") === null
    ) {
      history.push("/");
      // openDialog(MODALS.LOGIN);
      // setNextEvent({ event: EVENTS.ACCOUNT });
      openDialog(MODALS.LOGIN);
      setNextEvent({ event: EVENTS.ACCOUNT });
    } else {
      getCommonWalletInfo();
      loadAccount();
      loadMyLottery();
      loadAvaliableLotteries();
      loadMyAgents({rangeTicket:this.state.value},true);
      getCommonWalletInfoNoConsentCheck();
      const timer = setTimeout(() => {
        this.setState({
          loadingRef:true
        })
      }, 3000);
      return () => clearTimeout(timer);
    }
  }

  renderTickets() {
    const { myTickets } = this.props;

    return myTickets.data.map((ticket) => {
      console.log(ticket)
      return <MyTicket key={ticket.serial_number} result={ticket} />;
    });
  }

  logout() {
    // sessionStorage.setItem("balance","null")
    // this.props.history.push("/");
    this.props.logout();
    window.location.href="/";
    
  }

  resetPassword() {
    this.props.openDialog(MODALS.PASSWORDRESET);
  }

  renderAgents(){
    const {myAgents} = this.props;

    this.setState({
      refferlDetailsArray: myAgents.data
    })
  }

  resetMobileNo() {
    this.props.openDialog(MODALS.MOBILENORESET);
  }

  renderPackages() {
    const { myPackages } = this.props;
    return myPackages.map((pack) => {
      return <MyPackage key={pack.package_id} result={pack} />;
    });
  }

  renderTableData() {
  }

  handleSubcriptionFromDateChange(event) {
    this.setState({ subFromDate: event.target.value });
  }

  handleSubcriptionToDateChange(event) {
    this.setState({ subToDate: event.target.value });
  }

  toggleFilter() {
    window.scrollTo(0, 0);
    this.setState({ show_filter: !this.state.show_filter });
    this.setState({ searchDate: "", draw_number: "", drawIdValue: "" });
    this.props.loadMyLottery(0);
  }

  loadLotteryName(lottery_name) {
    const { t } = this.props;
    var pack = _.find(LOTTERY_LIST, { value: lottery_name });

    // return lottery_name;
    return pack === undefined ? lottery_name : t("lottery_tickets." + pack.key);
  }

  renderSelect() {
    const { available_packages, t } = this.props;
    if (available_packages.length != 0) {
      return available_packages.data.map((result) => {
        return (
          <option key={result.id} value={result.id}>
            {t(this.loadLotteryName(result.name))}
          </option>
        );
      });
    }
  }

  changeSelectDrawId(event) {
    this.setState({ drawIdValue: event.target.value });
    // this.setState({drawIdValue: event.target.value});
  }

  handleDateChange(event) {
    this.setState({ searchDate: event.target.value });
    // this.setState({drawIdValue: event.target.value});
  }

  filterButtonClick() {
    this.setState({ show_filter: true, hasMore: true });
    var item = {};
    var offsetUpdated = 0;
    this.setState({ offsetSearch: 0 });
    if (
      !this.state.drawIdValue &&
      !this.state.draw_number &&
      !this.state.searchDate
    ) {
      this.props.loadMyLottery(offsetUpdated);
    } else {
      item = {
        lottery_id: this.state.drawIdValue,
        draw_number: this.state.draw_number,
        search_date: this.state.searchDate,
        id: 2,
      };

      this.props.loadMyLottery(offsetUpdated, item, true);
    }
  }

  filterButtonClickReferral(){
    if(!this.state.subFromDate && !this.state.subToDate
      && this.state.value['max'] == 0
      && (this.state.settlementStatus == null || this.state.settlementStatus == '')
      && (this.state.packageId == '')
      && (this.state.mobileNo == '')
      && !this.state.settlementFromDate
      && !this.state.settlementToDate){
      this.props.loadMyAgents()
    }else{
      const filters ={
        subFromDate:!this.state.subFromDate?null:moment(this.state.subFromDate._d).format("YYYY-MM-DD"),
        subToDate:!this.state.subToDate?null:moment(this.state.subToDate._d).format("YYYY-MM-DD"),
        rangeTicket:this.state.value,
        maxTicketValueRef:MAX_VALUE,
        settlementStatus:this.state.settlementStatus=null?"":this.state.settlementStatus,
        packageId:this.state.packageId,
        mobileNo:this.state.mobileNo,
        settlementFromDate:!this.state.settlementFromDate?"":moment(this.state.settlementFromDate._d).format("YYYY-MM-DD"),
        settlementToDate:!this.state.settlementToDate?"":moment(this.state.settlementToDate._d).format("YYYY-MM-DD")
      }
      this.props.loadMyAgents(filters,true)
    }
  }
  filterButtonClickSalesReferral(){
    if(!this.state.saleFromDate && !this.state.saleToDate
      && (this.state.salePackageId == '')
      && (this.state.saleMobileNo == '')
      ){
      this.props.loadMyAgentsSales()
    }else{
      const filters ={
        saleFromDate:!this.state.saleFromDate?null:moment(this.state.saleFromDate._d).format("YYYY-MM-DD"),
        saleToDate:!this.state.saleToDate?null:moment(this.state.saleToDate._d).format("YYYY-MM-DD"),
        salePackageId:this.state.salePackageId,
        saleMobileNo:this.state.saleMobileNo,
        download:false
      }
      this.props.loadMyAgentsSales(filters,true)
    }
  }

  resetButtonClick() {
    this.setState({
      offsetSearch: 0,
      drawIdValue: "",
      draw_number: "",
      searchDate: "",
      hasMore: true,
    });
    this.props.loadMyLottery();
  }

  resetButtonClickReferral(){
    this.setState({
      subFromDate: null,
      subToDate: null,
         packageId:"",
      mobileNo: "",
      settlementStatus:"",
      settlementFromDate: "",
      settlementToDate: "",
      focusedInputSub: "",
      focusedInputSettlement: "",
      value: { min: 0, max: MAX_RANGE_VALUE }
    })
    this.props.loadMyAgents({rangeTicket:this.state.value,maxTicketValueRef:MAX_VALUE})
  }

  downloadButtonClick(){
    if(!this.state.saleFromDate && !this.state.saleToDate
      && (this.state.salePackageId == '')
      && (this.state.saleMobileNo == '')
      ){
        const filters ={
            download:true
        }
      this.props.loadMyAgentsSales(filters,true)
    }else{
      const filters ={
        saleFromDate:!this.state.saleFromDate?null:moment(this.state.saleFromDate._d).format("YYYY-MM-DD"),
        saleToDate:!this.state.saleToDate?null:moment(this.state.saleToDate._d).format("YYYY-MM-DD"),
        salePackageId:this.state.salePackageId,
        saleMobileNo:this.state.saleMobileNo,
        download:true
      }
      this.props.loadMyAgentsSales(filters,true)
    }
  }

  updateInputDrawId(event) {
    this.setState({ draw_number: event.target.value });
  }

  loadMore() {
    const { myTickets } = this.props;
    // if (!this.props.result_loading) {
    //   return;
    // }
    if (!this.state.show_filter) {
      var hasMore;
      if (myTickets.page.total > LIMIT) {
        if (this.state.offset < myTickets.page.total) {
          hasMore = true;
        } else {
          hasMore = false;
        }
      } else {
        hasMore = false;
      }
      this.setState({ hasMore: hasMore, result_loading: true });
      if (hasMore) {
        this.setState({ offset: this.state.offset + LIMIT }, () => {
          this.props.loadMyLottery(this.state.offset);
        });
      }
    } else {
      var hasMore;
      if (myTickets.page.total > 10) {
        if (this.state.offsetSearch < myTickets.page.total) {
          hasMore = true;
        } else {
          hasMore = false;
        }
      } else {
        hasMore = false;
      }

      this.setState({ hasMore: hasMore, result_loading: true });
      var item = {
        lottery_id: this.state.drawIdValue,
        draw_number: this.state.draw_number,
        search_date: this.state.searchDate,
      };
      if (hasMore) {
        this.setState({ offsetSearch: this.state.offsetSearch + LIMIT }, () => {
          this.props.loadMyLottery(this.state.offsetSearch, item, false);
        });
      }
    }
  }

  refresh() {}

  scrolled() {
    // console.log("Scrolled");
  }

  renderSelect() {
    const { available_packages } = this.props;
    const { t } = this.props;
    if (available_packages.length != 0) {
      return available_packages.data.map(result => {
        return (
          <option value={result.id}>
            {t(this.loadLotteryName(result.name))}
          </option>
        );
      });
    }
  }

  renderPackageNames() {
    const { packages } = this.props;
    const { t } = this.props;
     if (packages.length != 0) {
  
       return packages.map(result => {
           return (
             <option value={result.package_id}>
                {result.package_name}
             </option>
           );
         });

      }
  }

 
 renderField = ({ input, label, type, meta: { touched, error, warning } }) => (
    <div>
      <label>{label}</label>
      <div>
        <input {...input} placeholder={label} type={type}/>
        {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
      </div>
    </div>
  )
  handleSubmit(values) {
    this.props.login(values);
  }


  componentDidUpdate(prevProps) {
    // Only recalculate the total if myAgentsSales has changed
    if (prevProps.myAgentsSales !== this.props.myAgentsSales) {
        this.calculateTotal();
    }
}

calculateTotal = () => {
    const { myAgentsSales } = this.props;
    console.log(myAgentsSales);
    let temptotal = 0;
    if (myAgentsSales && myAgentsSales.data && myAgentsSales.data.length > 0) {
        myAgentsSales.data.forEach(sale => {
          // console.log(sale);
            temptotal += sale.noOfTickets;
        });
        console.log(temptotal);
        this.setState({ total: temptotal });
    }
}

  render() {
   
    const { user, wallet, myPackages, myTickets, t, myAgents,handleSubmit,myAgentsSales } = this.props;
    const myAgentsBool = true;

    const refferalDetails = myAgents.data.map(obj => {
      
      // return {...obj, settlmentStatus: obj.settlmentStatus?[obj.subDate,obj.packageName]:"unsettled"};
      return {...obj, settlmentStatus: obj.settlmentStatus?"Commission Settled on "+obj.settlementDate:obj.noOfTickets <=1 ? obj.noOfTickets+" Ticket sold":obj.noOfTickets+" Tickets sold"}
      return obj;
    });
    const refferalSales = myAgentsSales.data.map(obj => {
      
      // return {...obj, settlmentStatus: obj.settlmentStatus?[obj.subDate,obj.packageName]:"unsettled"};
      // return {...obj}
      return obj;
    });
   
console.log(this.state.total);

    const columns = [{
      dataField: 'subDate',
      text:  `${t('My_Reffral.Subscription_Date')}`
    }, 
    {
      dataField: 'mobileNo',
      text: `${t('loginmodal.mobile_no')}`
    }
    , {
      dataField: 'packageName',
      text: `${t('My_Reffral.Package_Name')}`
    },
    {
      dataField: 'settlmentStatus',
      text: `${t('My_Reffral.Status')}`
    }, 
   ];

   const columnsSales = [{
    dataField: 'date',
    text:  `${t('My_Reffral.Sale_Date')}`
  }, {
    dataField: 'mobileNo',
    text: `${t('loginmodal.mobile_no')}`
  }, {
    dataField: 'packageName',
    text: `${t('My_Reffral.Package_Name')}`
  },
  {
    dataField: 'salesType',
    text: `${t('My_Reffral.Sale_Type')}`
  }, 
  // {
  //   dataField: 'nic',
  //   text: `${t('My_Reffral.NIC')}`
  // },
  {
    dataField: 'noOfTickets',
    text: `${t('My_Reffral.Number_of_Tickets')}`
  }, 
 ];

    const sizePerPageRenderer = ({
      options,
      currSizePerPage,
      onSizePerPageChange
    }) => (
      <div className="btn-group" role="group">
        {
          options.map((option) => {
            const isSelect = currSizePerPage === `${option.page}`;
            return (
              <button
                key={ option.text }
                type="button"
                onClick={ () => onSizePerPageChange(option.page) }
                className={ `btn ${isSelect ? 'btn-secondary' : 'btn-warning'}` }
              >
                { option.text }
              </button>
            );
          })
        }
      </div>
    );
    

    const options = {
      paginationSize: 3,
      pageStartIndex: 1,
      sizePerPageList: [{
        text: '5', value: 5
      }, {
        text: '10', value: 10
      },],
      sizePerPageRenderer
    };


    return (
      <div className="row p-1">
        <div className="col-12 my-2">
          {user.mobile ? (
            <div className="bg-light border rounded col-12 p-3">
              <div className="align-items-stretch justify-content-between d-flex flex-sm-row flex-column">
                <div className=" pb-4">
                  {/* <h3>{name}</h3> */}
                  <p>
                    {t("loginmodal.mobile_no") + " : " + user.mobile} <br />
                    {t("sign_up.nic_no") + " : " + (user.nic ? user.nic : "-")}
                    {wallet ? (
                      <span>
                        <br />
                        {/* {t("myaccount.wallet_balance") + " "} {wallet} */}
                      </span>
                    ) : (
                      ""
                    )}
                  </p>
                  {/* <button
                className="btn btn-primary btn-sm mr-3"
                onClick={() => this.resetPassword()}
              >
                {t("myaccount.change_password")}
              </button> */}

                  {/* <button
                className="btn btn-primary btn-sm mr-3"
                onClick={() => this.resetMobileNo()}
              >
                {t("Update Mobile Number")}
              </button> */}
                  <button
                    onClick={() => this.logout()}
                    className="btn btn-secondary btn-sm"
                    type="button"
                  >
                    {t("myaccount.logout")}
                  </button>
                </div>

                <Urlbutton />
              </div>
            </div>
          ) : (
            <div className="bg-light border rounded col-12 p-3">
              <button
                onClick={() => this.logout()}
                className="btn btn-secondary btn-sm"
                type="button"
              >
                {t("myaccount.logout")}
              </button>
            </div>
          )}
        </div>
        <div className="col-12 my-2">
          <Nav tabs className="nav-style">
            <NavItem>
              <NavLink
                className={this.state.activeTab === "1" ? "active" : ""}
                onClick={() => {
                  this.toggle("1");
                }}
              >
                {t("myaccount.my_ticket")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={this.state.activeTab === "2" ? "active" : ""}
                onClick={() => {
                  this.toggle("2");
                }}
              >
                {t("myaccount.my_packages")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={this.state.activeTab === "3" ? "active" : ""}
                onClick={() => {
                  this.toggle("3");
                }}
              >
                {t("myaccount.my_payments")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={this.state.activeTab === "4" ? "active" : ""}
                onClick={() => {
                  this.toggle("4");
                }}
              >
           {t("My_Reffral.My_Referral")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={this.state.activeTab === "5" ? "active" : ""}
                onClick={() => {
                  this.toggle("5");
                }}
              >
           {t("My_Reffral.My_Referral_Sales")}
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent
            className="border border-top-0 rounded-bottom"
            activeTab={this.state.activeTab}
          >
            <TabPane tabId="1">
              {this.state.width < 768 && !this.state.show_filter ? (
                <button
                  onClick={() => this.toggleFilter()}
                  className="btn btn-filter-show filter_btn"
                  type="button"
                >
                  <FilterIcon size={16} />
                </button>
              ) : (
                <div className="col-12 p-3 pt-5">
                  {this.state.width < 768 && this.state.show_filter ? (
                    <div className="closeIcon float-right">
                      <CloseIcon
                        color="black"
                        size={16}
                        onClick={() => this.toggleFilter()}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <h4>{t("myaccount.search_ticket")}</h4>
                  <form>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>{t("menu.lottery_name")}</label>
                          <select
                            onChange={this.changeSelectDrawId.bind(this)}
                            value={this.state.drawIdValue}
                            className="form-control form-control-lg"
                          >
                            <option value="Select">{t("menu.select")}</option>
                            {this.renderSelect()}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>{t("menu.draw_no")}</label>
                          <input
                            type="text"
                            className="form-control"
                            id="drawNumber"
                            placeholder={t("menu.draw_no")}
                            value={this.state.draw_number}
                            onChange={this.updateInputDrawId.bind(this)}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>{t("home.date")}</label>
                          {this.state.dateSupport ? (
                            <input
                              onChange={this.handleDateChange.bind(this)}
                              className="form-control zmDatePicker"
                              value={this.state.searchDate}
                              type="date"
                              myAgentsmin="2019-07-08"
                              id="example-date-input"
                            />
                          ) : (
                            <DayPickerInput
                              className={"form-control zmDatePicker"}
                              clearIcon={null}
                              inputProps={{
                                readOnly: true,
                              }}
                              format={this.state.searchDate}
                              onChange={(date) => {
                                this.setState({
                                  searchDate: moment(date).format("YYYY-MM-DD"),
                                });
                              }}
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-md-3 d-md-flex">
                        <div className="form-group  ml-md-1">
                          <label className="dummyLabel" />
                           <button
                            type="submit"
                            className="btn btn-primary btn-search-filter mx-auto"
                            onClick={() => this.filterButtonClick()}
                          >
                            {t("myaccount.search")}
                          </button> 
                        </div>
                        {this.state.width > 768 ? (
                          <div className="form-group">
                            <label className="dummyLabel" />
                            <button
                              type="submit"
                              className="btn btn-primary btn-search-filter reset mx-auto"
                              onClick={() => this.resetButtonClick()}
                            >
                              <Refresh
                                size={20}
                                onClick={() => this.toggleFilter()}
                              />
                            </button>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              )}
              <div className="py-3">
                {myTickets.data && myTickets.data.length ? (
                  <InfiniteScroll
                    pullDownToRefresh
                    dataLength={myTickets.data.length} //This is important field to render the next data
                    onScroll={this.scrolled.bind(this)}
                    pullDownToRefreshContent={
                      <h3 style={{ textAlign: "center" }}>
                        &#8595; Pull down to refresh
                      </h3>
                    }
                    releaseToRefreshContent={
                      <h3 style={{ textAlign: "center" }}>
                        &#8593; Release to refresh
                      </h3>
                    }
                    next={this.loadMore.bind(this)}
                    refreshFunction={this.refresh.bind(this)}
                    hasMore={this.state.hasMore}
                    loader={
                      <h4 style={{ textAlign: "center", color: "white" }}>
                        Loading...
                      </h4>
                    }
                    endMessage={
                      <p style={{ textAlign: "center", color: "white" }}>
                        <b>Yay! You have seen it all</b>
                      </p>
                    }
                  >
                    <div className="row mx-2 mx-lg-2">
                      {this.renderTickets()}
                    </div>
                  </InfiniteScroll>
                ) : (
                  <p className="p-3">{t("myaccount.no_purchase_ticket")}</p>
                )}
              </div>
            </TabPane>
            <TabPane tabId="2">
              <div className="py-3">
                <div className="row mx-2 mx-lg-2">
                  {myPackages.length ? (
                    this.renderPackages()
                  ) : (
                    <div>
                      <br />
                      <p className="p-3">
                        {t("myaccount.no_subcribe_package")}
                      </p>
                      <br />
                    </div>
                  )}
                </div>
              </div>
            </TabPane>
            <TabPane tabId="3">
              <div className="py-3">
                <Wallet />
              </div>
            </TabPane>
            <TabPane tabId="4">
              <div className="py-3">
              <form onSubmit={handleSubmit(this.handleSubmit)}>
                {/* ################################################################ */}

                <div>
                  <div class="row p-3 pt-5">
                     <div class="col-12 col-md-3">

                     <h4 className="pb-1">{t("My_Reffral.Filter")}</h4>
             
                              <div className="form-group">

                              

                              <div>
                                <div className="form-group  ml-md-1">
                                   <label>{t("loginmodal.mobile_no")}</label>
                                    <input
                                     type="number"
                                     className="form-control"
                                      id="mobileNumber"
                                      placeholder={t("loginmodal.mobile_no")}
                                      component={this.renderField}
                                      value={this.state.mobileNo}
                                      onChange={(e) => {
                                        if(e.target.value.length==11) return false; 
                                        console.log(e.target.value)
                                        this.setState(
                                          {
                                            mobileNo:e.target.value
                                          }
                                        )
                                   
                                      }
                                    

                                      }
                                      
                                      />
                                </div>
                                </div>
                                
                                <label>{t("My_Reffral.Subscription_Date")}</label>
                                <div>
                                  <DateRangePicker
                                    small
                                    readOnly
                                    showClearDates
                                    orientation={window.matchMedia("(max-width: 900px)").matches?VERTICAL_ORIENTATION:HORIZONTAL_ORIENTATION}
                                    isOutsideRange={(date) => {date.format()}}
                                    startDate={this.state.subFromDate} // momentPropTypes.momentObj or null,
                                    endDate={this.state.subToDate} // momentPropTypes.momentObj or null,
                                    onDatesChange={({ startDate, endDate }) =>
                                      this.setState({
                                        subFromDate: startDate,
                                        subToDate: endDate,
                                      })
                                    } // PropTypes.func.isRequired,
                                    focusedInput={this.state.focusedInputSub} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                    onFocusChange={(focusedInput) =>
                                      this.setState({
                                        focusedInputSub: focusedInput,
                                      })
                                    } // PropTypes.func.isRequired,
                                  />
                                </div> 
                              </div>

                              {/* <div className="form-group">
                                <label>{t("My_Reffral.Settlement_Date")}</label>
                                <div style={{width:"100%"}}>
                                  <DateRangePicker
                                    small
                                    readOnly
                                    showClearDates
                                    orientation={window.matchMedia("(max-width: 970px)").matches?VERTICAL_ORIENTATION:HORIZONTAL_ORIENTATION}
                                    isOutsideRange={(date) => {date.format()}}
                                    startDate={this.state.settlementFromDate}
                                    endDate={this.state.settlementToDate}
                                    onDatesChange={({ startDate, endDate }) =>
                                      this.setState({
                                        settlementFromDate: startDate,
                                        settlementToDate: endDate,
                                      })
                                    }
                                    focusedInput={
                                      this.state.focusedInputSettlement
                                    }
                                    onFocusChange={(focusedInput) =>
                                      this.setState({
                                        focusedInputSettlement: focusedInput,
                                      })
                                    }
                                  />
                                </div>
                              </div> */}
                           
                      
                              {/* <div className="form-group">
                                <label>{t("My_Reffral.Settlement_Status")}</label>
                                <select
                                  onChange={(e) => {
                                    this.setState(
                                      {
                                        settlementStatus:e.target.value
                                      }
                                    )}
                                  }
                                  value={this.state.settlementStatus}
                                  className="form-control form-control-lg"
                                >
                                  <option value="">{t("menu.select")}</option>
                                  <option value={true}>Settled</option>
                                  <option value="false">Unsettled</option>
                                </select>
                              </div> */}
                            

                              <div className="form-group">
                                <label>{t("My_Reffral.Package_Name")}</label>
                                <select
                                  onChange={(e) => {
                                    console.log(e.target.value)
                                    this.setState(
                                      {
                                        packageId:e.target.value
                                      }
                                    )
                               
                                  }
                                
                                    
                                  }
                              
                                  value={this.state.packageId}
                              
                                  className="form-control form-control-lg"
                                 
                                >
                                  
                                  <option value="Select">{t("menu.select")}</option>
                                  {this.renderPackageNames()}
                                </select>

                                </div>

                                  <div>
                                  <div class="row">
                     <div class="col-12 col-md-8 p-3">
                     
                              
                                <label className="dummyLabel" />
                                <button
                                  type="button"
                                  style={{width:"100%"}}
                                  className="btn btn-primary btn-search-filter mx-auto"
                                  onClick={() => this.filterButtonClickReferral()}
                                >
                                  {t("myaccount.search")}
                                </button>
                                 

                     </div>
                     <div class="col-12 col-md-4 p-3">
                    
                                <div>  

                                <div>
                                  <label className="dummyLabel" />
                                  <button
                                    type="submit"
                                    style={{width:"100%"}}
                                    className="btn btn-primary btn-search-filter reset mx-auto"
                                    onClick={() => this.resetButtonClickReferral()}
                                  >
                                    <Refresh
                                      size={20}
                                      onClick={() => this.toggleFilter()}
                                    />
                                  </button>       
                                </div>
                              </div>  
                             
                     </div>
                     </div>

                                  </div>

                                 </div>
                                
                     <div class="col-12 col-md-9">
                         
                     <div className="table-responsive px-2 p-2">
                       {this.state.loadingRef ?(
                         myAgents.data !=""?(
                          <BootstrapTable 
                          keyField='id' 
                          data={refferalDetails} 
                          columns={ columns } 
                          pagination={ paginationFactory(options) }
                          />
                         ):(
                          <div>
                          <br />
                          <p className="p-3">
                          {t("There are no records to show")}
                          </p>
                          <br />
                        </div>
                         )
                       ):(
                        <div>
                        <br />
                        <p className="p-3">
                     Loading....
                        </p>
                        <br />
                      </div>
                       )
                      }
                     
                      </div>

                     </div>
                  </div>
                </div>
                </form>
              </div>
            </TabPane>
            <TabPane tabId="5">
              <div className="py-3">
              <form onSubmit={handleSubmit(this.handleSubmit)}>
                {/* ################################################################ */}

                <div>
                  <div class="row p-3 pt-5">
                     <div class="col-12 col-md-3">

                     <h4 className="pb-1">{t("My_Reffral.Filter")}</h4>
             
                              <div className="form-group">

                              <div>
                                <div className="form-group  ml-md-1">
                                   <label>{t("loginmodal.mobile_no")}</label>
                                    <input
                                     type="number"
                                     className="form-control"
                                      id="saleMobileNumber"
                                      placeholder={t("loginmodal.mobile_no")}
                                      component={this.renderField}
                                      value={this.state.saleMobileNo}
                                      onChange={(e) => {
                                        if(e.target.value.length==11) return false; 
                                        console.log(e.target.value)
                                        this.setState(
                                          {
                                            saleMobileNo:e.target.value
                                          }
                                        )
                                   
                                      }                                    
                                        
                                      }
                                      
                                      />
                                </div>
                                </div>
                                
                                <label>{t("My_Reffral.Sale_Date")}</label>
                                <div>
                                  <DateRangePicker
                                    small
                                    readOnly
                                    showClearDates
                                    orientation={window.matchMedia("(max-width: 900px)").matches?VERTICAL_ORIENTATION:HORIZONTAL_ORIENTATION}
                                    isOutsideRange={(date) => {date.format()}}
                                    startDate={this.state.saleFromDate} // momentPropTypes.momentObj or null,
                                    endDate={this.state.saleToDate} // momentPropTypes.momentObj or null,
                                    onDatesChange={({ startDate, endDate }) =>
                                      this.setState({
                                        saleFromDate: startDate,
                                        saleToDate: endDate,
                                      })
                                    } // PropTypes.func.isRequired,
                                    focusedInput={this.state.focusedInputSale} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                    onFocusChange={(focusedInput) =>
                                      this.setState({
                                        focusedInputSale: focusedInput,
                                      })
                                    } // PropTypes.func.isRequired,
                                  />
                                </div> 
                              </div>

                              <div className="form-group">
                          <label>{t("menu.lottery_name")}</label>
                          <select
                            onChange={(e) => {
                              console.log(e.target.value)
                              this.setState(
                                {
                                  salePackageId:e.target.value
                                }
                              )
                            }
                            }
                            value={this.state.salePackageId}
                            className="form-control form-control-lg"
                          >
                            <option value="Select">{t("menu.select")}</option>
                            {this.renderSelect()}
                          </select>
                        </div>
       
                                  <div>
                                  <div class="row">
                     <div class="col-12 col-md-8 p-3">
                                                  
                                <label className="dummyLabel" />
                                <button
                                  type="button"
                                  style={{width:"100%"}}
                                  className="btn btn-primary btn-search-filter mx-auto"
                                  onClick={() => this.filterButtonClickSalesReferral()}
                                >
                                  {t("myaccount.search")}
                                </button>
                                                                                           
                     </div>
                     <div class="col-12 col-md-4 p-3">
                    
                                {/* <div>  

                                <div>
                                  <label className="dummyLabel" />
                                  <button
                                    type="submit"
                                    style={{width:"100%"}}
                                    className="btn btn-primary btn-search-filter reset mx-auto"
                                    onClick={() => this.downloadButtonClick()}
                                  >
                                    <Download
                                      size={20}
                                      onClick={() => this.toggleFilter()}
                                    />
                                  </button>       
                                </div>
                              </div>   */}
                             
                     </div>
                     </div>

                                  </div>
                               
                                 </div>        

                     <div class="col-12 col-md-9">
                         
                     <div className="table-responsive px-2 p-2">
                       {this.state.loadingRef ?(
                        //  myAgentsSales.data !=""?(
                        <>
                          <BootstrapTable 
                          keyField='id' 
                          data={refferalSales} 
                          columns={ columnsSales } 
                          pagination={ paginationFactory(options) }
                          />
                          <br/>
                          <p><b> Total : {this.state.total}</b></p>
                          
                          </>
                        //  ):(
                        //   <div>
                        //   <br />
                        //   <p className="p-3">
                        //   {t("There are no records to show")}
                        //   </p>
                        //   <br />
                        // </div>
                        //  )
                       ):(
                        <div>
                        <br />
                        <p className="p-3">
                     Loading....
                        </p>
                        <br />
                      </div>
                       )
                      }
                     
                      </div>
                   
                     </div>
                  </div>
                </div>
                </form>
              </div>
            </TabPane>
          </TabContent>
        </div>
      </div>
    );
  }
}

function validate(values, props) {
  const errors = {};
  const { t } = props;
  var regex = /(^((947|07|7)(0|1|2|4|5|6|7|8)[0-9]{7})$)/    //Check valid mobile number

  // Validate the inputs from 'values'
  if (!regex.test(values.mobile)) {
    errors.mobile = t("validate.mobile_no");
  }
  // if (!values.password) {
  //   errors.password = t("validate.password");
  // }
  return errors;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      openDialog,
      loadAccount,
      logout,
      setNextEvent,
      loadMyLottery,
      loadAvaliableLotteries,
      showNotificationModal,
      subscribeWithWallet,
      buyWithWallet,
      randomBuyWithWallet,
      getCommonWalletInfo,
      loadMyAgents,
      getCommonWalletInfoNoConsentCheck,
      loadMyAgentsSales
    },
    dispatch
  );
}

function mapStateToProps({
  token,
  user,
  myTickets,
  myPackages,
  wallet,
  available_packages,
  commonWalletInfo,
  myAgents,
  packages,
  myAgentsSales
}) {
  return {
    token,
    user,
    myTickets,
    myPackages,
    wallet,
    available_packages,
    commonWalletInfo,
    myAgents,
    packages,
    myAgentsSales
  };
}

export default withRouter(
  translate(
    reduxForm({
      validate,
      form: "Account"
    })(
    connect(
      mapStateToProps,
      mapDispatchToProps,
      
    )(Account)
  )
  )
);
