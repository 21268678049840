/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import reduxDialog, { closeDialog, openDialog } from "redux-reactstrap-modal";
import { API_ROOT_URL, MODALS, EVENTS } from "../config/";
import { translate, getLanguage } from "react-switch-lang";
import { login, clearNotification } from "../actions/index";
import { dataPush } from "../utils/piwikDataLayerPush";

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.goToPasswordForget = this.goToPasswordForget.bind(this);
  }

  goToPasswordForget() {
    this.props.clearNotification();
    this.props.closeDialog(MODALS.LOGIN);

    this.props.openDialog(MODALS.PASSWORDFORGET);
  }

  renderField(field) {
    const {
      meta: { touched, error }
    } = field;
    const className = `form-group ${touched && error ? "has-danger" : ""}`;
    var no_input_button = "";

    if (field.input.name === "mobile") {
      no_input_button = "form-control no_input_button";
    } else {
      no_input_button = "form-control";
    }

    // if (field.input.value && !field.meta.active){
    //   // piwik pro related code segment
    //   let dataLayerObject = {
    //     event: "enterMobileNumber",
    //     mobile: field.input.value
    //   };
    //   dataPush(dataLayerObject);
    // }

    return (
      <div className={className}>
        <label>{field.label}</label>
        <input
          className={no_input_button}
          type={field.type || "tel"}
          {...field.input}
          maxLength='11'
        />
        <div className="text-help">{touched ? error : ""}</div>
      </div>
    );
  }

  onSubmit(values) {
    this.props.login(values);
  }

  render() {
    const { handleSubmit, t } = this.props;

    return (
      <form onSubmit={handleSubmit(this.props.login)}>
        <Field
          label={t("loginmodal.mobile_no")}
          name="mobile"
          component={this.renderField}
        />
        {/* <Field
          label={t("loginmodal.password")}
          name="password"
          component={this.renderField}
          type="password"
        /> */}
        <button type="submit" className="btn btn-primary btn-block">
          {t("loginmodal.login")}
        </button>
        {/* <div className="text-center mt-2">
          <a href="#" onClick={this.goToPasswordForget.bind(this)}>
            {t("loginmodal.forgot_password")}
          </a>
        </div> */}
      </form>
    );
  }
}

function validate(values, props) {
  const errors = {};
  const { t } = props;
  var regex = /(^((947|07|7)(0|1|2|4|5|6|7|8)[0-9]{7})$)/    //Check valid mobile number

  // Validate the inputs from 'values'
  if (!regex.test(values.mobile)) {
    errors.mobile = t("validate.mobile_no");
  }
  // if (!values.password) {
  //   errors.password = t("validate.password");
  // }
  return errors;
}

export default translate(
  reduxForm({
    validate,
    form: "LoginForm"
  })(
    connect(
      null,
      { login, closeDialog, openDialog, clearNotification }
    )(LoginForm)
  )
);
