/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 *
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

//API BASE URL
export const API_ROOT_URL = process.env.REACT_APP_BASE_API;

// API MERCHANT PROXY URL
export const API_MERCHANT_PROXY_ROOT_URL =
  process.env.REACT_APP_MERCHANT_PROXY_BASE_API;

export const CLIENT_PROXY_ACCESS_TOKEN = process.env.REACT_APP_BO_TOKEN;

//Silverleap consts
export const MERCHANT_ID = process.env.REACT_APP_MERCHANT_ID;
export const MERCHANT_NAME = process.env.REACT_APP_MERCHANT_NAME;
export const BILLING_ADDRESS = process.env.REACT_APP_BILLING_ADDRESS;
export const DESCRIPTION = process.env.REACT_APP_DESCRIPTION;

export const LOTTERY_BUY_COUNT = 1;
export const LOTTERY_SUBCRIBE_COUNT = 1;

//Common wallet
export const COMMON_WALLET_LOGIN_URL =
  process.env.REACT_APP_COMMON_WALLET_LOGIN_URL;

export const MODALS = {
  BUY: "MODAL_BUY",
  SUBSCRIBE: "MODAL_SUBSCRIBE",
  SEARCH: "MODAL_SEARCH",
  LOGIN: "MODAL_LOGIN",
  WARNING: "MODAL_WARNING",
  SIGNUP: "MODAL_SIGNUP",
  PINVERIFY: "MODAL_PINVERIFY",
  PASSWORDFORGET: "MODAL_PASSWORD_FORGET",
  PASSWORDRESET: "MODAL_PASSWORD_RESET",
  NIC: "MODAL_NIC",
  MODALMOBILEENTER: "MODAL_MOBILE_ENTER",
  MOBILENORESET: "MODAL_MOBILENO_RESET",
  OTP: "OTP_VERIFICATION",
  SUB: "MODAL_SUB",
  NAMING: "MODAL_NAMING",
  TOPUP: "MODAL_TOPUP",
  BANNER: "MODAL_BANNER",
};

export const EVENTS = {
  RANDOM_BUY: "EVENT_RANDOM_BUY",
  SUBSCRIBE: "EVENT_SUBSCRIBE",
  ACCOUNT: "EVENT_ACCOUNT",
  BUY: "EVENT_BUY",
  DEACTIVATE: "EVENT_DEACTIVATE",
  TEMP_TOKEN: "TEMP_TOKEN",
  BUYALL: "BUYALL",
  SUBSCRIBE_WITH_WALLET: "SUBSCRIBE_WITH_WALLET",
  WALLET_RANDOM_BUY: "EVENT_WALLET_RANDOM_BUY",
  WALLET_BUY: "EVENT_WALLET_BUY",
  SEARCH_AND_BUY: "EVENT_SEARCH_AND_BUY",
};

export const LIMIT = 10;
export const SEARCH_LIMIT = 50;

//live
export const GOOGLE_SIGN_IN = {
  GOOGLE_LIVE:
    "1009481181622-um1e9mpmfko1slnpti7fdn3vornj29hd.apps.googleusercontent.com",
};

export const FACEBOOK_SIGN_IN = {
  FACEBOOK_ID: "189330161821065",
};

export const WEEK_DAYS = [
  { id: "Sat", name: "Saturday" },
  { id: "Sun", name: "Sunday" },
  { id: "Mon", name: "Monday" },
  { id: "Tue", name: "Tuesday" },
  { id: "Wed", name: "Wednesday" },
  { id: "Thu", name: "Thursday" },
  { id: "Fri", name: "Friday" },
];

export const CONVENIENT_FEE =
  "Convenient fee + tax will apply for each ticket for mobile charging";

//Dialog Ad API
export const SIMATO_AFFID = "1";
export const SIMATO_USERID = "zm";

export const LOTTERY_LIST = [
  { key: "supiri_vasana", value: "Supiri Vasana" },
  { key: "mega_power", value: "Mega Power" },
  { key: "govisetha", value: "Govi Setha" },
  { key: "mahajana_sampatha", value: "Mahajana Sampatha" },
  { key: "neeroga", value: "Neeroga" },
  { key: "jathika_sampatha", value: "Jathika Sampatha" },
  { key: "dhana_nidhanaya", value: "Dhana Nidhanaya" },
  { key: "sevana", value: "Sevana" },
  { key: "vasana_sampatha", value: "Vasana Sampatha" },
  { key: "delakshapathi_double_dreams", value: "Delakshapathi Double Dreams" },
  { key: "sampath_rekha", value: "Sampath Rekha" },
  { key: "Mahajana Sampatha - Test", value: "Mahajana Sampatha - Test" },
  { key: "Chathura_Test", value: "Chathura_Test" },
  { key: "Dhana_Nidanaya", value: "Dhana_Nidanaya" },
  { key: "sampath_rekha", value: "Sampath Rekha" },
  { key: "ada_kotipathi", value: "Ada Kotipathi" },
  { key: "development_fortune", value: "Development Fortune" },
  { key: "jayoda", value: "Jayoda" },
  { key: "kotipathi_kapruka", value: "Kotipathi Kapruka" },
  { key: "super_ball", value: "Super Ball" },
  { key: "saturday_fortune", value: "Saturday Fortune" },
  { key: "lagna_vasana", value: "Lagna Vasana" },
];

//These constants are user for wallet
export const FINANCIAL_SERVICE_ID = {
  PAYHERE: 1,
  FRIMI: 2,
  MASTER: 3,
  VISA: 4,
};

export const INSTRUMENTS = {
  METHODS: "methods",
  SUMMERY: "summary",
  TRANSACTION: "transaction",
  CARD: "Card",
  BANK: "bank",
  PAYHERE: "payhere",
  FRIMI: "frimi",
  TOPUP: "topup",
};

export const STRING = {
  BANNER_TEXT: "Next Super Prize",
  FB_BUTTON: "Login with Facebook",
  GOOGLE_BUTTON: "Login with Google",
  LOGIN: "Login",
  SIGNUP: "Sign up",
  TYPE_NORMAL: "normal",
  TYPE_GPLUS: "gplus",
  TYPE_FB: "fb",
  PROFILE_EDIT: "Profile Edit",
  EDIT_PW: "Change Password",
  EDIT_NAME: "Change",
  OTP: "Verify code",
  OTP_TITLE: "Code verification",
  OTP_TEXT: "You will receive verification code to ",
  BID: "Bid now",
  FORGOT_PW: "Forgot password?",
  NOT_VERIFY_OTP: "You have not verified the code",
  MOBILE: "Enter your mobile number",
  SUBMIT: "Submit",
  VALUE: "Value",
  BID_TIME: "Bidded Time",
  NEW_USER: "Not registered user",
  EXCEED: "Your daily limit is exceeded",
  SENT: "Successfully sent",
  TYPE: "bulk",
  REMAINING_WARNIG: "Remaining bids for the day is ",
  UPGRADE: "Please Upgrade your package.",
  ENTER_BID: "What is your bidding value?",
  BID_FORM_DESC:
    "Enter value that you think will be the lowest and the unique and click on submit button.",
  STATUS: [
    { value: "", label: "All" },
    { value: "unique", label: "Unique Bids" },
  ],

  MOBILE_SUBS: "Mobile Subscriptions",
  SUBS: "Subscriptions",
  SUBS_METHOD: "Mobile",
  LOWEST: "lowest",
  NOT_UNIQUE: "not_unique",
  UNIQUE_NOT_LOWEST: "unique_not_lowest",
  CONFIRMBOX_TITLE: "Confirm to submit",
  CONFIRMBOX_MSG: "Are you sure to do this.",
  BTN_YES: "Yes",
  BTN_NO: "No",
  TOP_UP: "Top Up",

  SUBSCRIBE_WALLET: "subscribe",
  WALLET_ACTIVE: "Active",
  WALLET_REQUEST_TYPE_WEB: "WEB",
  WALLET_SEARCH_SALES_TYPE: "SEARCH_AND_BUY",
  PAYMENT_TYPE_MOBILE: "MOBILE",
  WALLET_ON_DEMAND_SALES_TYPE: "ON_DEMAND",
  WALLET_CALLBACK_SUCCESS: "success",

  CONNECTION_ERROR_MESSAGE:
    "Unable to proceed your request due to a service failure",
  REQUEST_FAILED_MESSAGE: "Request failed",
  INVALID_LOGIN_ATTEMPT: "Invalid Login Attempt",
};

export const MINIMUM_TOPUP_FEE = process.env.REACT_APP_MINIMUM_TOP_UP;

export const TIME_OUT = process.env.REACT_APP_TIME_OUT;

export const PAYMENT_METHOD = {
  MOBILE: "MOBILE",
  WALLET: "WALLET",
  BOTH: "BOTH",
};

export const GOOGLE_TAG_MANAGER = {
  GTM_ID: "GTM-5MKRQDL",
};

export const BANNER_MODAL_ACTIONS = {
  SUBSCRIBE: "SUB",
  ONDEMAND: "BUY",
  SEARCH_AND_BUY: "SEARCH",
};

export const FEE = {
  TEXT:
    "Convenience fees, including taxes, will be applicable for purchases made through any Mobile Operator.",
  DIALOG: "Dialog Rs. 9.94/-",
  MOBITEL: "Mobitel Rs. 9.88/-",
  HUTCH: "Hutch Rs. 9.94/-",
};
