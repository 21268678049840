/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import reduxDialog, { closeDialog, openDialog } from 'redux-reactstrap-modal';
import { connect } from "react-redux";
import { compose } from 'recompose';
import { bindActionCreators } from "redux";
import { API_ROOT_URL, MODALS, EVENTS } from '../config/';
import NotificationBarModal from './NotificationBarModal';

import { enterNIC, mobileNoUpdate, showNotificationModal, clearNotification } from "../actions/index";
import {
    translate,
    getLanguage
} from 'react-switch-lang';
import { dataPush } from '../utils/piwikDataLayerPush';
class NICModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            nic: ""
        }

        this.updateInput = this.updateInput.bind(this);
    }


    updateInput(event) {
        this.setState({ nic: event.target.value });
    }

    userNICSave() {
        const { event, data } = this.props.nextEvent;
        const { t } = this.props;
        if (this.state.nic !== "") {

            if (/^([0-9]{9}[x|X|v|V]|[0-9]{12})$/.test(this.state.nic)) {
                // data.nic_number = this.state.nic;
                this.props.enterNIC(this.state.nic);

                // piwik pro related code segment
                let dataLayerObject = {
                    event: "enterNIC"
                };
                dataPush(dataLayerObject);
            }
            else {
                var dataModal = { type: 'danger', message: t("sign_up.invalid_nic") }
                this.props.showNotificationModal(dataModal);
            }            

        } else {
            var dataModal = { type: 'danger', message: t("sign_up.enter_nic") }
            this.props.showNotificationModal(dataModal);
        }


    }

    render() {
        const { t } = this.props;

        return (
            <div className="p-2">
                <div className="modal-header">
                    <h5 className="modal-title">{t("sign_up.nic_no")}</h5>
                    <button type="button" className="close" onClick={() => {
                        this.props.clearNotification();
                        this.props.closeDialog(MODALS.NIC);
                    }}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <NotificationBarModal />

                    <input type="text" className="form-control" placeholder={t("sign_up.nic_no")}
                        onChange={this.updateInput}></input>

                    <div className="pt-3">
                        <button type="submit" className="btn btn-primary btn-block"
                            onClick={() => this.userNICSave()}>{t("sign_up.proceed")}
                        </button>
                    </div>


                </div>
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return state;
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ clearNotification, closeDialog, openDialog, enterNIC, mobileNoUpdate, showNotificationModal }, dispatch);
}

export default translate(compose(reduxDialog({
    name: 'MODAL_NIC',
    centered: true
}), connect(mapStateToProps, mapDispatchToProps))(NICModal));
