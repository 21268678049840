/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { clearNotification } from "../actions/index";

class NotificationBarModal extends Component {

    componentDidUpdate() {
        const { clearNotification, notificationsmodal } = this.props;
        if (notificationsmodal.message) {
            setTimeout(function () {
                clearNotification();
            }, 10000);
        }
    }

    renderNotification() {
        const { type, message } = this.props.notificationsmodal;
        return (
            <div className="py-1">
                <div className={`mb-0 alert alert-${type}`} >{message} </div>
            </div>
        );
    }

    render() {
        const { type, message } = this.props.notificationsmodal;
        return (
            <div>
                {type ? this.renderNotification() : ""}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ clearNotification }, dispatch);
}

function mapStateToProps({ notificationsmodal }) {
    return { notificationsmodal };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationBarModal);
