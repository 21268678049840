/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import reduxDialog, { closeDialog, openDialog } from 'redux-reactstrap-modal';
import { connect } from "react-redux";
import { compose } from 'recompose';
import { bindActionCreators } from "redux";
import { API_ROOT_URL, MODALS, EVENTS } from '../config/';
import NotificationBarModal from './NotificationBarModal';

import { showNotificationModal, mobileNoReset } from "../actions/index";

class MobileNoResetModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            mobile_no: ""
        }

        this.updateInput = this.updateInput.bind(this);
    }

    updateInput(event) {
        this.setState({ mobile_no: event.target.value });
    }

    userMobileNumber() {
        if (this.state.mobile_no) {
            this.props.mobileNoReset({ "mobile": this.state.mobile_no, nic: '' });
        } else {
            var data = { type: 'danger', message: 'Please enter the mobile number' }
            this.props.showNotificationModal(data);
        }
    }


    render() {
        return (
            <div className="p-2">
                <div className="modal-header">
                    <h5 className="modal-title">Enter Mobile Number</h5>
                    <button type="button" className="close" onClick={() => this.props.closeDialog(MODALS.MOBILENORESET)}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <NotificationBarModal />
                    <input type="text" className="form-control" placeholder="Enter Mobile Number" onChange={this.updateInput}></input>
                    <div className="pt-3"><button type="submit" className="btn btn-primary btn-block" onClick={() => this.userMobileNumber()}>SEND</button></div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ mobileNoReset, closeDialog, openDialog, showNotificationModal }, dispatch);
}

export default compose(reduxDialog({ name: 'MODAL_MOBILENO_RESET', centered: true }), connect(null, mapDispatchToProps))(MobileNoResetModal);
