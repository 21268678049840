/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import reduxDialog, { closeDialog } from 'redux-reactstrap-modal';
import { connect } from "react-redux";
import { compose } from 'recompose';
import { bindActionCreators } from "redux";

import { FacebookLogin } from 'react-facebook-login-component';
import { GoogleLogin } from 'react-google-login-component';
import SignUpForm from './SignUpForm';
import NotificationBarModal from './NotificationBarModal';
import { clearNotification } from "../actions/index";
import {
    translate,
    getLanguage
  } from 'react-switch-lang';

class SignUpModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            mobile_no: "",
            password: ""
        }
    }

    render() {
        const { data, t } = this.props;
        return (
            <div className="p-2">
                <div className="modal-header">
                    <h5 className="modal-title">{t("sign_up.sign_up")}</h5>
                    <button type="button" className="close" onClick={() =>{
                        this.props.closeDialog('MODAL_SIGNUP');
                        this.props.clearNotification();

                    } }>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <NotificationBarModal />
                    <SignUpForm data={data} />
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ closeDialog,clearNotification }, dispatch);
}

export default translate(compose(reduxDialog({ name: 'MODAL_SIGNUP', centered: true }), connect(null, mapDispatchToProps))(SignUpModal));
