/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import { LOAD_RESULTS , RESULT_LOADING} from "../actions/index";


const data = { "data": [], "page": { "total": 0, "limit": 0, "offset": 0 } };
export default function (state = data, action) {
    switch (action.type) {
        case LOAD_RESULTS:
            return action.payload;
        default:
            return state;
    }
}
