/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from "react";
import reduxDialog, { closeDialog } from "redux-reactstrap-modal";
import { connect } from "react-redux";
import { compose } from "recompose";
import { bindActionCreators } from "redux";
import ReactDOM from "react-dom";
import { LOTTERY_LIST } from "../config/";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { subscribe, getPackage, getPackagePrice } from "../actions/index";
import { MODALS, WEEK_DAYS, CONVENIENT_FEE } from "../config";
import { translate, getLanguage } from "react-switch-lang";
import Wizad from '../components/Wizad'
// import '../styles/css/subscription.css';
import LoadingBar, { showLoading, hideLoading } from "react-redux-loading-bar";


class SubscribeModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            count: 1,
            loadEditText: false,
            currentStep: 1,
            email: '',
            username: '',
            password: '',
            mobile: false,
            wallet: false,
            both: true,
            dayCount: 0,
            periodData: ''
        };
        this.updateInput = this.updateInput.bind(this);
    }

    componentDidMount = () => {
        this.props.getPackage()
    }

    _next = () => {
        let currentStep = this.state.currentStep
        currentStep = currentStep >= 2 ? 3 : currentStep + 1
        this.setState({
            currentStep: currentStep
        })
    }

    _prev = () => {
        let currentStep = this.state.currentStep
        currentStep = currentStep <= 1 ? 1 : currentStep - 1
        this.setState({
            currentStep: currentStep
        })
    }

    /*
    * the functions for our button
    */
    previousButton() {
        let currentStep = this.state.currentStep;
        const { data: { dark_color } } = this.props;

        var buttonStyle = {
            border: `1px solid ${dark_color}`,
            backgroundColor: "#ffffff",
            color: dark_color
        };


        if (currentStep !== 1) {
            return (
                <button
                    className="btn btn-block mr-1"
                    style={buttonStyle}
                    type="button" onClick={this._prev}>
                    Previous
                </button>
            )
        }
        return null;

    }

    nextButton() {
        let currentStep = this.state.currentStep;
        const { data: { dark_color } } = this.props;

        var buttonStyle = {
            border: `1px solid ${dark_color}`,
            backgroundColor: dark_color,
            color: "#ffffff"
        };
        if (currentStep === 1) {
            return (
                <button
                    className="btn btn-block mr-1"
                    style={buttonStyle}
                    type="button" onClick={this._next}>
                    Next
                </button>
            )
        } else if (currentStep === 2) {
            return (
                <button
                    className="btn btn-block mr-1"
                    style={buttonStyle}
                    disabled={!this.state.wallet && !this.state.both}
                    type="button" onClick={this._next}>
                    Next
                </button>
            )
        }
        return null;
    }

    submitButton() {
        const { currentStep, periodData, count, mobile, wallet, both } = this.state;
        const { data: { dark_color } } = this.props;

        let paymentMethod = mobile ? 'MOBILE' : wallet ? 'WALLET' : 'BOTH'
        let buttonStyle = {
            border: `1px solid ${dark_color}`,
            backgroundColor: dark_color,
            color: "#ffffff"
        };

        if (currentStep === 3) {
            return (
                <button
                    className="btn btn-block mr-1"
                    style={buttonStyle}
                    disabled={!this.state.dayCount}
                    type="button">
                    Submit
                </button>
            )

            // return (
            //     <button
            //         className="btn btn-block mr-1"
            //         style={buttonStyle}
            //         disabled={!this.state.dayCount}
            //         type="button" onClick={() => subscribeWithWallet(
            //             {
            //                 lotteryId: periodData.lotteryId,
            //                 payload: {
            //                     walletPackageId: periodData.walletPackageId,
            //                     ticketsCount: count,
            //                     paymentMethod: paymentMethod,
            //                     source: "WEB"
            //                 }
            //             })}>
            //         Submit
            //     </button>
            // )
        }
        return null;
    }

    subscribeButton() {
        const { currentStep, periodData } = this.state;
        const { data: { dark_color, package_id }, subscribe } = this.props;

        var buttonStyle = {
            border: `1px solid ${dark_color}`,
            backgroundColor: dark_color,
            color: "#ffffff"
        };

        if (currentStep === 2) {
            return (
                <button
                    className="btn btn-block mr-1"
                    style={buttonStyle}
                    type="button"
                    onClick={() =>
                        subscribe(
                            {
                                salesType: "SUBSCRIPTION",
                                source: "web",
                                payload: {
                                    packageId: package_id,
                                    customerId: localStorage.getItem('id'),
                                    count: this.state.count,
                                }

                            })
                    }>
                    Subscribe
                </button>
            )
        }
        return null;
    }

    renderCount(count) {
        const {
            t,
            data: { lottery_price, light_color, dark_color }
        } = this.props;
        var style = {
            border: `1px solid ${dark_color}`,
            color: dark_color,
            backgroundColor: "#ffffff",
            selected: ""
        };

        if (this.state.count === count) {
            style.color = "#ffffff";
            style.backgroundColor = dark_color;
            style.selected = " selected";
        }

        return (
            <div key={count} className="col-3 p-1">
                {count === 10 ? (
                    <button
                        className="btn btn-block mr-1"
                        style={style}
                        onClick={() => this.setState({ loadEditText: true })}
                    >
                        <h1>+</h1>
                        <small>{t("const_file.other")}</small>
                    </button>
                ) : (
                        <button
                            className={"btn btn-block mr-1" + style.selected}
                            style={style}
                            onClick={() => this.setState({ count })}
                        >
                            <h1>{count}</h1>
                            <small>
                                {t("const_file.rs")}
                                {count * lottery_price}
                            </small>
                        </button>
                    )}
            </div>
        );
    }

    // Render the count in the first step
    renderCount(count) {
        const {
            t,
            data: { lottery_price, light_color, dark_color }
        } = this.props;
        var style = {
            border: `1px solid ${dark_color}`,
            color: dark_color,
            backgroundColor: "#ffffff",
            selected: ""
        };

        if (this.state.count === count) {
            style.color = "#ffffff";
            style.backgroundColor = dark_color;
            style.selected = " selected";
        }

        return (
            <div key={count} className="col-3 p-1">
                {count === 10 ? (
                    <button
                        className="btn btn-block mr-1"
                        style={style}
                        onClick={() => this.setState({ loadEditText: true })}
                    >
                        <h1>+</h1>
                        <small>{t("const_file.other")}</small>
                    </button>
                ) : (
                        <button
                            className={"btn btn-block mr-1" + style.selected}
                            style={style}
                            onClick={() => this.setState({ count })}
                        >
                            <h1>{count}</h1>
                            <small>
                                {t("const_file.rs")}
                                {count * lottery_price}
                            </small>
                        </button>
                    )}
            </div>
        );
    }


    // Render the pay method in the second step
    renderPayMethod() {
        const { data: { dark_color } } = this.props;
        const { mobile, wallet, both } = this.state;


        var notSelected = {
            border: `1px solid ${dark_color}`,
            color: dark_color,
            backgroundColor: "#ffffff",
            selected: ""
        };

        var selected = {
            border: `1px solid ${dark_color}`,
            color: "#ffffff",
            backgroundColor: dark_color,
            selected: " selected"
        };


        return (
            <React.Fragment>
                <div key={1} className="col-4 p-1">
                    <button
                        className={"btn btn-block mr-1  p-2 py-3" + (mobile ? ' selected' : '')}
                        style={mobile ? selected : notSelected}
                        onClick={() => this.setState({ mobile: !mobile, wallet: false, both: false })}
                    >
                        <h5>{'Mobile'}</h5>
                    </button>
                </div>
                <div key={2} className="col-4 p-1">
                    <button
                        className={"btn btn-block mr-1  p-2 py-3" + (wallet ? ' selected' : '')}
                        style={wallet ? selected : notSelected}
                        onClick={() => this.setState({ wallet: !wallet, mobile: false, both: false })}
                    >
                        {/* <h5>{'Wallet'}</h5> */}
                        <h5>{'Credit/Debit Cards'}</h5>
                    </button>
                </div>
                <div key={3} className="col-4 p-1">
                    <button
                        className={"btn btn-block mr-1  p-2 py-3" + (both ? ' selected' : '')}
                        style={both ? selected : notSelected}
                        onClick={() => this.setState({ both: !both, mobile: false, wallet: false })}
                    >
                        <h5>{'Both'}</h5>
                    </button>
                </div>
            </React.Fragment>

        );
    }

    // Render the dayCount in the third step
    renderSummary(walletPackage) {
        const {
            t,
            data: { dark_color, package_id }
        } = this.props;

        var style = {
            border: `1px solid ${dark_color}`,
            color: dark_color,
            backgroundColor: "#ffffff",
            selected: ""
        };

        if (this.state.dayCount === walletPackage.periodNumber) {
            style.color = "#ffffff";
            style.backgroundColor = dark_color;
            style.selected = " selected";
        }

        let periodData = {
            lotteryId: package_id,
            walletPackageId: walletPackage.packageId,
            ticketsCount: this.state.count,
            periodNumber: walletPackage.periodNumber
        }

        return (
            <div key={walletPackage.periodNumber} className="col-4 p-1">
                <button
                    className={"btn btn-block mr-1" + style.selected}
                    style={style}
                    onClick={() => this.handlePeriodClick(periodData)}
                >
                    <h5>{walletPackage.packageName}</h5>
                    {/* <small>
                        {t("const_file.rs")}
                        {dayCount * lottery_price}
                    </small> */}
                </button>
            </div>
        );
    }

    updateInput(event) {
        event.preventDefault();
        // this.setState({mobile_pin: event.target.value });

        var regex = /[0-9]|\./;

        if (!regex.test(event.target.value)) {
            this.setState({ count: 1 });
            ReactDOM.findDOMNode(this.refs.ticket_count_sub).value = 1;
        }

        if (event.target.value <= 0) {
            if (event.target.value !== "") {
                this.setState({ count: 1 });
                ReactDOM.findDOMNode(this.refs.ticket_count_sub).value = 1;
            }
        } else {
            this.setState({ count: event.target.value });
        }
    }

    renderEditText() {
        const { t } = this.props;
        return (
            <div className="col-12 p-1">
                {
                    <input
                        type="number"
                        ref="ticket_count_sub"
                        min="1"
                        pattern="[0-9.]+"
                        className="form-control"
                        placeholder={t("subscribemodal.enter_no_tickets_buy")}
                        onChange={this.updateInput}
                    />
                }
            </div>
        );
    }

    renderPackageTable() {
        const { walletPackages, packagePrice } = this.props;

        return (

            <table className="table table-bordered">

                <thead>
                    <tr>
                        <th scope="col">Mon</th>
                        <th scope="col">Tue</th>
                        <th scope="col">Wed</th>
                        <th scope="col">Thu</th>
                        <th scope="col">Fri</th>
                        <th scope="col">Sat</th>
                        <th scope="col">Sun</th>
                    </tr>
                </thead>
                {(this.state.dayCount && packagePrice) ?
                    <tbody>
                        {packagePrice.lotteries.map(element => {
                            return (
                                element.draws.map((day, index) => {

                                    return (
                                        <tr key={index}>
                                            {Object.keys(day).map((i) => {
                                                return (<td key={i}>{day[i]}</td>)
                                            })}
                                        </tr>
                                    )
                                })
                            )
                        })}

                    </tbody> :
                    <tbody>
                        <tr>
                            <td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td>
                        </tr>
                    </tbody>}
            </table>
        )

    }

    //placeholder={t("subscribemodal.enter_no_tickets_buy")}

    loadLotteryName(lottery_name) {
        const { t } = this.props;
        var pack = _.find(LOTTERY_LIST, { value: lottery_name });

        return lottery_name;
        // return "lottery_tickets." + pack.key;
    }

    handleChange = event => {
        const { name, value } = event.target
        this.setState({
            [name]: value
        })
    }

    handlePeriodClick = (periodData) => {
        this.props.getPackagePrice(periodData);
        this.setState({ dayCount: periodData.periodNumber, periodData });
    }



    step1() {
        const { currentStep } = this.state;

        if (currentStep !== 1) {
            return ''
        } else {
            const { data: { dark_color, draw_lotteries }, t } = this.props;

            var buttonStyle = {
                border: `1px solid ${dark_color}`,
                backgroundColor: dark_color,
                color: "#ffffff"
            };
            var date_on_lottery = t("subscribemodal.draw_available");
            var draw_dates = draw_lotteries[0].draw_dates;

            for (var i = 0; i < draw_dates.length; i++) {
                if (draw_dates.length == 1) {
                    var search_day = WEEK_DAYS.find(item => item.id == draw_dates[i]);
                    var day_full_name = search_day.id;
                    date_on_lottery =
                        date_on_lottery + " " + t("day_names." + day_full_name + "");
                } else {
                    if (i == Number(draw_dates.length) - Number(1)) {
                        //  if (draw_dates.length!=1) {

                        var search_day = WEEK_DAYS.find(item => item.id == draw_dates[i]);
                        var day_full_name = search_day.id;
                        date_on_lottery =
                            date_on_lottery +
                            " " +
                            t("const_file.and") +
                            " " +
                            t("day_names." + day_full_name + "") +
                            " ";
                    } else {
                        var search_day = WEEK_DAYS.find(item => item.id == draw_dates[i]);
                        var day_full_name = search_day.id;
                        date_on_lottery =
                            date_on_lottery + " " + t("day_names." + day_full_name + "") + ",";
                    }
                }
            }
            return (
                <React.Fragment>
                    <Wizad page={1} color={dark_color} />
                    <br />
                    <h5>{t("subscribemodal.no_tickets_sub")}</h5>
                    <div className="my-3 px-3 row">
                        {this.state.loadEditText
                            ? this.renderEditText()
                            : [1, 2, 5, 10].map(count => {
                                return this.renderCount(count);
                            })}
                    </div>
                    <h5 className="my-3 bolded px-2 text-center">{date_on_lottery}</h5>
                    <p className="my-3  text-center">{t("const_file.convenient_fee")}</p>
                    {this.nextButton()}
                </React.Fragment>
            );
        }
    }

    step2() {
        const { currentStep, wallet, mobile } = this.state;
        const { data } = this.props;

        if (currentStep !== 2) {
            return null
        }
        return (
            <React.Fragment>
                <Wizad page={2} color={data.dark_color} />
                <br />

                <h5>{'Select a Payment Method'}</h5>
                <br />
                <div className="my-3 px-3 row pt-2">
                    {this.renderPayMethod()}
                </div>

                <div className="row">
                    <div className="col-6 pr-1">
                        {this.previousButton()}
                    </div>
                    <div className="col-6 pr-1">
                        {(mobile && !wallet) ? this.subscribeButton() : this.nextButton()}
                    </div>
                </div>

            </React.Fragment>
        );
    }

    step3() {
        const { currentStep, dayCount } = this.state;
        const { walletPackages, packagePrice, data } = this.props;

        if (currentStep !== 3) {
            return null
        }

        return (
            <React.Fragment>
                <Wizad page={3} color={data.dark_color} />
                <br />
                <h5>{'Select Payment Method'}</h5>
                <div className="my-3 px-3 row">

                    {walletPackages.map(walletPackage => {
                        return this.renderSummary(walletPackage);
                    })}

                </div>
                <div className="my-3 px-3 row">
                    Tickets amounts per each day
                </div>
                <div className="my-3 px-3 row">
                    {this.renderPackageTable()}
                </div>
                <div className="my-3 px-3 row">
                    <div className='col-6'>Total Tickets</div>
                    <div className='col-6 text-right'>{(packagePrice.ticketsCount && dayCount) ? packagePrice.ticketsCount : '0'}</div>
                </div>
                <div className="my-3 px-3 row">
                    <div className='col-6'>Total Tickets Price</div>
                    <div className='col-6 text-right'>{(packagePrice.price && dayCount) ? packagePrice.price : '0'}.00 LKR</div>
                </div>
                <br />
                <br />

                <div className="row">
                    <div className="col-6 pr-1">
                        {this.previousButton()}
                    </div>
                    <div className="col-6 pr-1">
                        {this.submitButton()}
                    </div>
                </div>
            </React.Fragment>
        );
    }

    render() {
        const { data, subscribe, t } = this.props;

        return (
            <div className="p-3">
                <div className="modal-header">
                    <h5 className="modal-title">
                        {t("subscribemodal.subscribe")}{" "}
                        {t(this.loadLotteryName(data.lottery_name))}
                    </h5>
                    <button type="button" className="close" onClick={() => this.props.closeDialog(MODALS.SUBSCRIBE)}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <LoadingBar scope="loading_search" maxProgress={100} style={{ backgroundColor: data.dark_color }} />

                <div className="modal-body subcription-modal" >
                    <React.Fragment>
                        <form>
                            {this.step1()}
                            {this.step2()}
                            {this.step3()}
                        </form>
                    </React.Fragment>
                </div>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ subscribe, closeDialog, getPackage, getPackagePrice }, dispatch);
}

function mapStateToProps({ walletPackages, packagePrice }) {
    return { walletPackages, packagePrice };
}


export default compose(
    translate,
    reduxDialog({ name: MODALS.SUBSCRIBE, centered: true, backdrop: 'static' }),
    withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(SubscribeModal);