/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 *
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { openDialog } from "redux-reactstrap-modal";
import {
  Search as SearchIcon,
  ShoppingCart as BuyIcon,
  FolderPlus as SubscribeIcon,
  XSquare as CloseIcon,
} from "react-feather";
import axios from "axios";
import _ from "lodash";

import { LOTTERY_LIST, MODALS, EVENTS, BANNER_MODAL_ACTIONS } from "../config/";

import ticket_image from "../images/ticket-img2.png";

import { setNextEvent, checkTokenExpiration } from "../actions/index";
import { translate, getLanguage } from "react-switch-lang";
import { dataPush } from "../utils/piwikDataLayerPush";

import { withRouter } from "react-router-dom";

var Color = require("color");

class HomeLottery extends Component {

  componentWillMount() {
    // const { lottery: { draw_id }, packages } = this.props;
    // var self = this;
    // const url = `${API_ROOT_URL}/public/lottery-tickets/search?limit=1&offset=0`;
    // axios.post(url, {
    //     draw_id: draw_id,
    //     fields: []
    // }).then(function (response) {
    //     if (response.data.page.total === 0) {
    //         self.setState({ sold_out: true });
    //     }
    // }).catch(function (error) {
    //
    // });
    // if(this.props.subcribeId){
    //   var pack = _.find(packages, { 'package_name': this.props.subcribeId })
    //   if (pack) {
    //     this.props.openDialog('MODAL_SUBSCRIBE', _.merge(pack, this.props.lottery))
    //   }else{
    //
    //   }
    // }
  }

    componentDidUpdate(prevProps) {
      const { bannerAction, lottery } = this.props;

      if (
        prevProps.bannerAction !== bannerAction &&
        bannerAction.lotteryId === lottery.lottery_id
      ) {
        switch (bannerAction.action ) {
          case BANNER_MODAL_ACTIONS.SUBSCRIBE:
            this.subcribeLottery();
            break;

          case BANNER_MODAL_ACTIONS.ONDEMAND:
            if (lottery.is_ongoing && !lottery.is_soldOut) {
              this.buyLottery();
            }            
            break;

          case BANNER_MODAL_ACTIONS.SEARCH_AND_BUY:
            if (lottery.is_ongoing && !lottery.is_soldOut) {
              this.openSearch();
            }
            break;

          default:
            break;
        }
      }
    }

    async openSearch() {
        // this.props.openDialog("MODAL_SEARCH", this.props.lottery);
        const { token, lottery } = this.props;
        let isExpired = false;
        if (token) {
            isExpired = await checkTokenExpiration(EVENTS.SEARCH_AND_BUY, lottery);
        }

        if (!isExpired) {
            if (!token) {
                this.openRelevantModal(EVENTS.SEARCH_AND_BUY, lottery, MODALS.LOGIN);
            } else if (
                localStorage.getItem("nic") === null ||
                localStorage.getItem("nic") === "null"
            ) {
                this.openRelevantModal(EVENTS.SEARCH_AND_BUY, lottery, MODALS.NIC);
            } else {
                this.props.openDialog(MODALS.SEARCH, lottery);
            }
        }
    }

    async buyLottery() {
        const { token, lottery } = this.props;
        let isExpired = false;
        if (token) {
            isExpired = await checkTokenExpiration(EVENTS.BUY, lottery);
        }
        if (!isExpired) {
            if (!token) {
                this.openRelevantModal(EVENTS.BUY, lottery, MODALS.LOGIN);
                // this.props.randomBuys(ticket);

                // piwik pro related code segment
                let dataLayerObject = {
                    event: "logingWhenBuy",
                    lottery_name: lottery.lottery_name,
                };
                dataPush(dataLayerObject);

            } else if (localStorage.getItem('nic') === null || localStorage.getItem('nic') === 'null') {
                this.openRelevantModal(EVENTS.BUY, lottery, MODALS.NIC);

                // piwik pro related code segment
                let dataLayerObject = {
                    event: "enterNicWhenBuy"
                };
                dataPush(dataLayerObject);
            } else {
                // ticket.mobile_no = "";
                // this.props.randomBuys(ticket);
                this.props.openDialog(MODALS.BUY, lottery);
            }
        }

    }

    async subcribeLottery() {
        const { token, lottery, packages } = this.props;

        let isExpired = false;
        if (token) {
            isExpired = await checkTokenExpiration(EVENTS.SUBSCRIBE, lottery);
        }
        if (!isExpired) {
            if (!token) {
                this.openRelevantModal(EVENTS.SUBSCRIBE, lottery, MODALS.LOGIN);

                // piwik pro related code segment
                let dataLayerObject = {
                    event: "logingWhenSubscribe",
                    lottery_name: lottery.lottery_name
                };
                dataPush(dataLayerObject);
                //this.props.subscribes(ticket);
            } else if (
                localStorage.getItem("nic") === null ||
                localStorage.getItem("nic") === "null"
            ) {
                this.openRelevantModal(EVENTS.SUBSCRIBE, lottery, MODALS.NIC);

                // piwik pro related code segment
                let dataLayerObject = {
                    event: "enterNicWhenSubscribe"
                };
                dataPush(dataLayerObject);

            } else {
                var pack = _.find(packages, { package_name: lottery.lottery_name });
                // ticket.mobile_no = "";
                this.props.openDialog(MODALS.SUB, _.merge(pack, lottery));
                //this.props.subscribes(ticket);
                // window.scrollTo(0, 0);
            }
        }
    }

  openRelevantModal(event, props, modal) {
    this.props.setNextEvent({ event, props });
    this.props.openDialog(modal);
  }

  renderBuyButtons() {
    const { t } = this.props;

    const {
      lottery_id,
      is_ongoing,
      is_soldOut,
      dark_color,
      draw_id,
      is_search_enable,
      is_ticket_soldout,
      lottery_name,
    } = this.props.lottery;

    var buttonStyle = {
      background: dark_color,
      borderColor: dark_color,
    };
    return (
      <div className="col-8">
        <div className="row">
          <div className="col-6 p-1">
            <button
              type="button"
              // disabled={!is_ongoing ? "disabled" : ""}
              onClick={() => {
                // tracking with piwik code segment
                const dataLayerObject = {
                  event: "On-Demand",
                  lottery_name: lottery_name,
                };
                dataPush(dataLayerObject);

                this.buyLottery();
                //this.props.openDialog("MODAL_BUY", this.props.lottery)
              }}
              style={buttonStyle}
              className={
                "btn btn-search btn-block shadow-button buy-" + lottery_id
              }
            >
              <BuyIcon size={16} />
              <br />
              {t("home.buy")}
            </button>
          </div>
          <div className="col-6 p-1">
            <button
              type="button"
              disabled={
                is_search_enable
                  ? !is_ongoing || is_soldOut
                    ? "disabled"
                    : ""
                  : "disabled"
              }
              onClick={() => this.openSearch()}
              style={buttonStyle}
              className={
                "btn btn-search btn-block shadow-button srch-" + lottery_id
              }
            >
              <SearchIcon size={16} />
              <br />
              {t("home.search")}
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderSubscribeButtons() {
    const { t } = this.props;
    const {
      lottery: { lottery_name, dark_color },
      packages,
      subcribeId,
      myPackages,
      sharedStatus,
    } = this.props;

    const { lottery_id } = this.props.lottery;

    var buttonStyle = {
      background: dark_color,
      borderColor: dark_color,
    };

    //The package names 'Package' in the end removed in the API side.
    //Therefor removed here. Earlier -->  { package_name: lottery_name + " Package" }

    var pack = _.find(packages, { package_name: lottery_name });
    var package_id = "";
    var subcribe_success = false;

    if (subcribeId) {
      var packFilter = _.find(packages, {
        package_name: this.props.subcribeId,
      });
      var lotteryFilter;
      if (this.props.lottery.lottery_name === this.props.subcribeId) {
        lotteryFilter = this.props.lottery;
      }

      if (packFilter) {
        // this.subcribeTicket({lotteryFilter.package_id, subscribe_count: LOTTERY_SUBCRIBE_COUNT, lottery_name:lottery_name })
      }
    }
    if (pack) {
      package_id = pack.package_id;

      var packMy = _.find(myPackages, { package_id: package_id });
      // var packMy2 = _.find(subcribe_list, { 'package_id': package_id })
      if (sharedStatus) {
        if (sharedStatus.id === pack.package_id) {
          subcribe_success = true;
        }
      }

      if (packMy) {
        return (
          <button
            type="button"
            style={buttonStyle}
           // disabled="disabled"
            onClick={() => {
                // tracking with piwik code segment
                let dataLayerObject = {
                    event: "On-Subscription",
                    lottery_name: lottery_name,
                };
                dataPush(dataLayerObject);

                this.subcribeLottery();
                // this.props.openDialog(
                //     "MODAL_SUB",
                //     _.merge(pack, this.props.lottery)
                // )
            }}
            className={
              "btn btn-search btn-block px-md-0 px-lg-1 btn-" + lottery_id
            }
          >
            <SubscribeIcon size={16} />
            <br />
            {t("home.update_subscribcription")}
          </button>
        );
      } else {
        return (
          <button
            type="button"
            onClick={() => {
              // tracking with piwik code segment
              let dataLayerObject = {
                event: "On-Subscription",
                lottery_name: lottery_name,
              };
              dataPush(dataLayerObject);

              this.subcribeLottery();
              // this.props.openDialog(
              //     "MODAL_SUB",
              //     _.merge(pack, this.props.lottery)
              // )
            }}
            style={buttonStyle}
            className={
              "btn btn-search btn-block shadow-button px-md-0 px-lg-1 btn-" +
              package_id
            }
          >
            <SubscribeIcon size={16} />
            <br />
            {t("home.subscribe")}
          </button>
        );
      }
    } else {
      return (
        <button
          type="button"
          style={buttonStyle}
          disabled="disabled"
          className="btn btn-search btn-block px-md-0 px-lg-1"
        >
          <SubscribeIcon size={16} />
          <br />
          {t("home.subscribe")}
        </button>
      );
    }
  }

  loadLotteryName(lottery_name) {
    const { t } = this.props;

    var pack = _.find(LOTTERY_LIST, { value: lottery_name });

    // return lottery_name;
    return pack === undefined ? lottery_name : t("lottery_tickets." + pack.key);
  }

  render() {
    const { t } = this.props;

    const {
      lottery_name,
      lottery_logo_url,
      lottery_mobile_logo_url,
      jackpot_price,
      draw_number,
      draw_date_time,
      is_ongoing,
      is_soldOut,
      isBundleDraw,
      light_color,
      dark_color,
      is_ticket_soldout,
      drawType
    } = this.props.lottery;

    var color = Color(dark_color)
      .rotate(25)
      .lighten(0.3);
    // var color = Color(dark_color).saturate(0.9).lighten(0.4);
    var drawTimeLable = draw_date_time
      ? t("home.date") + " :" + draw_date_time.substr(0, 10)
      : "";

    var backgroundStyle = {
      background: `linear-gradient(45deg, ${color}, ${dark_color})`,
      border: `1px solid ${color}`,
    };

    let lottery_image = lottery_logo_url;
    let lottery_image_mobile = lottery_mobile_logo_url;

    // lotteryBasics.mobileImage = 'data:image/png;base64,' + lotteryBasics.mobileImage.substring(29);

    var buttonStyle = {
      background: dark_color,
      borderColor: dark_color,
    };

    return (
      <div className="mb-2 col-12 col-md-4 col-lg-3">
        <div
          className="bg-1 rounded-top home-ticket"
          style={{ backgroundImage: `url('${lottery_image}')` }}
        >
          <div
            className="rounded remove-rounded-top content"
            style={backgroundStyle}
          >
            <div className="background ticket-bottom py-2 px-1">
              <div className="d-flex flex-row detail">
                <div
                  style={{ backgroundImage: `url('${lottery_image_mobile}')` }}
                  className="col-3 lottery_logo"
                />
                <div className="col-9 col-md-12 px-2 px-md-0 px-lg-1 ">
                  {is_ongoing && jackpot_price ? (
                    <div>
                      <small className="my-0 h6">
                        {t("home.nextsuperprize")}
                      </small>

                      <h3 className="my-0">
                        {t("const_file.rs")}{" "}
                        {numberWithCommas(jackpot_price.toFixed(2))}
                      </h3>
                    </div>
                  ) : (
                    <div>
                      <br />
                    </div>
                  )}

                  <h6 className="my-0 bolded">
                    {this.loadLotteryName(lottery_name)}
                  </h6>
                  <small className="my-0">
                    {t("home.draw")} : {draw_number} - {drawTimeLable}
                  </small>
                </div>
              </div>
              <div className="d-flex flex-row pt-2 button-set">
                
                {
                    !(isBundleDraw || drawType == 1) ? (
                      <div className="col-4 p-1 text-center">
                        {this.renderSubscribeButtons()}
                        </div>
                    ):
                    (
                      <div class="col-2">
                  </div>
                    )}
                
                {is_ongoing && !is_soldOut ? (
                  this.renderBuyButtons()
                ) : (
                  <div className="col-8 p-1 text-center">
                    <button
                      type="button"
                      style={buttonStyle}
                      disabled="disabled"
                      className="btn btn-search btn-block "
                    >
                      <CloseIcon size={16} />
                      <br />
                      {t("home.soldout")}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch, props) {
    return bindActionCreators({ openDialog, setNextEvent,checkTokenExpiration }, dispatch);
}

function mapStateToProps({
  token,
  packages,
  myPackages,
  sharedStatus,
  dialog_app_kp,
  bannerAction
}) {
  return { token, packages, myPackages, sharedStatus, dialog_app_kp, bannerAction };
}

export default translate(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(HomeLottery)
  )
);

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
