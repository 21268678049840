/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { connect } from "react-redux";

import { bindActionCreators } from "redux";
import { Check as CheckIcon } from 'react-feather';
import { loadResults, loadAvaliableLotteries } from "../actions/index";
import { Table } from 'reactstrap';
import TermEn from "../config/language_contend/termen.js";
import TermSin from "../config/language_contend/termsin.js";
import TermTml from "../config/language_contend/termtml.js";

import {
    translate,
    getLanguage
} from 'react-switch-lang';

class Terms extends Component {

    

    render() {

        const { t } = this.props;
        var lan =getLanguage();

        return (
            <div className="innerContent termsPage">
                {lan==='en'?<TermEn/>
                :<span/>}
                {lan==='sin'?<TermSin/>
                :<span/>}
                {lan==='tml'?<TermTml/>
                :<span/>}      
            </div>
        );
    }
}

export default translate(connect()(Terms));
