/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from "react";

class TermEn extends Component {
  render() {
    return (
      <div>
        <h1>
          <span>Terms </span>
        </h1>
        <p>
          <span>Eligibility</span>
        </p>
        <p>
          <span>
            The lotteries are open to anyone aged 18 years or over and a Sri
            Lankan citizenship only. For those who purchase tickets via SMS need
            to buy through a valid mobile connection. However, the following are
            not eligible to this service: Employees of the Promoter and their
            families, the Promoter's service providers, agents and anyone
            professionally associated with this prize draw.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>Available lotteries and draw dates</span>
        </p>
        <div className="table-responsive">
          <table>
            <tbody>
              <tr>
                <td>
                  <p>
                    <strong>Product</strong>
                  </p>
                </td>
                <td>
                  <p>
                    <strong>Monday</strong>
                  </p>
                </td>
                <td>
                  <p>
                    <strong>Tuesday</strong>
                  </p>
                </td>
                <td>
                  <p>
                    <strong>Wednesday</strong>
                  </p>
                </td>
                <td>
                  <p>
                    <strong>Thursday</strong>
                  </p>
                </td>
                <td>
                  <p>
                    <strong>Friday</strong>
                  </p>
                </td>
                <td>
                  <p>
                    <strong>Saturday</strong>
                  </p>
                </td>
                <td>
                  <p>
                    <strong>Sunday</strong>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>Govi Setha</strong>
                  </p>
                </td>
                <td>
                  <p>
                    <strong>&radic;</strong>
                  </p>
                </td>
                <td>
                  <p>
                    <strong>&radic;</strong>
                  </p>
                </td>
                <td>
                  <p>
                    <strong>&radic;</strong>
                  </p>
                </td>
                <td>
                  <p>
                    <strong>&radic;</strong>
                  </p>
                </td>
                <td>
                  <p>
                    <strong>&radic;</strong>
                  </p>
                </td>
                <td>
                  <p>
                    <strong>&radic;</strong>
                  </p>
                </td>
                <td>
                  <p>
                    <strong>&radic;</strong>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>Mahajana Sampatha</strong>
                  </p>
                </td>
                <td>
                  <p>
                    <strong>&radic;</strong>
                  </p>
                </td>
                <td>
                  <p>
                    <strong>&radic;</strong>
                  </p>
                </td>
                <td>&nbsp;</td>
                <td>
                  <p>
                    <strong>&radic;</strong>
                  </p>
                </td>
                <td>
                  <p>
                    <strong>&radic;</strong>
                  </p>
                </td>
                <td>&nbsp;</td>
                <td>
                  <p>
                    <strong>&radic;</strong>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>Mega Power</strong>
                  </p>
                </td>
                <td>
                  <p>
                    <strong>&radic;</strong>
                  </p>
                </td>
                <td>&nbsp;</td>
                <td>
                  <p>
                    <strong>&radic;</strong>
                  </p>
                </td>
                <td>&nbsp;</td>
                <td>
                  <p>
                    <strong>&radic;</strong>
                  </p>
                </td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>Neeroga</strong>
                  </p>
                </td>
                <td>&nbsp;</td>
                <td>
                  <p>
                    <strong>&radic;</strong>
                  </p>
                </td>
                <td>&nbsp;</td>
                <td>
                  <p>
                    <strong>&radic;</strong>
                  </p>
                </td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>
                  <p>
                    <strong>&radic;</strong>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>Jathika Sampatha</strong>
                  </p>
                </td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>
                  <p>
                    <strong>&radic;</strong>
                  </p>
                </td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>
                  <p>
                    <strong>&radic;</strong>
                  </p>
                </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>Supiri Wasana</strong>
                  </p>
                </td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>
                  <p>
                    <strong>&radic;</strong>
                  </p>
                </td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>
                  <p>
                    <strong>&radic;</strong>
                  </p>
                </td>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p>&nbsp;</p>
        <p>
          <span>Ticket price</span>
        </p>
        <p>
          <span>
            This will be as per the price structure published by NLB for each
            lottery + 20% convenience fee charged by the given operator
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>Dealer registration number</span>
        </p>
        <p>
          <span>
            Dealership is registered under E-005 for DigitalX Labs (Pvt Ltd) at
            59/6, Gregorys Road, Colombo 7{" "}
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>How to Purchase lotteries</span>
        </p>
        <p>
          <span>
            Any of the existing lotteries available on &ldquo;Available
            lotteries and draw dates&rdquo; can be purchased via SMS or Web site
            (www.lucky989.lk/www.lucky1.lk). Consumers can purchase tickets by
            simply sending an SMS to 989 short code with the relevant key word.
            Both subscription and on-demand (one off purchase) models are
            available for purchasing.
          </span>
        </p>
        <ul>
          <li>
            <span>
              To purchase tickets, user must have sufficient credit in their
              mobile account.
            </span>
          </li>
          <li>
            <span>They must possess a valid National Identity card number</span>
          </li>
          <li>
            <span>
              Once subscribed for a given lottery package, a weekly billing will
              be allotted. Based on the credit availability, immediate draw
              tickets will be issued to your mobile.
            </span>
          </li>
          <li>
            <span>
              Users can subscribe to multiple packages and can purchase up to 50
              tickets for on demand service.
            </span>
          </li>
        </ul>
        <p>&nbsp;</p>
        <p>
          <span>Winner draws</span>
        </p>
        <p>
          <span>
            Lottery draws happen as per the published dates by NLB given in the
            &ldquo;Available lotteries and draw dates&rdquo;
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>Wining results</span>
        </p>
        <p>
          <span>
            Official results will be notified after the draw happens via SMS to
            all subscribers, according to the lottery.
          </span>
        </p>
        <p>&nbsp;</p>
        <p><strong>Winning prizes</strong></p>
            <div className="table-responsive">
            <table>
            <tbody>
            <tr>
            <td>
            <p><span >Rs.20.00 to &nbsp;Rs.1000.00</span></p>
            </td>
            <td>
            <p><span >Lucky1s Balance.</span></p>
            </td>
            <td>
            <p><span >Cash prize between Rs.20 and Rs. 1,000/- prizes will be added to customer's Lucky1s Balance. Customer can purchase additional tickets from the prize money won.</span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><span >Rs. 1,000  &ndash; &nbsp;Rs. 20,000</span></p>
            </td>
            <td>
            <p><span >Mobile Cash or Bank Transfer</span></p>
            </td>
            <td>
            <p><span >Cash prize above Rs. 1,000 and Equal or Below 20,000/- can be;</span></p>
            <ul>
            <li ><span >Transferred to Lucky1s Balance (default)</span></li>
            <li ><span >Collected from Lucky1 office</span></li>
            <li ><span >Deposit to winner's bank account on request</span></li>
            <li ><span >Transfer to mCash/eZCash account of the customer who won on request</span></li>
            </ul>
            <p><span >Lucky1 call center agent will contact via 0117989989 to brief the winner on claiming the prize money.</span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><span >Rs. 20,000 & above</span></p>
            </td>
            <td>
            <p><span >Through NLB</span></p>
            </td>
            <td>
            <p><span >All cash prizes above Rs. 20,000/- must be collected from National Lotteries Board. A Lucky1 call center agent will contact via 0117989989 to brief the winner on claiming the prize money.</span></p>
            </td>
            </tr>
            </tbody>
            </table>
            </div>
            <p>&nbsp;</p>
            <p><strong>Opt out from the service </strong></p>
            <p><span >Type keyword space OFF and SMS to 989</span></p>



      </div>
    );
  }
}

export default TermEn;
