/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import reduxDialog, { closeDialog, openDialog } from 'redux-reactstrap-modal';
import { connect } from "react-redux";
import { compose } from 'recompose';
import { bindActionCreators } from "redux";
import { API_ROOT_URL, MODALS, EVENTS } from '../config/';
import NotificationBarModal from './NotificationBarModal';
import {
    translate,
    getLanguage
  } from 'react-switch-lang';

import PasswordForgetForm from './PasswordForgetForm';

class PasswordForgetModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            mobile_no: "",
            password: ""
        }
    }


    render() {
        const { t } = this.props;

        return (
            <div className="p-2">
                <div className="modal-header">
                    <h5 className="modal-title">{t("forgot_password.forgot_password")}</h5>
                    <button type="button" className="close" onClick={() => this.props.closeDialog(MODALS.PASSWORDFORGET)}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <NotificationBarModal/>
                    <PasswordForgetForm />
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ closeDialog, openDialog }, dispatch);
}

export default translate(compose(reduxDialog({ name: 'MODAL_PASSWORD_FORGET', centered: true }), connect(null, mapDispatchToProps))(PasswordForgetModal));
