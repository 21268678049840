/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import axios from "axios";
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { closeDialog } from "redux-reactstrap-modal";
import { sendSession } from './silverleap.js';
import { API_ROOT_URL, EVENTS, INSTRUMENTS } from '../config/';
import { axios_instance, clearNextEventLocalStorage, getCommonWalletInfo, getPackage } from './index';
import { toUpper } from "lodash";

export const REG_WALLET = "WALLET_REGISTERED";
export const SHOW_NOTIFICATION_MODAL = "SHOW_NOTIFICATION_MODAL";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";


// export const AUTH = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiZmlyc3ROYW1lIjoibHVja3kxIiwibGFzdE5hbWUiOiJhZG1pbiIsImVtYWlsIjoiYWRtaW5AbHVja3kuY29tIiwib3BlcmF0b3IiOjAsImlzTWVyY2hhbnRVc2VyIjpmYWxzZSwibWVyY2hhbnRJZCI6MCwiaWF0IjoxNTgxMDY2NjU5LCJleHAiOjE2MTI2MDI2NTl9.wTRCvnrC3p2Sa8rYyXeJyblDFGLRz9ueR8FMtwhd70E'

const mobileNo = localStorage.getItem("mobile");
localStorage.setItem("baseURL", API_ROOT_URL);
// localStorage.setItem("authorization_2", AUTH);




//This function is to call register new user for common wallet
export const registerCommonWallet = function (reqData) {

    const url = `/common-wallet/register`;

    return (dispatch) => {
        dispatch(showLoading());

        let commonwWalletData = axios_instance.post(url, reqData);
        const isFrimiSource = (sessionStorage.getItem("source") === toUpper(INSTRUMENTS.FRIMI));

        commonwWalletData.then(({ data }) => {
            if (data.data && !data.data.mobile) {
                //redirect to url
                //dispatch(listPaymentInstrument(mobileNo, data.data));
                sendSession(data.data);
                dispatch({ type: REG_WALLET, payload: data })
            } else {
                if (isFrimiSource) {
                    dispatch(hideLoading());
                    dispatch({
                        type: SHOW_NOTIFICATION,
                        payload: { type: "success", message: (data.message || "Successfully Subscribed") }
                    });
                    dispatch(getCommonWalletInfo());
                    dispatch(getPackage());
                    dispatch(closeDialog("MODAL_SUB"));
                    dispatch(closeDialog("MODAL_BUY"));
                    dispatch(closeDialog("MODAL_SEARCH"));
                } else {
                    dispatch(hideLoading());
                    dispatch({
                        type: SHOW_NOTIFICATION,
                        payload: { type: "warning", message: "Already Subscribed." }
                    });
                }
            }

        }).catch((error) => {
            error = JSON.parse(JSON.stringify(error));
            if (error.response.status === 401) {

                dispatch(clearNextEventLocalStorage());
                dispatch(closeDialog("MODAL_SUB"));
                dispatch(closeDialog("MODAL_BUY"));
                dispatch(closeDialog("MODAL_SEARCH"));

                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: { type: "danger", message: error.response.data.description }
                });
            } else {
                isFrimiSource ?
                    dispatch({
                        type: SHOW_NOTIFICATION,
                        payload: { type: "danger", message: "Frimi Consent Failed." }
                    }) :
                    dispatch({
                        type: SHOW_NOTIFICATION,
                        payload: { type: "danger", message: error.response.data.description }
                    })
            }
        });
    };
}



