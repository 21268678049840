/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { LOTTERY_LIST } from "../config/";

import _ from "lodash";
import { translate, getLanguage } from "react-switch-lang";

class MyTicket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false
    };
  }

  renderTicketFields(fields) {
    return fields.map((field, index) => {
      return field.value ? (
        <div
          className={
            field.special_field
              ? "px-2 py-1 m-1 ticket-number bg-secondary"
              : "px-2 py-1 m-1 ticket-number bg-white"
          }
          key={index}
        >
          {field.value}
        </div>
      ) : (
          ""
        );
    });
  }

  loadLotteryName(lottery_name) {
    const { t } = this.props;
    var pack = _.find(LOTTERY_LIST, { value: lottery_name });
    // return lottery_name;
    return pack === undefined ? lottery_name : t("lottery_tickets." + pack.key);
  }

  render() {
    const {
      lottery_name,
      draw_number,
      draw_date,
      ticket_serial_no,
      lottery_number_fields,
      feilds,
      second_lottery_ticket_number,
      feildsSecond,
      winning_price,
      puchased_date,
      isBundleDraw,
      bundleId

    } = this.props.result;
    const { t } = this.props;

    return (
      <div
        key={ticket_serial_no}
        className="col-12 col-md-4 col-lg-3 my-1 px-2 py-lg-1"
      >
        <div className="p-2 bg-light rounded h-100">
          <div className="d-flex flex-row p-1 justify-content-between align-items-top">
            <span className="badge badge-success mytickets">
              {winning_price ? 'Won Rs. ' + winning_price : winning_price === 0 ? 'Won High Price' : ''}
            </span>
            <div>
              <h5 className="my-0 bolded">
                {t(this.loadLotteryName(lottery_name))}
              </h5>
              {
                isBundleDraw ? (
                  <small className="my-0">
                {t("myaccount.draw_no")}: {draw_number} -{" "}
                {draw_date.substr(0, 10)}
                <br /> {t("myaccount.purchased_date")}: {puchased_date}
                <br />{t("myaccount.gold_no")} : {bundleId}
              
              </small>

                ):(
                  <small className="my-0">
                {t("myaccount.draw_no")}: {draw_number} -{" "}
                {draw_date.substr(0, 10)}
                <br /> {t("myaccount.purchased_date")}: {puchased_date}
              
              </small>

                )
              }
              
            </div>
            <div className="mx-1 text-right">

            </div>
          </div>
          <div className="bg-light p-1 rounded text-center">
            {this.renderTicketFields(feilds)}
          </div>
          {second_lottery_ticket_number&&<div className="bg-light p-1 rounded text-center">
            {this.renderTicketFields(feildsSecond)}
          </div>}
          <div className="text-center mt-2">
            <small className="my-0">
              {t("myaccount.serial_number")}: {ticket_serial_no}
            </small>
          </div>
        </div>
      </div>
    );
  }
}
export default translate(connect()(MyTicket));
