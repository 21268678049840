/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import InfiniteScroll from 'react-infinite-scroller';
import reduxDialog, { closeDialog, openDialog } from 'redux-reactstrap-modal';

import HomeLottery from './HomeLottery';
import { randomBuy, subscribe, loadOngoingLotteries, setNextEvent, loadLotteryPackages, getCommonWalletInfo, getCommonWalletInfoNoConsentCheck } from "../actions/index";
import _ from "lodash";
import { BANNER_MODAL_ACTIONS } from '../config';

class Home extends Component {

    componentWillMount() {
        // this.props.loadHeaderMobile();
        // this.props.getLotterySubcribeList();
        const {token} = this.props;
        
        if(token&&token!=""){
            this.props.getCommonWalletInfo();
            this.props.loadLotteryPackages();
            this.props.getCommonWalletInfoNoConsentCheck();
        }



        this.props.loadOngoingLotteries();
        //Refferral code identifier
        const { history } = this.props;

        const name = new URLSearchParams(history.location.search).get(
          "referral_code"
        );
        if (name !== null && name !=="") {
            sessionStorage.setItem("referral_code", name);
            }
            
        
        // if (this.props.location.search) {
        //     var paramsString = this.props.location.search;
        //     const params = new URLSearchParams(paramsString);
        //     const kpValue = params.get('kp');
        //     const kpData = { "kp": kpValue }
        //     this.props.enterKpValue(kpData);
        // }
        // this.props.openDialog('MODAL_ADVERT');
    }

    componentDidMount() {
    
        // let searchContent = this.props.location.search && this.props.location.search

        // if (this.checkUrlContainsParams(searchContent, 'cw-callback')) {
        //     const urlParams = new URLSearchParams(searchContent);
        //     const cwCallback = urlParams.get('cw-callback');

        //     if(cwCallback){
        //         this.setState({cwCallback:!cwCallback})
        //     }
            
        // }
    }

    componentDidUpdate(prevProps) {
        const { ongoingLotteries } = this.props;

        if (prevProps.ongoingLotteries !== ongoingLotteries) {
            this.showBannerModal(ongoingLotteries);
        }
    }

    showBannerModal(ongoingLotteries) {
        setTimeout(() => {
            const bannerData = JSON.parse(sessionStorage.getItem('lk_banner'));
            
            if (bannerData !== null) {

                const { lottery_id, draw_number, action, img_url, action_text, btn_color } = bannerData;
                
                if (lottery_id && draw_number && action && img_url && action_text && btn_color) {
                                
                    const lotteryForBanner =  _.filter(ongoingLotteries.data, { 
                        'lottery_id': Number(lottery_id), 
                        'draw_number': draw_number 
                    });

                    if (_.size(lotteryForBanner) > 0) {
                        if (action === BANNER_MODAL_ACTIONS.SUBSCRIBE || (lotteryForBanner[0].is_ongoing && !lotteryForBanner[0].is_soldOut)) {

                            const isAlreadyViewed = (sessionStorage.getItem('lk_banner_viewed') === 'true');

                            if (!isAlreadyViewed) {
                                this.props.openDialog("MODAL_BANNER", { 
                                    lottery : lotteryForBanner[0],
                                    action: action,
                                    image: img_url,
                                    actionText: action_text,
                                    btnColor: btn_color
                                });
                            }
                        }
                    }
                }
                else {
                    console.log("Banner configured incorrectly");
                }
            }

        }, 1000);
    }
    
    checkUrlContainsParams = (url, field) => {
        if (url.indexOf('?' + field + '=') != -1)
            return true;
        else if (url.indexOf('&' + field + '=') != -1)
            return true;
        return false
    }    

    renderLotteries() {
    
        const { ongoingLotteries, match: { params }, randomBuy, subscribe } = this.props;

        if (this.props.location) {
            var paramsString = this.props.location.search;
            const params = new URLSearchParams(paramsString);
            const tags = params.get('kp');
        }

        
        var data = _.orderBy(ongoingLotteries.data, ['is_ongoing','isBundleDraw','drawType'], ['desc','desc','desc']);
        // var data = _.orderBy(data, ['is_ongoing'], ['desc']);
        return data.map(lottery => {
            if(lottery){
                return (
                    <HomeLottery subcribeId={params.id} key={lottery.lottery_id} lottery={lottery} randomBuys={randomBuy} subscribes={subscribe} setNextEvent={setNextEvent}
                    />
                ); 
            }
            
        });
    }

    render() {
        return (
            <div className="row p-1 home-screen-height">
                {this.renderLotteries()}
            </div>

        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ loadOngoingLotteries, randomBuy, subscribe, setNextEvent, loadLotteryPackages, closeDialog, openDialog, getCommonWalletInfo, getCommonWalletInfoNoConsentCheck }, dispatch);
}

function mapStateToProps({ ongoingLotteries, token }) {
    return { ongoingLotteries, token };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
