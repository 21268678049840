/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from "react";
import { connect } from "react-redux";

import { bindActionCreators } from "redux";
import { loadResults, loadAvaliableLotteries } from "../actions/index";

import { translate, getLanguage } from "react-switch-lang";

class PrivacyPolicy extends Component {
  render() {
    const { t } = this.props;
    var lan = getLanguage();

    return (
      <div className="innerContent">
        <h2 className="content-title">{t("privacy_policy.policy_title")}</h2>
        <p>{t("privacy_policy.policy_para1")}</p>
        <p>{t("privacy_policy.policy_para2")}</p>

        <h5 className="content-title">{t("privacy_policy.title2")}</h5>
        <p>{t("privacy_policy.title2_para1")}</p>
        <p>{t("privacy_policy.title2_para2")}</p>

        <h5 className="content-title">{t("privacy_policy.title3")}</h5>
        <p>{t("privacy_policy.title3_para1")}</p>
        <p>{t("privacy_policy.title3_para2")}</p>

        <h5 className="content-title">{t("privacy_policy.title4")}</h5>
        <p>{t("privacy_policy.title4_para1")}</p>

        <h5 className="content-title">{t("privacy_policy.title5")}</h5>
        <p>{t("privacy_policy.title5_para1")}</p>

        <h5 className="content-title">{t("privacy_policy.title6")}</h5>
        <p>{t("privacy_policy.title6_para1")}</p>

        <h5 className="content-title">{t("privacy_policy.title7")}</h5>
        <p>{t("privacy_policy.title7_para1")}</p>

        <h5 className="content-title">{t("privacy_policy.title8")}</h5>
        <p>{t("privacy_policy.title8_para1")}</p>
      </div>
    );
  }
}

export default translate(connect()(PrivacyPolicy));
