/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { registerUser } from "../actions/index";
import reduxDialog from "redux-reactstrap-modal";
import { bindActionCreators } from "redux";
import generator from "generate-password";
import { translate, getLanguage } from "react-switch-lang";

class SignUpForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      typeSocial: false,
      password: ""
    };
  }

  componentDidMount() {
    const { data } = this.props;

    this.setState({
      typeSocial: data.typeSocial
    });
  }

  renderField(field) {
    const {
      meta: { touched, error }
    } = field;
    const className = `form-group ${touched && error ? "has-danger" : ""}`;
    var no_input_button = "";

    if (field.input.name === "mobile_no") {
      no_input_button = "form-control no_input_button";
    } else {
      no_input_button = "form-control";
    }

    return (
      <div className={className}>
        <label>{field.label}</label>
        <input
          className={no_input_button}
          type={field.type || "text"}
          {...field.input}
        />
        <div className="text-help">{touched ? error : ""}</div>
      </div>
    );
  }

  render() {
    const { handleSubmit, data, t } = this.props;

    return (
      <form onSubmit={handleSubmit(this.props.registerUser)}>
        <Field
          label={t("sign_up.name")}
          name="name"
          component={this.renderField}
        />
        <Field
          label={t("loginmodal.mobile_no")}
          name="mobile_no"
          component={this.renderField}
        />
        <Field
          label={t("sign_up.nic_no")}
          name="nic"
          component={this.renderField}
        />
        {!this.state.typeSocial && (
          <Field
            label={t("loginmodal.password")}
            name="password"
            component={this.renderField}
            type="password"
          />
        )}

        {!this.state.typeSocial && (
          <Field
            label={t("loginmodal.password")}
            name="confirm_password"
            component={this.renderField}
            type="password"
          />
        )}

        <button type="submit" className="btn btn-primary btn-block">
          {t("sign_up.sign_up")}
        </button>
      </form>
    );
  }
}

function validate(values, props) {
  const errors = {};
  const { t } = props;

  if (
    values.name === "" &&
    values.mobile_no === "" &&
    values.nic === "" &&
    values.password === "" &&
    values.confirm_password === ""
  ) {
  }
  if (!values.name) {
    errors.name = t("validate.name");
  }
  if (!values.mobile_no) {
    errors.mobile_no = t("validate.mobile_no");
  }

  // Validate the inputs from 'values'
  if (!values.nic) {
    errors.nic = t("validate.nic");
  }
  if (!values.password) {
    errors.password = t("validate.password");
  }

  if (values.password !== values.confirm_password) {
    errors.confirm_password = t("validate.confirm_password");
  }
  return errors;
}

function mapStateToProps(state, ownProps, context) {
  const { data } = ownProps;
  let userForm = {};

  if (data.social_account === "google") {
    userForm = {
      name: data.name,
      google_id: data.google_id,
      google_acc_token: data.google_acc_token,
      typeSocial: true
    };
  } else if (data.social_account === "facebook") {
    userForm = {
      name: data.name,
      facebook_id: data.facebook_id,
      facebook_acc_token: data.facebook_acc_token,
      typeSocial: true
    };
  } else {
    userForm = {
      name: data.name,
      typeSocial: false
    };
  }
  return { initialValues: userForm };
}

let ClientsFormData = reduxForm({
  validate,
  form: "SignUpForm",
  enableReinitialize: true
})(SignUpForm);
ClientsFormData = connect(
  state => mapStateToProps,
  { registerUser }
)(ClientsFormData);
export default translate(ClientsFormData);
