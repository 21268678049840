/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import axios from "axios";
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { openDialog, closeDialog } from "redux-reactstrap-modal";
import { API_ROOT_URL, MODALS, EVENTS, LIMIT, API_MERCHANT_PROXY_ROOT_URL, STRING} from '../config/';
import ReactGA from 'react-ga';
import _, { filter } from "lodash";
import generator from 'generate-password';
import { isEmptyObject } from "jquery";
import jwt_decode from "jwt-decode";
import store from "../index"
import { empty } from "glamor";
import moment from "moment";
import { TIME_OUT } from "../config";
var waitUntil = require('wait-until');

export const LOAD_ONGOING_LOTTERIES = "ONGOING_LOTTERIES_LOADED";
export const LOAD_PACKAGES = "PACKAGES_LOADED";
export const LOAD_RESULTS = "RESULTS_LOADED";
export const LOAD_WALLET_BALANCE = "WALLET_BALANCE_LOADED";
export const LOAD_WALLET_PACKAGES = "WALLET_PACKAGES_LOADED";
export const LOAD_PACKAGE_PRICE = "PACKAGE_PRICE_LOADED";
export const LOGGED_IN = "LOGGED_IN";
export const LOGGED_OUT = "LOGGED_OUT";
export const LOAD_USER_ACCOUNT = "USER_ACCOUNT_LOADED";
export const LOAD_MY_TICKETS = "LOAD_MY_TICKETS";
export const LOAD_MY_PACKAGES = "LOAD_MY_PACKAGES";
export const NEXT_EVENT = "NEXT_EVENT";
export const CLEAR_NEXT_EVENT = "CLEAR_NEXT_EVENT";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION";
export const PACKAGE_DEACTIVATED = "PACKAGE_DEACTIVATED";
export const REGISTER = 'REGISTER';
export const RESET_USER = 'RESET_USER';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const SOCIAL_LOGIN = 'SOCIAL_LOGIN';
export const SHOW_NOTIFICATION_MODAL = "SHOW_NOTIFICATION_MODAL";
export const RESULT_LOADING = 'RESULT_LOADING';
export const AVAILABLE_LOTTERIES_LOADED = 'AVAILABLE_LOTTERIES_LOADED';
export const NIC_ENTER = 'NIC_ENTER';
export const SUBCRIBE_SUCCESS = 'SUBCRIBE_SUCCESS';
export const HEADERMOBILEADDED = 'HEADERMOBILEADDED';
export const SECURE_SUBCRIBE_LIST = 'SECURE_SUBCRIBE_LIST';
export const DIALOG_APP_KP = 'DIALOG_APP_KP';
export const CLEAR_MY_PACKAGES = 'CLEAR_MY_PACKAGES';
export const CLEAR_SECURE_SUBCRIBE_LIST = 'CLEAR_SECURE_SUBCRIBE_LIST';
export const LANGUAGE = 'LANGUAGE';
export const LOAD_WALLET_INFO = "WALLET_INFO_LOADED";
export const GET_MOBILE_NUMBER = "GET_MOBILE_NUMBER";
export const GET_PAYMENT_SOURCE = "GET_PAYMENT_SOURCE";
export const LOAD_MY_AGENTS = "LOAD_MY_AGENTS";
export const GET_WALLET_BALANCE = "GET_WALLET_BALANCE";
export const GET_PAYMENT_METHOD = "GET_PAYMENT_METHOD";
export const BANNER_ACTION = "BANNER_ACTION";
export const LOAD_MY_AGENTS_SALES = "LOAD_MY_AGENTS_SALES";

export function loadLanguage(data) {


    return function (dispatch, getState) {
        dispatch({
            type: LANGUAGE,
            payload: data
        });
    }
}


export const axios_instance = axios.create({
    baseURL: API_ROOT_URL
});


//TODO : need to change the api to client proxy
const axios_instance_old_api = axios.create({
    baseURL: 'https://dev.api.bo.v2.lucky1.lk/api/'
});

const axios_dialog_ad = axios.create({
    baseURL: 'http://97.74.195.115:8061/trackingconversion/con'
});

const axios_merchant_proxy = axios.create({
    baseURL: API_MERCHANT_PROXY_ROOT_URL
});


// axios_instance.defaults.headers.common['authorization'] = CLIENT_PROXY_ACCESS_TOKEN;
axios_instance.defaults.headers.common['authorization'] = (localStorage.getItem('TOKEN') === "" || localStorage.getItem('TOKEN') === null) ? '' : localStorage.getItem('TOKEN');

// axios_instance.defaults.headers.common['X-API-User-Token'] = localStorage.getItem('TOKEN') === "" || localStorage.getItem('TOKEN') === null ? '' : localStorage.getItem('TOKEN');

export const clearNotification = function () {
    return (dispatch) => {
        dispatch({
            type: CLEAR_NOTIFICATION,
            payload: null
        });
    };
}

export const showNotificationModal = function (data) {
 
    return (dispatch) => {
        dispatch({
            type: SHOW_NOTIFICATION,
            payload: data
        });
    };   
}


export const setNextEvent = function (data) {
    // axios_instance.defaults.headers.common['nic'] = data.nic_number;

    return (dispatch) => {
        dispatch({
            type: NEXT_EVENT,
            payload: { event: data.event, data: data.props }
        });
    };
}

export const setNextEventLocalStorage = function (data) {
    // axios_instance.defaults.headers.common['nic'] = data.nic_number;

   // return localStorage.setItem(NEXT_EVENT, data);
    return (dispatch) => {
        localStorage.setItem(NEXT_EVENT, JSON.stringify(data));
    };
}

export const loadNextEventLocalStorage = function () {

    let data=localStorage.getItem(NEXT_EVENT);
    if(data){
        const dataPhrase=JSON.parse(data);
        return (dispatch) => {
            dispatch({
                type: NEXT_EVENT,
                payload: { event: dataPhrase.event, data: dataPhrase.props }
            });
        };
    }else{
        return (dispatch) => {
        };  
    }
    
}

export const clearNextEventLocalStorage = function (data) {
    return (dispatch) => {
        localStorage.removeItem(NEXT_EVENT);
    };
}


export const clearNextEvent = function (data) {
    return ({
        type: CLEAR_NEXT_EVENT
    });
}

// Refactored
export const enterNIC = function (data, scope) {
    const url = `/client/add-nic`;
    const request = axios_instance.patch(url, { nic: data });
    return scope = (dispatch, getState) => {
        // dispatch({
        //     type: CLEAR_NEXT_EVENT
        // });

        const state = getState();

        request.then((data) => {
            if (data.status === 200) {
                localStorage.setItem('nic', data.data.nic);
                dispatch(closeDialog(MODALS.NIC));
                dispatch(getCommonWalletInfo())
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: { type: "success", message: "Nic Has Been Stored." }
                });
                dispatch({
                    type: NIC_ENTER,
                    payload: data.data
                });
                
            } else {
                dispatch(closeDialog(MODALS.NIC));
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: { type: "danger", message: data.data.description }
                });
            }

        }).catch(error => {
            dispatch(hideLoading(scope));
            if (error.statusCode === 401) {
                dispatch(logout());
                dispatch(closeDialog(MODALS.BUY));
                dispatch(closeDialog(MODALS.NIC));
                dispatch(openDialog(MODALS.LOGIN));
            } else {
                dispatch(closeDialog(MODALS.BUY));
                dispatch(closeDialog(MODALS.NIC));
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: { type: "danger", message: error.data.description }
                });
            }
        });
    };
}

export const enterKpValue = function (data) {
    // if(data){
    return (dispatch) => {
        dispatch({
            type: DIALOG_APP_KP,
            payload: data
        });
    };
}

// Refactored
export const loadOngoingLotteries = scope => (dispatch) => {
    dispatch(showLoading(scope));

    const url = `/lottery/ongoing`;
    axios_instance.get(url).then(({ data }) => {
        const dataInitial = { "data": data, "page": { "total": 9, "limit": 0, "offset": 3 } };
        dispatch({
            type: LOAD_ONGOING_LOTTERIES,
            payload: dataInitial
        });

        const url = `/lottery/packages`;
        axios_instance.get(url).then(({ data }) => {
            dispatch(hideLoading(scope));
            dispatch({
                type: LOAD_PACKAGES,
                payload: data
            });

            //   const url = `/public/lottery-list`;
            //   axios_instance.get(url).then(({ data }) => {
            //     dispatch(hideLoading(scope));
            //     dispatch({
            //       type: AVAILABLE_LOTTERIES_LOADED,
            //       payload: data
            //     });
            //   });

        });


    }).catch(error => {
        dispatch(hideLoading());
        
        if (!error || !error.response || error.response.status === 502) { // api services down
            dispatch({
                type: SHOW_NOTIFICATION,
                payload: { type: "danger", message: STRING.CONNECTION_ERROR_MESSAGE }
            });
        }
        else if (error) {
            dispatch({
                type: SHOW_NOTIFICATION,
                payload: { type: "danger", message: STRING.REQUEST_FAILED_MESSAGE }
            });
        }
        
    });
}

// Refactored
export const loadAvaliableLotteries = function () {
    const url = `/lottery`;

    return (dispatch) => {
        axios_instance.get(url).then(({ data }) => {

            let results = {
                data: data
            }
            dispatch({
                type: AVAILABLE_LOTTERIES_LOADED,
                payload: results
            });
        }).catch((error)=>{});
    };

};

// Refactored
export const loadResults = function (offset, filters = {}, reset = false) {
    return (dispatch, getState) => {
        dispatch({ type: RESULT_LOADING, payload: true });
        const state = getState();
        let lottery_id = '';
        let draw_number = '';
        let searchDate = '';
        // var ass=  _.map(state.ongoingLotteries.data, 'draw_id'===props.draw_id);
        var search_lottery = "";

        if (filters && filters.lottery_id) {
            // search_lottery = state.available_packages.data.find(item => item.lottery_id == filters.lottery_id);
            lottery_id = filters.lottery_id;
        }

        if (filters && filters.draw_number) {
            draw_number = filters.draw_number;
        }

        if (filters && filters.search_date) {
            searchDate = filters.search_date;
        }


        if (filters && filters.lottery_id === '') {
            lottery_id = '';
        }
        if (filters && filters.lottery_id === 'Select') {
            lottery_id = '';
        }

        if (filters && filters.draw_number === '') {
            draw_number = '';
        }
        // dispatch(showLoading(scope));
        const url = `/lottery/results/search?limit=${LIMIT}&offset=${offset}&lottery_id=${lottery_id}&draw_number=${draw_number}&draw_date=${searchDate}`;
        // const url = `/public/lottery-results?limit=${LIMIT}&offset=${offset}&draw_number=${draw_number}&lottery_id=${lottery_id}&draw_date_time=${searchDate}`;
        axios_instance.get(url).then(({ data }) => {
            // dispatch(hideLoading(scope));
            let dataset = [];
            const dataInitial = { "data": [], "page": { "total": 0, "limit": 0, "offset": 0 } };
            const { results } = state;
            if (data && data.data) {
                if (offset > 0) {

                    let datasets = results.data.concat(data.data);
                    data.data = datasets;
                    dataset = data;
                } else {
                    dispatch({
                        type: LOAD_RESULTS,
                        payload: dataInitial
                    });
                    dataset = data;
                    if (filters) {
                        if (filters && filters.lottery_id) {
                            ReactGA.event({
                                category: "Results",
                                action: "View",
                                label: search_lottery.lottery_name,
                                value: 1
                            });
                        }
                    } else {
                        ReactGA.event({
                            category: "Results",
                            action: "View",
                            label: "All",
                            value: 1
                        });
                    }

                }
            } else {
                if (data.statusCode === 210) {
                    dispatch({
                        type: SHOW_NOTIFICATION,
                        payload: { type: "danger", message: data.description }
                    });

                    dispatch({
                        type: LOAD_RESULTS,
                        payload: dataInitial
                    });
                    dataset = dataInitial
                }
            }

            dispatch({ type: RESULT_LOADING, payload: false });
            dispatch({
                type: LOAD_RESULTS,
                payload: dataset
            });
        });
    }
}



// Refactored
export const otpVerification = function (props) {
    const url = `/client/verify`;
    const request = axios_instance.post(url, props);

    return (dispatch) => {
        request.then(({ data }) => {
            if (data.statusCode === 210) {
                dispatch({
                    type: SHOW_NOTIFICATION_MODAL,
                    payload: { type: "danger", message: data.description }
                });
            } else {
                dispatch(closeDialog(MODALS.OTP));

                // id: 206
                // token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjA2LCJtb2JpbGUiOiI5NDc3MDQ0ODIwMSIsImlhdCI6MTU3MzYzMDgwMiwiZXhwIjoxNTczNjMwODg4fQ.X0i8O5PLvCrQy8RZxKaGwh7MpHI8V2ifbi2WoG4FnF4"

                axios_instance.defaults.headers.common['authorization'] = data.token;
                dispatch(getCommonWalletInfo())
                localStorage.setItem('id', data.id);
                localStorage.setItem('nic', data.nic);
                localStorage.setItem('mobile', data.mobile);

                let datas = {
                    data: data
                }
                dispatch({
                    type: LOGGED_IN,
                    payload: datas
                });
                dispatch(getCommonWalletInfoNoConsentCheck())
            }

        }).catch(function (res) {
            let error = JSON.parse(JSON.stringify(res));
            if (res instanceof Error) {
                if (res.response.data.error_code == "400024") {
                    dispatch(closeDialog(MODALS.LOGIN));
                    dispatch(clearNotification());
                    dispatch(openDialog(MODALS.PINVERIFY, props))
                } else {
                    dispatch({
                        type: SHOW_NOTIFICATION_MODAL,
                        payload: { type: "danger", message: error.response.data.message }
                    });
                }
            }

        });
    };

}

// Refactored
export const login = function (props) {
    const url = `/client/login`;
    const request = axios_instance.post(url, props);

    return (dispatch) => {
        request.then(({ data, status }) => {
            if (status === 200) {
                dispatch(closeDialog(MODALS.LOGIN));
                dispatch(openDialog(MODALS.OTP, props));
            } else {
                dispatch({
                    type: SHOW_NOTIFICATION_MODAL,
                    payload: { type: "danger", message: data.description }
                });
            }

        }).catch(function (res) {
            let error = JSON.parse(JSON.stringify(res));
            if (res instanceof Error) {
                if (res.response.data.error_code == "400024") {
                    dispatch(closeDialog(MODALS.LOGIN));
                    dispatch(clearNotification());
                    dispatch(openDialog(MODALS.PINVERIFY, props))
                } else {
                    dispatch({
                        type: SHOW_NOTIFICATION_MODAL,
                        payload: { type: "danger", message: error.response.data.message }
                    });
                }
            }
        });
    };

}

export const fbLogin = function (response) {
    var fb_user = {
        social_account: "facebook",
        facebook_id: response.id,
        facebook_acc_token: response.accessToken,
        name: response.name
    };

    return login(fb_user);
    //anything else you want to do(save to localStorage)...
}

export const googleLogin = function (googleUser) {
    var id_token = googleUser.getAuthResponse().access_token;
    var googleId = googleUser.getId();
    var googleUser = {
        social_account: "google",
        google_id: googleId,
        google_acc_token: id_token,
        name: ""
    };
    return login(googleUser);
    //anything else you want to do(save to localStorage)...
}

export const createNewUserPin = function (mobile_no) {
    // const url = `${API_ROOT_URL}/public/user/create-new-pin`;
    const url = `${API_ROOT_URL}/public/user/create-new-pin`;

    return (dispatch) => {
        return axios_instance
            .post(url, { mobile_no })
            .then(function (response) {
                if (response.data && response.data.message && response.data.message.message) {
                    dispatch({
                        type: SHOW_NOTIFICATION_MODAL,
                        payload: { type: "success", message: response.data.message.message }
                    });
                    return {
                        status: true,
                        message: response.data.message.message
                    };
                }
                return { status: true };
            })
            .catch(function (error) {

            });
    };
};


export const registerUser = function (saveUser) {

    saveUser.access_source = 'WEB_APP_USER';
    const url = `${API_ROOT_URL}/public/user/save`;

    let password = generator.generate({
        length: 10,
        numbers: true
    });

    if (saveUser.typeSocial) {
        saveUser.password = password;
        saveUser.confirm_password = password;
    }

    const request = axios_instance.post(url, saveUser);

    return (dispatch) => {
        request.then(({ data }) => {
            dispatch({
                type: REGISTER,
                payload: data.data
            });
            dispatch(closeDialog(MODALS.SIGNUP));
            dispatch(clearNotification());
            dispatch(openDialog(MODALS.PINVERIFY, saveUser));
            // dispatch(closeDialog(MODALS.LOGIN));
        }).catch(error => {
            if (error.response.data.error_code !== null) {
                if (error.response.data.error_code === "400024") {
                    dispatch(closeDialog(MODALS.SIGNUP));
                    dispatch(clearNotification());
                    dispatch(openDialog(MODALS.PINVERIFY, saveUser));
                    // dispatch(closeDialog(MODALS.LOGIN));
                }
                dispatch({
                    type: SHOW_NOTIFICATION_MODAL,
                    payload: { type: "danger", message: error.response.data.message }
                });
                return {
                    status: false,
                    message: error.response.data.message
                }
            }
        });
    };
}

export const userNumberVerify = function (mobile_pin, mobile_number) {

    // axios_instance.defaults.headers.common['otp'] = mobile_pin;
    let body = {
        mobile_pin: mobile_pin,
        mobile_no: mobile_number
    };
    const url = `${API_ROOT_URL}/public/user/validate-pin`;

    return (dispatch, getState) => {

        return axios_instance
            .post(url, body)
            .then(function (response) {
                try {
                    if (response && response.data) {
                        axios_instance.defaults.headers.common['X-API-User-Token'] = response.data.data.exchange_token;
                        dispatch({ type: LOGGED_IN, payload: response.data });
                        // axios_instance.defaults.headers.common['otp'] = null;
                        dispatch(getCommonWalletInfo())
                        dispatch(closeDialog(MODALS.PINVERIFY));
                        dispatch(closeDialog(MODALS.NIC));
                    } else {
                    }
                } catch (error) {
                    return {
                        status: false,
                        message: error.response.data.message
                    };
                }
            })
            .catch(function (error) {
                // axios_instance.defaults.headers.common['otp'] = null;
                dispatch({
                    type: SHOW_NOTIFICATION_MODAL,
                    payload: { type: "danger", message: error.response.data.message }
                });
                return {
                    status: false,
                    message: error.response.data.message
                };
            });
    };
}

export const saveUserPassword = function (savePassword) {
    const url = `${API_ROOT_URL}/secure/user/reset-password`;
    const request = axios_instance.post(url, savePassword);

    return (dispatch) => {
        request.then(({ data }) => {
            dispatch({
                type: RESET_PASSWORD,
                payload: data
            });
            dispatch(closeDialog(MODALS.PASSWORDRESET));
            dispatch({
                type: SHOW_NOTIFICATION,
                payload: { type: "success", message: data.message.message }
            });
        }).catch(function (error) {
            if (error.error_code === '400024') {
                dispatch(closeDialog(MODALS.PASSWORDRESET));
                dispatch(openDialog(MODALS.PINVERIFY));
            } else {
                dispatch({
                    type: SHOW_NOTIFICATION_MODAL,
                    payload: { type: "danger", message: error.response.data.message }
                });
            }
        });
    };

};

export const userPasswordForgot = function (forgetData) {
    const url = `${API_ROOT_URL}/public/user/forgot-password`;
    const request = axios_instance.post(url, forgetData);

    return (dispatch) => {
        request.then(({ data }) => {
            dispatch({
                type: RESET_PASSWORD,
                payload: data
            });
            dispatch(closeDialog(MODALS.PASSWORDFORGET));
            dispatch(openDialog(MODALS.PINVERIFY, forgetData));
        }).catch(function (error) {
            if (error.response.data.error_code === '400024') {
                dispatch(closeDialog(MODALS.PASSWORDFORGET));
                dispatch(openDialog(MODALS.PINVERIFY, forgetData));
                dispatch({
                    type: SHOW_NOTIFICATION_MODAL,
                    payload: { type: "danger", message: error.response.data.message }
                });
            } else {
                dispatch({
                    type: SHOW_NOTIFICATION_MODAL,
                    payload: { type: "danger", message: error.response.data.message }
                });
            }
        });
    };

};

export const logout = function (props) {

    axios_instance.defaults.headers.common['X-API-User-Token'] = "";
    // axios_instance.defaults.headers.common['msisdn'] = "";
    return (dispatch, getState) => {
        // window.location.reload();
        dispatch({ type: LOGGED_OUT });
      //  dispatch ({type: CLEAR_NEXT_EVENT});
        dispatch({ type: CLEAR_MY_PACKAGES });
        dispatch({ type: CLEAR_SECURE_SUBCRIBE_LIST });
        
    }
}

// Refactored
export const loadLotteryPackages = scope => (dispatch) => {
    dispatch(showLoading(scope));

    let id = localStorage.getItem('id');
    const url = `/client/packages`;
    axios_instance.get(url).then(({ data }) => {

        dispatch({
            type: LOAD_MY_PACKAGES,
            payload: data
        });
        dispatch(hideLoading(scope));

    }).catch(error => {
        console.log(error);

        dispatch({
            type: CLEAR_MY_PACKAGES
        });
        dispatch(hideLoading(scope));
        // if (error.response.data.error_code === "401001") {
        //   dispatch(logout());
        // }
    });
}

// Refactored
export const loadMyLottery = function (offset, filters, reset) {
    return (dispatch, getState) => {
        // dispatch(showLoading(scope));
        const state = getState();
        let lottery_id = '';
        let draw_number = '';
        let searchDate = '';
        let id = localStorage.getItem('id');
        offset = offset === undefined ? 0 : offset;

        if (filters && filters.lottery_id) {
            lottery_id = filters.lottery_id;
        }

        if (filters && filters.draw_number) {
            draw_number = filters.draw_number;
        }

        if (filters && filters.search_date) {
            searchDate = filters.search_date;
        }


        if (filters && filters.lottery_id === '') {
            lottery_id = '';
        }
        if (filters && filters.lottery_id === 'Select') {
            lottery_id = '';
        }

        if (filters && filters.draw_number !== '' && filters.lottery_id === '') {
            dispatch({
                type: SHOW_NOTIFICATION,
                payload: { type: "danger", message: 'Select a lottery ticket' }
            });
        }

        if (filters && filters.draw_number === '') {
            draw_number = '';
        }

        const url = `/client/purchased?limit=${LIMIT}&offset=${offset}&lottery_id=${lottery_id}&draw_number=${draw_number}&purchased_date=${searchDate}`;
        axios_instance.get(url).then(({ data }) => {

            /**
             * From the api the page details not coming, hardcoded here
             */

            data = { "data": data.data, "page": { "total": 10000000000, "limit": LIMIT, "offset": offset } };

            let dataset = [];
            const dataInitial = { "data": [], "page": { "total": 0, "limit": 0, "offset": 0 } };
            const { myTickets } = state;
            if (data && data.data) {
                if (offset > 0) {

                    /**
                     * Removed belongs code due to duplicating
                     * Need to Check this after serverside pagination done
                     * 
                     * 06-01-2020 Fix that one
                     */

                    let datasets = myTickets.data.concat(data.data);
                    data.data = datasets;
                    dataset = data;
                } else {
                    dispatch({
                        type: LOAD_MY_TICKETS,
                        payload: dataInitial
                    });
                    dataset = data;
                }
            }

            dispatch({
                type: LOAD_MY_TICKETS,
                payload: dataset
            });

        }).catch(error => {
            error = JSON.parse(JSON.stringify(error));
            if (error.response.status === 401 || error.response.status === 400) {
                dispatch(logout());
                dispatch(openDialog(MODALS.LOGIN));
                dispatch({
                    type: NEXT_EVENT,
                    payload: { event: EVENTS.ACCOUNT }
                });
            }
        });
    }
}

// Refactored
export const loadAccount = scope => (dispatch) => {
    // axios_instance.defaults.headers.common['authorization'];

    dispatch(showLoading(scope));

    const url = `/client`;
    axios_instance.get(url).then(({ data }) => {    //get the details of the client
        dispatch({
            type: LOAD_USER_ACCOUNT,
            payload: data
        });
        const url = `/client/packages`;
        axios_instance.get(url).then(({ data }) => {    //get the getails of the client pack
            dispatch({
                type: LOAD_MY_PACKAGES,
                payload: data
            });

            //Refactored url 27-03-2020
            const url = `my-payments`;

            axios_instance.get(url).then(({ data }) => {    //get the getails of the wallet
                if (data.status) {
                    dispatch({
                        type: LOAD_WALLET_BALANCE,
                        payload: data
                    });
                    console.log(data)
                }

                dispatch(hideLoading(scope));
            }).catch((error)=>{
                // console.log()
            });              

        });

    }).catch(error => {
        error = JSON.parse(JSON.stringify(error));
        if (error.response.status === 401 || error.response.status === 400) {
            dispatch(logout());
           // dispatch(openDialog(MODALS.LOGIN));
            dispatch({
                type: NEXT_EVENT,
                payload: { event: EVENTS.ACCOUNT }
            });
        }
    });
}

// Call existing api for identify whether token expired
export const checkTokenExpiration = (event,props) => {
    const url = `/client`;
    return axios_instance.get(url).then((data)=>{
        // if token expired data undefined and return true
        return data ? false : true;
    }).catch((error)=>{
        error = JSON.parse(JSON.stringify(error));
        if(error.response.status === 401){
            store.dispatch(logout());
            store.dispatch(openDialog(MODALS.LOGIN));
            store.dispatch(setNextEvent({event,props}));
            return true
        }
    })
}

// Refactored
export const subscribe = (props, scope) => {
    if (localStorage.getItem("TOKEN")) {
        if (localStorage.getItem('nic') !== 'null') {

            console.log("yyyy");

            // props.nic = localStorage.getItem('nic')
            const url = `/client/ticket-allocate`;
            const request = axios_instance.post(url, props);

            return scope = (dispatch, getState) => {
                const state = getState();

                var search_lottery = { lottery_name: "" };
                if (state.packages.length !== 0) {
                    search_lottery = state.packages.find(item => item.package_name == props.lottery_name);
                }
                dispatch({
                    type: CLEAR_NEXT_EVENT
                });

                request.then((data) => {
                    console.log("DATA === ", data);
                    if (data.status === 210) {
                        dispatch({
                            type: SHOW_NOTIFICATION,
                            payload: { type: "danger", message: data.data.description }
                        });
                    } else if(data.status === 200){
                        dispatch(closeDialog(MODALS.SUB));
                        if(data.data.alreadySubscribed){
                            dispatch({
                                type: SHOW_NOTIFICATION,
                                payload: { type: "success", message: "Already Subscribed" }
                            }); 
                        } else{
                            dispatch({
                                type: SHOW_NOTIFICATION,
                                payload: { type: "success", message: "Successfully Subscribed" }
                            });
                            dispatch(refreshWalletBalance(TIME_OUT))
                        }                                            
                        dispatch({
                            type: SUBCRIBE_SUCCESS,
                            payload: { "subcribe_success": true, "id": props.package_id }
                        });
                        dispatch(loadLotteryPackages());
                        // dispatch(getLotterySubcribeList());
                        dispatch(closeDialog(MODALS.NIC));

                    }
                    else {
                        dispatch(closeDialog(MODALS.SUB));
                        // dispatch(loadLotteryPackages());
                        // dispatch(getLotterySubcribeList());
                        dispatch(closeDialog(MODALS.NIC));
                    }
                    // ReactGA.event({
                    //     category: "Package",
                    //     action: "Subscribe",
                    //     label: search_lottery.package_name !== "" ? search_lottery.package_name : 'New Lottery',
                    //     value: props.subscribe_count
                    // });


                }).catch(error => {
                    dispatch(hideLoading(scope));
                    if (error.response.data.status === 401) {
                        dispatch(logout());
                        dispatch(closeDialog(MODALS.SUB));
                        dispatch(openDialog(MODALS.LOGIN));
                        dispatch({
                            type: NEXT_EVENT,
                            payload: { event: EVENTS.SUBSCRIBE, data: props }
                        });
                    } else {
                        dispatch(closeDialog(MODALS.SUB));
                        dispatch({
                            type: SHOW_NOTIFICATION,
                            payload: { type: "danger", message: error.response.data.description }
                        });
                    }
                });
            };
        } else {
            return scope = (dispatch, getState) => {
                dispatch(hideLoading(scope));
                dispatch(closeDialog(MODALS.SUB));
                dispatch(openDialog(MODALS.NIC));

                //This function is calling for 60 sec to get nic number from the api
                //The moment NIC got it will call buy function
                waitUntil()
                    .interval(1000)
                    .times(60)
                    .condition(function () {
                        return (!(localStorage.getItem('nic') === null || localStorage.getItem('nic') === 'null') ? true : false);
                    })
                    .done(function (result) {
                        if (result) {
                            // props.payload.customerId = localStorage.getItem("id");
                            dispatch({
                                type: NEXT_EVENT,
                                payload: { event: EVENTS.SUBSCRIBE, data: props }
                            });
                        } else {
                            dispatch(closeDialog(MODALS.LOGIN));
                        }
                    });
            }
        }
    } else {
        return scope = (dispatch, getState) => {
            dispatch(hideLoading(scope));
            dispatch(closeDialog(MODALS.SUB));
            dispatch(openDialog(MODALS.LOGIN));

            //This function is calling for 60 sec to get nic number from the api
            //The moment NIC got it will call buy function
            waitUntil()
                .interval(1000)
                .times(60)
                .condition(function () {
                    return (localStorage.getItem("TOKEN") ? true : false);
                })
                .done(function (result) {
                    if (result) {
                        // props.payload.customerId = localStorage.getItem("id");
                        dispatch({
                            type: NEXT_EVENT,
                            payload: { event: EVENTS.SUBSCRIBE, data: props }
                        });
                    } else {
                        dispatch(closeDialog(MODALS.LOGIN));
                    }
                });
        }
        // return openDialog(MODALS.LOGIN);
    }
}

// Refactored
export const randomBuy = (props, scope) => {
    if (localStorage.getItem("TOKEN")) {
        if (localStorage.getItem('nic') !== 'null') {

            // props.nic = localStorage.getItem('nic')
            const url = `/client/ticket-allocate`;
            const request = axios_instance.post(url, props);

            return scope = (dispatch, getState) => {
                dispatch({
                    type: CLEAR_NEXT_EVENT
                });

                request.then((data) => {
                    if (data.status === 200) {
                        dispatch(closeDialog(MODALS.BUY));
                        dispatch({
                            type: SHOW_NOTIFICATION,
                            payload: { type: "success", message: data.data.SalesTxnResponseStatus }
                        });
                        dispatch(refreshWalletBalance(TIME_OUT))
                    } else {
                        dispatch(closeDialog(MODALS.BUY));
                        dispatch({
                            type: SHOW_NOTIFICATION,
                            payload: { type: "danger", message: data.data.description }
                        });
                    }

                }).catch(error => {
                    error = JSON.parse(JSON.stringify(error));

                    dispatch(hideLoading(scope));
                    if (error.response.data.status === 401) {
                        dispatch(logout());
                        dispatch(openDialog(MODALS.LOGIN));
                        dispatch({
                            type: NEXT_EVENT,
                            payload: { event: EVENTS.ACCOUNT }
                        });
                    }
                    dispatch({
                        type: SHOW_NOTIFICATION,
                        payload: { type: "danger", message: error.response.data.description }
                    });
                });
            };
        } else {
            return scope = (dispatch, getState) => {
                dispatch(hideLoading(scope));
                dispatch(closeDialog(MODALS.BUY));
                dispatch(openDialog(MODALS.NIC));

                //This function is calling for 60 sec to get nic number from the api
                //The moment NIC got it will call buy function
                waitUntil()
                    .interval(1000)
                    .times(60)
                    .condition(function () {
                        return (!(localStorage.getItem('nic') === null || localStorage.getItem('nic') === 'null') ? true : false);
                    })
                    .done(function (result) {
                        if (result) {
                            // props.payload.customerId = localStorage.getItem("id");
                            dispatch({
                                type: NEXT_EVENT,
                                payload: { event: EVENTS.RANDOM_BUY, data: props }
                            });
                        } else {
                            dispatch(closeDialog(MODALS.LOGIN));
                        }
                    });
            }
            // return openDialog(MODALS.NIC);
        }
    } else {
        return scope = (dispatch, getState) => {
            dispatch(hideLoading(scope));
            dispatch(closeDialog(MODALS.BUY));
            dispatch(openDialog(MODALS.LOGIN));

            //This function is calling for 60 sec to get nic number from the api
            //The moment NIC got it will call buy function
            waitUntil()
                .interval(1000)
                .times(60)
                .condition(function () {
                    return (localStorage.getItem("TOKEN") ? true : false);
                })
                .done(function (result) {
                    if (result) {
                        // props.payload.customerId = localStorage.getItem("id");
                        dispatch({
                            type: NEXT_EVENT,
                            payload: { event: EVENTS.RANDOM_BUY, data: props }
                        });
                    } else {
                        dispatch(closeDialog(MODALS.LOGIN));
                    }
                });
        }
        // return openDialog(MODALS.LOGIN);
    }
}

// Refactored
export const buy = (props, scope) => {

    const url = `/client/ticket-allocate`;
    if (localStorage.getItem("TOKEN")) {
        if (localStorage.getItem('nic') === null || localStorage.getItem('nic') === 'null') {

            return scope = (dispatch, getState) => {
                dispatch(hideLoading(scope));
                dispatch(closeDialog(MODALS.SEARCH));
                dispatch(openDialog(MODALS.NIC));

                //This function is calling for 60 sec to get nic number from the api
                //The moment NIC got it will call buy function
                waitUntil()
                    .interval(1000)
                    .times(60)
                    .condition(function () {
                        return (!(localStorage.getItem('nic') === null || localStorage.getItem('nic') === 'null') ? true : false);
                    })
                    .done(function (result) {
                        if (result) {
                            dispatch({
                                type: NEXT_EVENT,
                                payload: { event: EVENTS.BUY, data: props }
                            });
                        } else {
                            dispatch(closeDialog(MODALS.NIC));
                        }
                    });

            }
        } else {
            let reqData = {
                salesType: "ON_DEMAND_SEARCH_AND_BUY",
                source: "web",
                paymentMethod: "MOBILE",
                payload: {
                    customerId: localStorage.getItem('id'),
                    lotteryId: props.lottery_id,
                    ticketIds: props.lottery_ticket_ids
                }
            }

            const request = axios_instance.post(url, props);
            return scope = (dispatch, getState) => {
                dispatch({
                    type: CLEAR_NEXT_EVENT
                });
                const state = getState();
                // var ass=  _.map(state.ongoingLotteries.data, 'draw_id'===props.draw_id);
                var search_lottery = state.ongoingLotteries.data.find(item => item.draw_id == props.draw_id);

                request.then(({ data, status }) => {
                    if (status === 200) {
                        dispatch(closeDialog(MODALS.NIC));
                        dispatch(closeDialog(MODALS.SEARCH));
                        dispatch({
                            type: SHOW_NOTIFICATION,
                            payload: { type: "success", message: data.SalesTxnResponseStatus }
                        });
                        dispatch({
                            type: SHOW_NOTIFICATION_MODAL,
                            payload: { type: "success", message: data.SalesTxnResponseStatus }
                        });
                        ReactGA.event({
                            category: "Ticket",
                            action: "Search and Buy",
                            label: search_lottery.lottery_name,
                            value: props.lottery_ticket_ids.length
                        });

                        dispatch(closeDialog(MODALS.WARNING));
                        // dispatch(openDialog(MODALS.NIC));
                        // dispatch({
                        //   type: NEXT_EVENT,
                        //   payload: { event: EVENTS.BUY, data: props }
                        // });
                        dispatch(refreshWalletBalance(TIME_OUT))
                    } else if (status === 210) {
                        dispatch({
                            type: SHOW_NOTIFICATION,
                            payload: { type: "danger", message: data.description }
                        });

                    }

                }).catch(error => {
                    error = JSON.parse(JSON.stringify(error));
                    dispatch(hideLoading(scope));
                    if (!isEmptyObject(error)) {            // NOTE: This is added due to get empty object even success
                        if (error.response.data.status === 401) {
                            dispatch(logout());
                            dispatch(closeDialog(MODALS.WARNING));
                            dispatch(closeDialog(MODALS.SEARCH));
                            dispatch(openDialog(MODALS.LOGIN));
                            dispatch({
                                type: NEXT_EVENT,
                                payload: { event: EVENTS.BUY, data: props }
                            });
                        } else {
                            dispatch(closeDialog(MODALS.WARNING));
                            dispatch(closeDialog(MODALS.SEARCH));
                            dispatch({
                                type: SHOW_NOTIFICATION,
                                payload: { type: "danger", message: error.response.data.description }
                            });
                        }
                    }        
                });

            };
        }
    } else {
        return scope = (dispatch, getState) => {
            dispatch(hideLoading(scope));
            dispatch(closeDialog(MODALS.SEARCH));
            dispatch(openDialog(MODALS.LOGIN));

            //This function is calling for 60 sec to get nic number from the api
            //The moment NIC got it will call buy function
            waitUntil()
                .interval(500)
                .times(120)
                .condition(function () {
                    return (localStorage.getItem("TOKEN") ? true : false);
                })
                .done(function (result) {
                    if (result) {
                        dispatch({
                            type: NEXT_EVENT,
                            payload: { event: EVENTS.BUY, data: props }
                        });

                        dispatch(closeDialog(MODALS.LOGIN));
                    } else {
                        dispatch(closeDialog(MODALS.LOGIN));
                    }
                });
        }
    }

}

// Refactored
export const deactivate = (props, scope) => {
    //props.source = "WEB_APP_USER";
    let mobile = localStorage.getItem('mobile');
    const url = `/client/packages/unsubscribe/${props.package_id}`;
    const request = axios_instance.put(url, '');


    return scope = (dispatch, getState) => {
        const state = getState();
        // var ass=  _.map(state.ongoingLotteries.data, 'draw_id'===props.draw_id);

        var search_lottery = {};
        // var ass=  _.map(state.ongoingLotteries.data, 'draw_id'===props.draw_id);
        if (state.myPackages.length !== 0) {
            search_lottery = state.myPackages.find(item => item.package_id == props.package_id);
        } else {
            search_lottery = { lottery_name: "" };
        }

        request.then(({ data }) => {
            if (data.isUnsubscribed) {
                dispatch({
                    type: PACKAGE_DEACTIVATED,
                    payload: props
                });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: { type: "success", message: 'Successfully unsubscribed' }
                });
                ReactGA.event({
                    category: "Package",
                    action: "Unsubscribe",
                    label: search_lottery.package_name,
                    value: 1
                });
            } else {
                dispatch(closeDialog(MODALS.WARNING));
            }
            dispatch(closeDialog(MODALS.WARNING));

        }).catch(error => {
            error = JSON.parse(JSON.stringify(error));
            if (error.response.status === 401 || error.response.status === 400) {
                dispatch(logout());
                dispatch(openDialog(MODALS.LOGIN));
                dispatch({
                    type: NEXT_EVENT,
                    payload: { event: EVENTS.ACCOUNT }
                });
            }
        });
    };
}

/**
 * This is to update the mobile number of the Customer
 * 12-02-2020 : This feature no longer available for customers
 */

export const mobileNoReset = (props, scope) => {
    let id = localStorage.getItem('id');

    let reqData = {
        mobile: props.mobile,
        id: id
    }

    const url = `web/customers`;
    // if (props.mobile_no !== "") {
    //   // axios_instance.defaults.headers.common['msisdn'] = props.mobile_no;
    // }
    if (props.nic_number) {
        // axios_instance.defaults.headers.common['nic'] = props.nic_number;
    }

    const request = axios_instance.put(url, reqData);

    return scope = (dispatch, getState) => {
        const state = getState();
        dispatch({
            type: HEADERMOBILEADDED,
            payload: { "msisdn": props.mobile_no }
        });

        request.then(({ data }) => {
            if (data.statusCode === 210) {
                dispatch({
                    type: SHOW_NOTIFICATION_MODAL,
                    payload: { type: "danger", message: data.description }
                });
            } else {
                dispatch(closeDialog(MODALS.MOBILENORESET));
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: { type: "success", message: 'Mobile Number Updated Successfuly' }
                });

                //Time set manually here for 3 sec
                setTimeout(function () {
                    window.location.reload()
                }, 3000)
            }
        }).catch(error => {
            error = JSON.parse(JSON.stringify(error));
            dispatch({
                type: SHOW_NOTIFICATION_MODAL,
                payload: { type: "danger", message: 'Mobile number not valid' }
            });

        });
    };

}

export const mobileNoUpdate = (props, scope) => {
    const url = `/secure/create-temp-token`;
    // if (props.mobile_no !== "") {
    //   // axios_instance.defaults.headers.common['msisdn'] = props.mobile_no;
    // }
    if (props.nic_number) {
        // axios_instance.defaults.headers.common['nic'] = props.nic_number;
    }

    const request = axios_instance.post(url, props);


    return scope = (dispatch, getState) => {
        const state = getState();
        dispatch({
            type: HEADERMOBILEADDED,
            payload: { "msisdn": props.mobile_no }
        });

        request.then(({ data }) => {
            dispatch(closeDialog(MODALS.LOGIN));
            dispatch({
                type: SHOW_NOTIFICATION,
                payload: { type: "success", message: data.message.message }
            });
            // dispatch(getLotterySubcribeList());
            dispatch(openDialog(MODALS.PINVERIFY, props));
            dispatch(closeDialog(MODALS.NIC));
            dispatch(clearNotification());
        }).catch(error => {
            dispatch(hideLoading(scope));
            if (error.response.data.error_code === "400004") {
                dispatch({
                    type: SHOW_NOTIFICATION_MODAL,
                    payload: { type: "danger", message: error.response.data.message }
                });
                dispatch(closeDialog(MODALS.LOGIN));
                dispatch(openDialog(MODALS.NIC, props));
            } else {
                dispatch({
                    type: SHOW_NOTIFICATION_MODAL,
                    payload: { type: "danger", message: error.response.data.message }
                });
            }

        });
    };

}
//This is to get the wallet packages 
export const getPackage = function () {

    const url = `/subscription/my-payments-packages`;   //Refactored url 27-03-2020

    return (dispatch) => {
        axios_instance.get(url).then(({ data }) => {

            dispatch({
                type: LOAD_WALLET_PACKAGES,
                payload: data
            });
        });
    };
}

//This is to get the wallet package price and details
export const getPackagePrice = function (data) {
    const url = `/subscription/${data.lotteryId}/price?walletPackageId=1&ticketsCount=${data.ticketsCount}`;
    // const url = `/subscription/${data.lotteryId}/price?walletPackageId=${data.walletPackageId}&ticketsCount=${data.ticketsCount}`;
    // axios_instance_old_api.defaults.headers.common['authorization'] = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiZmlyc3ROYW1lIjoibHVja3kxIiwibGFzdE5hbWUiOiJhZG1pbiIsImVtYWlsIjoiYWRtaW5AbHVja3kuY29tIiwib3BlcmF0b3IiOjAsImlzTWVyY2hhbnRVc2VyIjpmYWxzZSwibWVyY2hhbnRJZCI6MCwiaWF0IjoxNTgxMDY2NjU5LCJleHAiOjE2MTI2MDI2NTl9.wTRCvnrC3p2Sa8rYyXeJyblDFGLRz9ueR8FMtwhd70E';

    return (dispatch) => {
        axios_instance.get(url).then(({ data }) => {
            dispatch({
                type: LOAD_PACKAGE_PRICE,
                payload: data
            });
        });
    };
}

// Wallet subscription with wallet
// TODO: The status code always giving 200, need to do the error handle after fixing this
export const subscribeWithWallet = (props, scope) => {
    if (localStorage.getItem("TOKEN")) {
        // if (localStorage.getItem('nic') !== 'null') {
            return (dispatch, getState) => {
                dispatch(showLoading(scope));
                dispatch({
                    type: CLEAR_NEXT_EVENT
                });

                const mobileNo = localStorage.getItem("mobile");
                const url = `/subscription/${props.lotteryId}`;      //Refactored url 27-03-2020
                const request = axios_instance.post(url, props.payload);

                request.then(({ data, status }) => {
                    // This senario comes when the customer dont have wallet or 
                    // The wallet dont have any pay instruments
                    if (data.customErrorCode === 'LKE-127') {
                        dispatch(hideLoading(scope));

                    }
                    else if (data.customErrorCode === 'LKE-126') {
                        dispatch(hideLoading(scope));
                        dispatch(closeDialog(MODALS.SUB));
                        dispatch({
                            type: SHOW_NOTIFICATION,
                            payload: { type: "success", message: data.description }
                        });
                    }
                    else if (data.customErrorCode === 'LKE-066') {
                        dispatch(hideLoading(scope));
                        dispatch(closeDialog(MODALS.SUB));
                        dispatch({
                            type: SHOW_NOTIFICATION,
                            payload: { type: "danger", message: data.description }
                        });
                    }
                    else {
                        dispatch(hideLoading(scope));
                        dispatch(closeDialog(MODALS.SUB));
                        dispatch({
                            type: SHOW_NOTIFICATION,
                            payload: { type: "success", message: data.message }
                        });
                        dispatch(loadLotteryPackages());
                    }

                }).catch(error => {
                    error = JSON.parse(JSON.stringify(error));
                    dispatch(hideLoading(scope));
                    if (error.response.data.status === 401) {
                        dispatch(logout());
                        dispatch(closeDialog(MODALS.WARNING));
                        dispatch(closeDialog(MODALS.SUB));
                        dispatch(openDialog(MODALS.LOGIN));
                        dispatch({
                            type: NEXT_EVENT,
                            payload: { event: EVENTS.SUBSCRIBE_WITH_WALLET, data: props }
                        });
                    } else {
                        dispatch(closeDialog(MODALS.WARNING));
                        dispatch(closeDialog(MODALS.SUB));
                        dispatch({
                            type: SHOW_NOTIFICATION,
                            payload: { type: "danger", message: error.response.data.description }
                        });
                    }

                });
            };
        
    } else {
        return scope = (dispatch, getState) => {
            dispatch(hideLoading(scope));
            dispatch(closeDialog(MODALS.SUB));
            dispatch(openDialog(MODALS.LOGIN));

            //This function is calling for 60 sec to get nic number from the api
            //The moment NIC got it will call buy function
            waitUntil()
                .interval(1000)
                .times(60)
                .condition(function () {
                    return (localStorage.getItem("TOKEN") ? true : false);
                })
                .done(function (result) {
                    if (result) {
                        // props.payload.customerId = localStorage.getItem("id");
                        dispatch({
                            type: NEXT_EVENT,
                            payload: { event: EVENTS.SUBSCRIBE_WITH_WALLET, data: props }
                        });
                        dispatch({
                            type: CLEAR_NEXT_EVENT,
                            payload: { event: EVENTS.SUBSCRIBE_WITH_WALLET, data: props }
                        });
                    } else {
                        dispatch(closeDialog(MODALS.LOGIN));
                    }
                });
        }
        // return openDialog(MODALS.LOGIN);
    }
}

export const randomBuyWithWallet = (props, scope) => {
    if (localStorage.getItem("TOKEN")) {
        if (localStorage.getItem('nic') !== 'null') {
            return (dispatch, getState) => {
                dispatch(showLoading(scope));
                dispatch({
                    type: CLEAR_NEXT_EVENT
                });

                const mobileNo = localStorage.getItem("mobile");
                const url = `/client/ticket-allocate`;
                const request = axios_instance.post(url, props);

                request.then(({ data, status }) => {

                    // This senario comes when the customer dont have wallet or 
                    // The wallet dont have any pay instruments
                    if (data.customErrorCode === 'LKE-127') {
                        dispatch(hideLoading(scope));

                    }
                    else if (status === 200) {
                        dispatch(hideLoading(scope));
                        dispatch(closeDialog(MODALS.BUY));
                        dispatch({
                            type: SHOW_NOTIFICATION,
                            payload: { type: "success", message: data.SalesTxnResponseStatus }
                        });
                        dispatch(refreshWalletBalance(TIME_OUT))
                    }
                    else if (data.customErrorCode === 'LKE-134') {
                        dispatch(hideLoading(scope));
                        dispatch(closeDialog(MODALS.BUY));
                        dispatch({
                            type: SHOW_NOTIFICATION,
                            payload: { type: "danger", message: data.description }
                        });
                    }
                    else {
                        dispatch(hideLoading(scope));
                        dispatch(closeDialog(MODALS.BUY));
                        dispatch({
                            type: SHOW_NOTIFICATION,
                            payload: { type: "danger", message: data.description }
                        });
                    }

                }).catch(error => {
                    error = JSON.parse(JSON.stringify(error));
                    dispatch(hideLoading(scope));
                    if (error.response.data.status === 401) {
                        dispatch(logout());
                        dispatch(closeDialog(MODALS.WARNING));
                        dispatch(closeDialog(MODALS.BUY));
                        dispatch(openDialog(MODALS.LOGIN));
                        dispatch({
                            type: NEXT_EVENT,
                            payload: { event: EVENTS.WALLET_RANDOM_BUY, data: props }
                        });
                    } else {
                        dispatch(closeDialog(MODALS.WARNING));
                        dispatch(closeDialog(MODALS.BUY));
                        dispatch({
                            type: SHOW_NOTIFICATION,
                            payload: { type: "danger", message: error.response.data.description }
                        });
                    }

                });
            };
        } else {
            return scope = (dispatch, getState) => {
                dispatch(hideLoading(scope));
                dispatch(closeDialog(MODALS.BUY));
                dispatch(openDialog(MODALS.NIC));

                //This function is calling for 60 sec to get nic number from the api
                //The moment NIC got it will call random-buy function
                waitUntil()
                    .interval(1000)
                    .times(60)
                    .condition(function () {
                        return (!(localStorage.getItem('nic') === null || localStorage.getItem('nic') === 'null') ? true : false);
                    })
                    .done(function (result) {
                        if (result) {
                            // props.payload.customerId = localStorage.getItem("id");
                            dispatch({
                                type: NEXT_EVENT,
                                payload: { event: EVENTS.WALLET_RANDOM_BUY, data: props }
                            });
                        } else {
                            dispatch(closeDialog(MODALS.LOGIN));
                        }
                    });
            }
        }
    } else {
        return scope = (dispatch, getState) => {
            dispatch(hideLoading(scope));
            dispatch(closeDialog(MODALS.BUY));
            dispatch(openDialog(MODALS.LOGIN));

            //This function is calling for 60 sec to get nic number from the api
            //The moment NIC got it will call random-buy function
            waitUntil()
                .interval(1000)
                .times(60)
                .condition(function () {
                    return (localStorage.getItem("TOKEN") ? true : false);
                })
                .done(function (result) {
                    if (result) {
                        // props.payload.customerId = localStorage.getItem("id");
                        dispatch({
                            type: NEXT_EVENT,
                            payload: { event: EVENTS.WALLET_RANDOM_BUY, data: props }
                        });
                    } else {
                        dispatch(closeDialog(MODALS.LOGIN));
                    }
                });
        }
    }
}

export const buyWithWallet = (props, scope) => {
    if (localStorage.getItem("TOKEN")) {
        if (localStorage.getItem('nic') !== 'null') {
            return (dispatch, getState) => {
                dispatch(showLoading(scope));
                dispatch({
                    type: CLEAR_NEXT_EVENT
                });

                const mobileNo = localStorage.getItem("mobile");
                const url = `/client/ticket-allocate`;
                const request = axios_instance.post(url, props);

                request.then(({ data, status }) => {
                    // This senario comes when the customer dont have wallet or 
                    // The wallet dont have any pay instruments
                    if (data.customErrorCode === 'LKE-127') {
                        dispatch(hideLoading(scope));

                    }
                    else if (data.customErrorCode === 'LKE-126') {
                        dispatch(hideLoading(scope));
                        dispatch(closeDialog(MODALS.SEARCH));
                        dispatch({
                            type: SHOW_NOTIFICATION,
                            payload: { type: "success", message: data.description }
                        });
                    }
                    else {
                        dispatch(hideLoading(scope));
                        dispatch(closeDialog(MODALS.SEARCH));
                        dispatch({
                            type: SHOW_NOTIFICATION,
                            payload: { type: "success", message: data.SalesTxnResponseStatus }
                        });
                        dispatch(refreshWalletBalance(TIME_OUT))
                    }

                }).catch(error => {
                    error = JSON.parse(JSON.stringify(error));
                    dispatch(hideLoading(scope));
                    if (error.response.data.status === 401) {
                        dispatch(logout());
                        dispatch(closeDialog(MODALS.WARNING));
                        dispatch(closeDialog(MODALS.SEARCH));
                        dispatch(openDialog(MODALS.LOGIN));
                        dispatch({
                            type: NEXT_EVENT,
                            payload: { event: EVENTS.WALLET_BUY, data: props }
                        });
                    } else {
                        dispatch(closeDialog(MODALS.WARNING));
                        dispatch(closeDialog(MODALS.SEARCH));
                        dispatch({
                            type: SHOW_NOTIFICATION,
                            payload: { type: "danger", message: error.response.data.description }
                        });
                    }

                });
            };
        } else {
            return scope = (dispatch, getState) => {
                dispatch(hideLoading(scope));
                dispatch(closeDialog(MODALS.SEARCH));
                dispatch(openDialog(MODALS.NIC));

                //This function is calling for 60 sec to get nic number from the api
                //The moment NIC got it will call buy function
                waitUntil()
                    .interval(1000)
                    .times(60)
                    .condition(function () {
                        return (!(localStorage.getItem('nic') === null || localStorage.getItem('nic') === 'null') ? true : false);
                    })
                    .done(function (result) {
                        if (result) {
                            // props.payload.customerId = localStorage.getItem("id");
                            dispatch({
                                type: NEXT_EVENT,
                                payload: { event: EVENTS.WALLET_BUY, data: props }
                            });
                        } else {
                            dispatch(closeDialog(MODALS.LOGIN));
                        }
                    });
            }
        }
    } else {
        return scope = (dispatch, getState) => {
            dispatch(hideLoading(scope));
            dispatch(closeDialog(MODALS.SEARCH));
            dispatch(openDialog(MODALS.LOGIN));

            //This function is calling for 60 sec to get nic number from the api
            //The moment NIC got it will call buy function
            waitUntil()
                .interval(1000)
                .times(60)
                .condition(function () {
                    return (localStorage.getItem("TOKEN") ? true : false);
                })
                .done(function (result) {
                    if (result) {
                        // props.payload.customerId = localStorage.getItem("id");
                        dispatch({
                            type: NEXT_EVENT,
                            payload: { event: EVENTS.WALLET_BUY, data: props }
                        });
                    } else {
                        dispatch(closeDialog(MODALS.LOGIN));
                    }
                });
        }
        // return openDialog(MODALS.LOGIN);
    }
}


//This is to get wallet info from common wallet
export const getCommonWalletInfo = function () {

    const url = `/common-wallet`;

    return (dispatch) => {
        axios_instance.get(url).then(({ data }) => {    //get the getails of the wallet

            dispatch({
                type: LOAD_WALLET_INFO,
                payload: data
            });
        }).catch((error)=>{
            // console.log(error)
        });
    }
}


// Verify Frimi User And Auto Login
export const loginFrimiUser = (uuid, scope) =>{
    return (dispatch) => {
        dispatch(showLoading(scope));
        const requestBody = {
            customerReference: uuid,
            source: "Frimi"
        }
        const tokenGeneratePath = '/tokens';
        const request = axios_merchant_proxy.post(tokenGeneratePath, requestBody);
        request.then((response) => {
                axios_instance.defaults.headers.common['authorization'] = response.data.token;
                var decodedData = jwt_decode(response.data.token);
                sessionStorage.setItem('source', decodedData.source);
                localStorage.setItem('id', decodedData.id);
                localStorage.setItem('mobile', decodedData.mobile);
                localStorage.setItem('nic', decodedData.nic);
                dispatch({
                    type: LOGGED_IN,
                    payload: response
                });
            }).catch(error=>{
                if (!error || !error.response || error.response.status === 502) { // api services down
                    dispatch({
                        type: SHOW_NOTIFICATION,
                        payload: { type: "danger", message: STRING.CONNECTION_ERROR_MESSAGE }
                    });
                }
                else {
                    dispatch({
                        type: SHOW_NOTIFICATION,
                        payload: { type: "danger", message: STRING.INVALID_LOGIN_ATTEMPT }
                    });
                }
            });
        dispatch(hideLoading(scope));
    }
}

export const getMobileNumber = () => {
    
    return (dispatch) => {
        dispatch({
            type: GET_MOBILE_NUMBER,
        });
    }
}
export const getPaymentSource = () => {
    return (dispatch) => {
        dispatch({
            type: GET_PAYMENT_SOURCE,
        });
    }
}


//Agent Accout
export const loadMyAgents = function(filters, reset) {
    return (dispatch , getState) => {
        dispatch(loadPackages());
        console.log(filters);
        let subFromDate = '';
        let subToDate = '';
        let settlementFromDate = '';
        let settlementToDate = '';
        let ticketCountMin = '';
        let ticketCountMax = '';
        let settlementStatus = '';
        let packageId = '';
        let mobileNo = '';
        if(filters && filters['subFromDate'] && filters['subToDate']){
            subFromDate = filters['subFromDate'];
            subToDate = filters['subToDate'];
        }
        if(filters && filters['settlementFromDate'] && filters['settlementToDate']){
            settlementFromDate = filters['settlementFromDate'];
            settlementToDate = filters['settlementToDate'];
        }
        if(filters && filters['rangeTicket']['max'] > 0){
            ticketCountMin = filters['rangeTicket']['min'];
            ticketCountMax = filters['rangeTicket']['max']
        }
        if(filters && filters['settlementStatus']){
            settlementStatus = filters['settlementStatus'];
        }
        if(filters && filters['packageId']){
            packageId = filters['packageId'];
        }
        if(filters && filters['mobileNo']){
            mobileNo = filters['mobileNo'];
        }
        
        let maxTicketValueRef=filters['maxTicketValueRef'];
        const url = `/referral-details?subFromDate=${subFromDate}&subToDate=${subToDate}&settlementFromDate=${settlementFromDate}&settlementToDate=${settlementToDate}&ticketCountMin=${ticketCountMin}&ticketCountMax=${ticketCountMax}&settlementStatus=${settlementStatus}&packageId=${packageId}&mobileNo=${mobileNo}&maxTicketValueRef=${maxTicketValueRef}`;
        axios_instance.get(url).then(({data})=>{
            dispatch({
                type: LOAD_MY_AGENTS,
                payload: data
            });
        })

    }
} 
export const loadMyAgentsSales = function(filters, reset) {
    return (dispatch , getState) => {
        dispatch(loadPackages());
        console.log(filters);
        let subFromDate = '';
        let subToDate = '';
        let packageId = '';
        let mobileNo = '';
        let download = false;
        if(filters && filters['saleFromDate'] && filters['saleToDate']){
            subFromDate = filters['saleFromDate'];
            subToDate = filters['saleToDate'];
        }
        if(filters && filters['salePackageId'] && filters['salePackageId'] != "Select"){
            packageId = filters['salePackageId'];
        }
        if(filters && filters['saleMobileNo']){
            mobileNo = filters['saleMobileNo'];
        }
       
        if(filters && filters['download'] === true){
            download = true;
        }
        
    
        const url = `/referral-details/sales?fromDate=${subFromDate}&toDate=${subToDate}&packageId=${packageId}&mobileNo=${mobileNo}&download=${download}`;
        // console.log(url)
        if(filters && filters['download'] === true){
            axios_instance.get(url,{responseType: 'blob'}).then(({data})=>{
                const href = URL.createObjectURL(data);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'referalSales.csv'); //or any other extension
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
                
            })
        }else{
            axios_instance.get(url).then(({data})=>{
                dispatch({
                    type: LOAD_MY_AGENTS_SALES,
                    payload: data
                });
            })
        }
        

    }
}   
//This is to get wallet balance info from common wallet without cheking consent of wallet user
// used to show wallet balance in the web site
export const getCommonWalletInfoNoConsentCheck = function () {

    const url = `/common-wallet/info-no-consent`;

    return (dispatch) => {
        axios_instance.get(url).then(({ data }) => {    //get the getails of the wallet balnce

            sessionStorage.setItem('balance', data.balance);
            dispatch({type: GET_WALLET_BALANCE})
        }).catch((error)=>{
            // console.log(error)
        });
    }
}
const refreshWalletBalance = (timeOut) => {
    return (dispatch) => {
       setTimeout(() => {
        dispatch(getCommonWalletInfoNoConsentCheck())
        }, timeOut); 
    }   
}

export const setPaymentMethodToLocalStorage = (paymentMethod) => {
    return (dispatch) => {
        localStorage.setItem("payment_method",paymentMethod)
    }  
}

export const getPaymentMethodFromLocalStorage = () => {
    return (dispatch) => {
        dispatch({type: GET_PAYMENT_METHOD})
    }
}
export const proceedBannerCallToAction = (data) => {
    return (dispatch) => {
        dispatch({
            type: BANNER_ACTION,
            payload: data
        });
    }
}

export const loadPackages = () => {
    return (dispatch) => {   
        const urls = `/lottery/packages`;
        axios_instance.get(urls).then(({ data }) => {      
            dispatch({
                type: LOAD_PACKAGES,
                payload: data
                
            });
            
        });
    }
}

