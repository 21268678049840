/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import reduxDialog, { closeDialog, openDialog } from 'redux-reactstrap-modal';
import { connect } from "react-redux";
import { compose } from 'recompose';
import { bindActionCreators } from "redux";
import { API_ROOT_URL, MODALS, EVENTS } from '../config/';
import NotificationBarModal from './NotificationBarModal';

import { userNumberVerify, createNewUserPin } from "../actions/index";
import {
    translate,
    getLanguage
  } from 'react-switch-lang';

class PinVerifyModal extends Component {

    constructor(props) {
        super(props);
        const { t } = this.props;
        this.state = {
            mobile_pin: "",
            pin_input_enable: false,
            place_holder:t("number_verificaion.number_verify")
        }

        this.updateInput = this.updateInput.bind(this);
    }



    login() {
        this.props.login(this.state.mobile_no, this.state.password);
    }

    goToSignUp() {
    }

    updateInput(event) {
        event.preventDefault();
        this.setState({ mobile_pin: event.target.value });
    }

    updateInputMobile(event) {
    }

    userNumberVerify() {
        const { userNumberVerify, data } = this.props;
        if (!this.state.mobile_pin || this.state.mobile_pin === '') {
        } else {
            userNumberVerify(this.state.mobile_pin, data.mobile_no).then((result) => {
                if (result.status) {
                } else {
                    this.setState({ pin_input_enable: true, place_holder: "Click on Resend", mobile_pin: '' });
                    ReactDOM.findDOMNode(this.refs.pin_verify).value = "";
                }
            }).catch(e => {

            });
        }
    }

    resendSMS() {
        const { createNewUserPin, data } = this.props;
        createNewUserPin(data.mobile_no, true).then((result) => {
            if (result.status) {
                this.setState({ pin_input_enable: false, place_holder: "Enter Verification Code", mobile_pin: '' });
                ReactDOM.findDOMNode(this.refs.pin_verify).value = "";
            } else {
            }
        }).catch(e => {

        });
    }

    render() {

        const { t } = this.props;

        return (
            <div className="p-2">
                <div className="modal-header">
                    <h5 className="modal-title">{t("number_verificaion.number_verify")}</h5>
                    <button type="button" className="close" onClick={() => this.props.closeDialog(MODALS.PINVERIFY)}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <NotificationBarModal />
                    <p className="text-center">
                        {this.props.data.mobile_no}
                    </p>

                    <input type="text" ref="pin_verify" className="form-control" disabled={this.state.pin_input_enable} placeholder={this.state.place_holder} onChange={this.updateInput}></input>

                    <div className="pt-3"><button type="submit" className="btn btn-primary btn-block" onClick={() => this.userNumberVerify()}>{t("number_verificaion.enter_verify")}</button></div>


                    <div className="bg-light rounded p-3 mt-3 text-center">
                        <div className="pt-3">
                            <p>
                            {t("number_verificaion.no_verify")}
                        </p>
                            <button type="submit" className="btn btn-primary btn-block" onClick={() => this.resendSMS()}>{t("number_verificaion.resend")}</button></div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ userNumberVerify, createNewUserPin, closeDialog, openDialog }, dispatch);
}

export default translate(compose(reduxDialog({ name: 'MODAL_PINVERIFY', centered: true }), connect(null, mapDispatchToProps))(PinVerifyModal));
