/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { connect } from "react-redux";

import { bindActionCreators } from "redux";
import { loadResults , loadAvaliableLotteries} from "../actions/index";
import { Table } from 'reactstrap';
import HowItWorkEn from "../config/language_contend/howitworken.js";
import HowItWorkTml from "../config/language_contend/howitworktml.js";
import HowItWorkSin from "../config/language_contend/howitwrksin.js";

import {
    translate,
    getLanguage
} from 'react-switch-lang';

class HowItWorks extends Component {
    
    render() {
        const { t } = this.props;
        var lan =getLanguage();

        return (
            <div className="innerContent">
              <h2 className="content-title">{t("howitwork.title")}</h2>
              <p>{t("howitwork.para1")}</p>
              <p>{t("howitwork.para2")}</p>

        <h5 className="content-title">{t("howitwork.para3")}</h5>
        <div className="table-responsive">
        {lan==='en'?<HowItWorkEn/>
        :<span/>}
        {lan==='sin'?<HowItWorkSin/>
        :<span/>}
        {lan==='tml'?<HowItWorkTml/>
        :<span/>}
        </div>

        </div>




    );
    }
}


export default translate(connect()(HowItWorks));
