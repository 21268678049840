/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */


import React, { Component } from 'react';


class HowItWorkEn extends Component{

    render (){
        return (<div>
            <p>&nbsp;</p>
            <div className="table-responsive">
<table style={{borderColor:'#ffff'}}>
<tbody>
<tr>
<td>&nbsp;</td>
<td>
<p><strong>Keyword</strong></p>
</td>
<td>
<p><strong>How to Activate</strong></p>
</td>
<td>
<p><strong>How to Deactivate</strong></p>
</td>
<td>
<p><strong>Draw Dates</strong></p>
</td>
</tr>
<tr>
<td>
<p><strong>Mahajana Sampatha</strong></p>
</td>
<td>
<p><span >MS</span></p>
</td>
<td>
<p><span >MS NIC</span></p>
</td>
<td>
<p><span >MS OFF</span></p>
</td>
<td>
<p><span >Monday, Tuesday, Thursday, Friday, Saturday, Sunday</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Nirooga</strong></p>
</td>
<td>
<p><span >NR</span></p>
</td>
<td>
<p><span >NR NIC</span></p>
</td>
<td>
<p><span >NR OFF</span></p>
</td>
<td>
<p><span >Tuesday, Thursday, Sunday</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Mega Power</strong></p>
</td>
<td>
<p><span >MP</span></p>
</td>
<td>
<p><span >MP NIC</span></p>
</td>
<td>
<p><span >MP OFF</span></p>
</td>
<td>
<p><span >Monday, Tuesday, Wednesday, Friday</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Govi Setha</strong></p>
</td>
<td>
<p><span >GS</span></p>
</td>
<td>
<p><span >GS NIC</span></p>
</td>
<td>
<p><span >GS OFF</span></p>
</td>
<td>
<p><span >Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Supiri Vasana</strong></p>
</td>
<td>
<p><span >SV</span></p>
</td>
<td>
<p><span >SV NIC</span></p>
</td>
<td>
<p><span >SV OFF</span></p>
</td>
<td>
<p><span >Monday, Saturday</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Jathika Sampatha</strong></p>
</td>
<td>
<p><span >JS</span></p>
</td>
<td>
<p><span >JS NIC</span></p>
</td>
<td>
<p><span >JS OFF</span></p>
</td>
<td>
<p><span >Wednesday, Saturday</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Vasana Sampatha</strong></p>
</td>
<td>
<p><span >VS</span></p>
</td>
<td>
<p><span >VS NIC</span></p>
</td>
<td>
<p><span >VS OFF</span></p>
</td>
<td>
<p><span >Thursday</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Sevana</strong></p>
</td>
<td>
<p><span >SEV</span></p>
</td>
<td>
<p><span >SE NIC</span></p>
</td>
<td>
<p><span >SEV OFF</span></p>
</td>
<td>
<p><span >Tuesday, Saturday</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Dana Nidanaya</strong></p>
</td>
<td>
<p><span >DN</span></p>
</td>
<td>
<p><span >DN NIC</span></p>
</td>
<td>
<p><span >DN OFF</span></p>
</td>
<td>
<p><span >Sunday, Wednesday, Friday</span></p>
</td>
</tr>
</tbody>
</table>
</div>
<p><span >Note : Subscriber will receive one ticket from every draw of the lottery. Each draw will charge Rs.20 + Operator convenient fee + taxes per ticket</span></p>
<p><span >On Demand</span></p>
<div className="table-responsive">
<table>
<tbody>
<tr>
<td>&nbsp;</td>
<td>
<p><strong>Keyword</strong></p>
</td>
<td>
<p><strong>How to Activate</strong></p>
</td>
<td>
<p><strong>No of tickets</strong></p>
</td>
</tr>
<tr>
<td>
<p><strong>Mahajana Sampatha</strong></p>
</td>
<td>
<p><span >OMS</span></p>
</td>
<td>
<p><span >OMS No of tickets NIC</span></p>
</td>
<td>
<p><span >Number of ticket from the immediately available draw</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Nirooga</strong></p>
</td>
<td>
<p><span >ONR</span></p>
</td>
<td>
<p><span >ONR No of tickets NIC</span></p>
</td>
<td>
<p><span >Number of ticket from the immediately available draw</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Mega Power</strong></p>
</td>
<td>
<p><span >OMP</span></p>
</td>
<td>
<p><span >OMP No of tickets NIC</span></p>
</td>
<td>
<p><span >Number of ticket from the immediately available draw</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Govi Setha</strong></p>
</td>
<td>
<p><span >OGS</span></p>
</td>
<td>
<p><span >OGS No of tickets NIC</span></p>
</td>
<td>
<p><span >Number of ticket from the immediately available draw</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Supiri Vasana</strong></p>
</td>
<td>
<p><span >OSV</span></p>
</td>
<td>
<p><span >OSV No of tickets NIC</span></p>
</td>
<td>
<p><span >Number of ticket from the immediately available draw</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Jathika Sampatha</strong></p>
</td>
<td>
<p><span >OJS</span></p>
</td>
<td>
<p><span >OJS No of tickets NIC</span></p>
</td>
<td>
<p><span >Number of ticket from the immediately available draw</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Vasana Sampatha</strong></p>
</td>
<td>
<p><span >OVS</span></p>
</td>
<td>
<p><span >OVS No of tickets NIC</span></p>
</td>
<td>
<p><span >Number of ticket from the immediately available draw</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Sevana</strong></p>
</td>
<td>
<p><span >OSEV</span></p>
</td>
<td>
<p><span >OSEV No of tickets NIC</span></p>
</td>
<td>
<p><span >Number of ticket from the immediately available draw</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Dana Nidanaya</strong></p>
</td>
<td>
<p><span >ODN</span></p>
</td>
<td>
<p><span >ODN No of tickets NIC</span></p>
</td>
<td>
<p><span >Number of ticket from the immediately available draw</span></p>
</td>
</tr>
</tbody>
</table>
</div>
<p><span >Note: Requested number of tickets from the immediately available draw.</span></p>
<p>&nbsp;</p>
<p><span >Available lotteries and draw dates</span></p>
<div className="table-responsive">
<table style={{borderColor: "white"}}>
<tbody>
<tr>
<td>
<p><strong>Product</strong></p>
</td>
<td>
<p><strong>Monday</strong></p>
</td>
<td>
<p><strong>Tuesday</strong></p>
</td>
<td>
<p><strong>Wednesday</strong></p>
</td>
<td>
<p><strong>Thursday</strong></p>
</td>
<td>
<p><strong>Friday</strong></p>
</td>
<td>
<p><strong>Saturday</strong></p>
</td>
<td>
<p><strong>Sunday</strong></p>
</td>
</tr>
<tr>
<td>
<p><strong>Mahajana Sampatha</strong></p>
</td>
<td>
<p><span >available</span></p>
</td>
<td>
<p><span >available</span></p>
</td>
<td>&nbsp;</td>
<td>
<p><span >available</span></p>
</td>
<td>
<p><span >available</span></p>
</td>
<td>available</td>
<td>
<p><span >available</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Nirooga</strong></p>
</td>
<td>&nbsp;</td>
<td>
<p><span >available</span></p>
</td>
<td>&nbsp;</td>
<td>
<p><span >available</span></p>
</td>
<td>&nbsp;</td>
<td>&nbsp;</td>
<td>
<p><span >available</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Mega Power</strong></p>
</td>
<td>
<p><span >available</span></p>
</td>
<td>&nbsp;</td>
<td>
<p><span >available</span></p>
</td>
<td>&nbsp;</td>
<td>
<p><span >available</span></p>
</td>
<td>&nbsp;</td>
<td>&nbsp;</td>
</tr>
<tr>
<td>
<p><strong>Govi Setha</strong></p>
</td>
<td>
<p><span >available</span></p>
</td>
<td>
<p><span >available</span></p>
</td>
<td>
<p><span >available</span></p>
</td>
<td>
<p><span >available</span></p>
</td>
<td>
<p><span >available</span></p>
</td>
<td>
<p><span >available</span></p>
</td>
<td>
<p><span >available</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Supiri Vasana</strong></p>
</td>
<td>
<p><span >available</span></p>
</td>
<td>&nbsp;</td>
<td>&nbsp;</td>
<td>&nbsp;</td>
<td>&nbsp;</td>
<td>
<p><span >available</span></p>
</td>
<td>&nbsp;</td>
</tr>
<tr>
<td>
<p><strong>Jathika Sampatha</strong></p>
</td>
<td>&nbsp;</td>
<td>&nbsp;</td>
<td>
<p><span >available</span></p>
</td>
<td>&nbsp;</td>
<td>&nbsp;</td>
<td>
<p><span >available</span></p>
</td>
<td>&nbsp;</td>
</tr>
<tr>
<td>
<p><strong>Vasana Sampatha</strong></p>
</td>
<td>&nbsp;</td>
<td>&nbsp;</td>
<td>&nbsp;</td>
<td>
<p><span >available</span></p>
</td>
<td>&nbsp;</td>
<td>&nbsp;</td>
<td>&nbsp;</td>
</tr>
<tr>
<td>
<p><strong>Sevana</strong></p>
</td>
<td>&nbsp;</td>
<td>
<p><span >available</span></p>
</td>
<td>&nbsp;</td>
<td>&nbsp;</td>
<td>&nbsp;</td>
<td>
<p><span >available</span></p>
</td>
<td>&nbsp;</td>
</tr>
<tr>
<td>
<p><strong>Dana Nidanaya</strong></p>
</td>
<td>&nbsp;</td>
<td>&nbsp;</td>
<td>
<p><span >available</span></p>
</td>
<td>&nbsp;</td>
<td>
<p><span >available</span></p>
</td>
<td>&nbsp;</td>
<td>
<p><span >available</span></p>
</td>
</tr>
</tbody>
</table>
</div>
            </div>);
    }
};

export default HowItWorkEn;
